import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-browser-modal',
  templateUrl: './browser-modal.component.html',
  styleUrls: ['./browser-modal.component.scss']
})
export class BrowserModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BrowserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
  ) { }

  ngOnInit() {}
  submit() {
    this.dialogRef.close(true);
  }
}
