import { FilterSet } from './project';

export interface FilterPapers {
  general: FilterSet;
  titleScreening: FilterSet;
  fullTextScreening: FilterSet;
  dataEntry: FilterSet;
  manualPaper: FilterSet;
}
export enum FilterPaperType {
  MANUAL_PAPER,
  TITLE_SCREENING,
  FULL_TEXT_SCREENING,
  DATA_ENTRY,
  NONE,
}
