import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AnalysisFlow,
  SaveAnalysisInfo,
  AnalysisExportR,
  ExportRResult,
  PaperItem,
  FilterSet,
  IExportWideFormat,
} from '@shared/schema/project';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import { HelperService } from './service-helper';
import { HttpClient } from '@angular/common/http';
import {
  PaperComment,
  TitleScreeningReviewerStatistic,
  Sample,
  ModeratorVariable,
  CorrelationOutcome,
  ScreeningReason,
  PaperSubject,
  LibmetaPaperDetail,
  AICommentStatistics,
  CorrelationOutcomeStatistic,
  CorrelationOutcomeFilterSet,
  ASReviewConfig,
  CorelationOutcomeTableData,
} from '@shared/schema/statistic';
import {
  PaperSample,
  ProjectTasks,
} from '@shared/schema/project.statistic';
import { UserContribution } from '@shared/schema/contribution';
import { AppRuntimeConfig, CONFIG } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticService extends HelperService {
  loadAnalysisFirstTime = true;
  constructor(
    @Inject(CONFIG) private appRuntimeConfig: AppRuntimeConfig,
    private http: HttpClient
  ) {
    super();
  }
  // Title Screening Reviewer Statistic Info
  getTitleScreeningReviewerStatistic(
    projectId: number
  ): Observable<TitleScreeningReviewerStatistic> {
    return this.http
      .get(
        `${this.appRuntimeConfig.api_url}/statistic/${projectId}/title_screen/reviewer/`
      )
      .pipe(
        map((result: TitleScreeningReviewerStatistic) => {
          return result;
        })
      );
  }

  // Submit analyse flow
  async createAnalyze(info: AnalysisFlow): Promise<{ task_id: string }> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/statistic/analysis/`, info)
      .toPromise()) as { task_id: string };
  }
  async saveAnalysis(info: SaveAnalysisInfo): Promise<any> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/statistic/saved_analysis/`, info)
      .toPromise()) as any;
  }
  async getSavedAnalysis(
    page: number,
    pageSize: number,
    projectId: number
  ): Promise<any> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/saved_analysis/`, {
        params: {
          page: String(page),
          page_size: String(pageSize),
          project: String(projectId),
        },
      })
      .toPromise()) as any;
  }
  deleteSavedAnalysis(id: number) {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/statistic/saved_analysis/${id}/`)
      .pipe();
  }
  exportRAnalysis(analysisParam: AnalysisExportR): Observable<ExportRResult> {
    return this.http
      .post(
        `${this.appRuntimeConfig.api_url}/statistic/analysis/export_r/`,
        analysisParam
      )
      .pipe(map((res: ExportRResult) => res));
  }

  exportWideFormat(data: IExportWideFormat): Observable<{ task_id: string }> {
    return this.http
      .post<{ task_id: string }>(
        `${this.appRuntimeConfig.api_url}/statistic/analysis/export_wide_format_csv/`,
        data
      );
  }
  exportLongFormat(data: IExportWideFormat): Observable<{ task_id: string }> {
    return this.http
      .post<{ task_id: string }>(
        `${this.appRuntimeConfig.api_url}/statistic/analysis/export_long_format_csv/`,
        data
      );
  }

  // Submit Comment On Paper
  async submitComment(info: PaperComment): Promise<PaperComment> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/statistic/comment/`, info, {
        reportProgress: true,
      })
      .toPromise()) as PaperComment;
  }

  // Submit Comment On Paper
  async editComment(info: any, commentId: number): Promise<PaperComment> {
    return (await this.http
      .patch(`${this.appRuntimeConfig.api_url}/statistic/comment/${commentId}/`, info, {
        reportProgress: true,
      })
      .toPromise()) as PaperComment;
  }

  async getPaper(paper: number): Promise<PaperItem> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/${paper}/`)
      .toPromise()) as PaperItem;
  }

  async getPaperSamples(
    paper: number | number[]
  ): Promise<{ count: number; num_of_pages: number; data: Sample[] }> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/sample/`, {
        params: this.getHttpParams({
          page: '1',
          page_size: '100',
          paper__in: paper,
        }),
        reportProgress: true,
      })
      .toPromise()) as { count: number; num_of_pages: number; data: Sample[] };
  }

  async creatNewSample(data): Promise<Sample> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/statistic/sample/`, data, {
        reportProgress: true,
      })
      .toPromise()) as Sample;
  }
  copySample(data): Observable<Sample> {
    return  this.http
      .post<Sample>(`${this.appRuntimeConfig.api_url}/statistic/sample/copy/`, data, {
        reportProgress: true,
      });
  }

  deletePaperSample(id: number): Promise<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/statistic/sample/${id}/`)
      .toPromise();
  }

  async importPdf(data: any): Promise<any> {
    return (await this.http
      .post(`${this.appRuntimeConfig.libmeta_url}/papers/`, data, {
        reportProgress: true,
      })
      .toPromise()) as any;
  }

  async importPDFTask(taskId: string) {
    return (await this.http
      .get(`${this.appRuntimeConfig.libmeta_url}/tasks/${taskId}/status`, {
        reportProgress: true,
      })
      .toPromise()) as any;
  }

  async getTaskStatus(taskId: string) {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/tasks/${taskId}/status`, {
        reportProgress: true,
      })
      .toPromise()) as any;
  }

  async searchImportLibs(
    search: string,
    page: number,
    pageSize: number
  ): Promise<{
    data: PaperItem[];
    num_of_pages: number;
    count: number;
  }> {
    return (await this.http
      .get(`${this.appRuntimeConfig.libmeta_url}/papers/`, {
        reportProgress: true,
        params: {
          value: search,
          page: String(page),
          page_size: String(pageSize),
        },
      })
      .toPromise()) as any;
  }

  async getLibPaper(paperId: number): Promise<LibmetaPaperDetail> {
    return (await this.http
      .get(`${this.appRuntimeConfig.libmeta_url}/papers/${paperId}`)
      .toPromise()) as any;
  }

  async updateSample(sampleId: number, data: PaperSample): Promise<Sample> {
    return (await this.http
      .put(`${this.appRuntimeConfig.api_url}/statistic/sample/${sampleId}/`, data, {
        reportProgress: true,
      })
      .toPromise()) as Sample;
  }

  async getModeratorVariables(projectId: number): Promise<ModeratorVariable[]> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/moderators/`)
      .toPromise()) as ModeratorVariable[];
  }

  async setModeratorVariablesValue(data: any): Promise<ModeratorVariable[]> {
    return (await this.http
      .post(
        `${this.appRuntimeConfig.api_url}/statistic/moderator_variable/bulk_create/`,
        data,
        { reportProgress: true }
      )
      .toPromise()) as ModeratorVariable[];
  }

  async getModeratorVariablesValue(
    sample: number
  ): Promise<
    { count: number; num_of_pages: number; data: ModeratorVariable[] } | any
  > {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/moderator_variable/`, {
        reportProgress: true,
        params: this.getHttpParams({
          sample,
        }),
      })
      .toPromise()) as {
        count: number;
        num_of_pages: number;
        data: ModeratorVariable[];
      };
  }
  getCorrelationOutcome(
    sample: number,
  ): Observable<CorelationOutcomeTableData> {
    return this.http.get<CorelationOutcomeTableData>
      (`${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/table/`,
        {
          params: this.getHttpParams({
            sample
          }),
          reportProgress: true
        });
  }
  getTaxonomyRelatedPapers(variable: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/taxonomy_variable_papers/`,
      {
        params: this.getHttpParams({
          variable
        }),
        reportProgress: true
      }
    );
  }
  exportTaxonomyRelatedPapersCSV(variable: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/taxonomy_variable_papers_csv/`,
      {
        params: this.getHttpParams({
          variable
        }),
        reportProgress: true
      }
    );
  }
  getRelatedCorrelationOutcome(
    variable: number,
  ): Observable<any> {
    return this.http.get<any>
      (`${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/`,
        {
          params: this.getHttpParams({
            variable
          }),
          reportProgress: true
        });
  }

  async createCorrelationOutcomeBulk(
    data,
    reportProgress: boolean
  ): Promise<CorrelationOutcome[]> {
    return (await this.http
      .post(
        `${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/bulk_create/`,
        data,
        { reportProgress }
      )
      .toPromise()) as CorrelationOutcome[];
  }

  createActionTime(data: any) {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/statistic/statistics_report/`, data, {
        reportProgress: true,
      })
      .pipe(map((res) => res));
  }

  async getAnalyticsReport(filters) {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/statistics_report/`, {
        params: this.getHttpParams(filters),
      })
      .toPromise()) as any;
  }
  async getAnalyticsSummery(filters) {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/statistics_report/summary/`, {
        params: this.getHttpParams(filters),
      })
      .toPromise()) as any;
  }

  async getAnalyticsActions(filters) {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/statistics_report/user_action/`, {
        params: this.getHttpParams(filters),
      })
      .toPromise()) as any;
  }

  getScreeningReason(projectId: number, reason?: string): Observable<ScreeningReason[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/screening_reason/`, {
        params: this.getHttpParams({
          project: projectId,
          reason__icontains: reason
        }),
      })
      .pipe(map((res: ScreeningReason[]) => res));
  }
  getPaperSubjects(
    projectId: number,
    subjectName?: string
  ): Observable<PaperSubject[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/subjects/`, {
        reportProgress: true,
        params: { name: subjectName },
      })
      .pipe(map((res: PaperSubject[]) => res));
  }

  exportCorrelationOutcome(
    sampleId: number,
    isRType: boolean
  ): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/export/`, {
        params: this.getHttpParams({
          sample: sampleId,
          is_rtype: isRType,
        }),
      })
      .pipe(map((res: any) => res.file_url));
  }
  createScreeningReason(reason: ScreeningReason): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/statistic/screening_reason/`, reason)
      .pipe();
  }
  editScreeningReason(reason: ScreeningReason, id: number): Observable<any> {
    return this.http
      .put(`${this.appRuntimeConfig.api_url}/statistic/screening_reason/${id}/`, reason)
      .pipe();
  }
  deleteScreeningReason(id: number): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/statistic/screening_reason/${id}/`)
      .pipe();
  }
  getAsReviewDecisionStatistics(
    filter: FilterSet
  ): Observable<AICommentStatistics> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/asreview_decision/`, {
        params: this.getHttpParams(filter),
      })
      .pipe(map((res: AICommentStatistics) => res));
  }
  importAIGeneratedComments(
    filter: FilterSet
  ): Observable<{ task_id: string }> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/papers/asreview_decision/`, filter)
      .pipe(map((res: any) => res));
  }
  getCorrelationOutcomeStatistic(
    filter: CorrelationOutcomeFilterSet
  ): Observable<CorrelationOutcomeStatistic> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/statistic/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      })
      .pipe(map((res: CorrelationOutcomeStatistic) => res));
  }
  getCorrelationOutcomeStatisticCsv(
    filter: CorrelationOutcomeFilterSet
  ): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/statistic/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      });
  }
  getUsersContributions(projectId: number): Observable<UserContribution[]> {
    return this.http
      .get(
        `${this.appRuntimeConfig.api_url}/project/project/${projectId}/users_contribution/`
      )
      .pipe(map((res: UserContribution[]) => res));
  }
  getTasks(id: number, params: FilterSet): Observable<ProjectTasks> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/tasks/`, {
        params: this.getHttpParams(params),
        reportProgress: true,
      })
      .pipe(map((res: ProjectTasks) => res));
  }

  getTasksWarning(taskId: number): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/tasks/${taskId}/warnings/`)
      .pipe(map((res: any) => res));
  }

  getAsReviewConfig(id: number): Observable<ASReviewConfig> {
    return this.http.get(`${this.appRuntimeConfig.api_url}/project/project/${id}/asreview_configs/`)
      .pipe(
        map((res: ASReviewConfig) => res)
      );
  }
  updateAsReviewConfig(id: number, data: ASReviewConfig): Observable<ASReviewConfig> {
    return this.http.put(`${this.appRuntimeConfig.api_url}/project/project/${id}/asreview_configs/`, data)
      .pipe(
        map((res: ASReviewConfig) => res)
      );
  }
}
