import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NebularModule} from '@shared/nebular.module';
import {MaterialModule} from '@shared/material.module';
import {SearchInputComponent} from '@shared/components/search-input/search-input.component';
import {ProfileActionComponent} from '@shared/components/profile-action/profile-action.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {HubMetaMenuComponent} from '@shared/components/hub-meta-menu/hub-meta-menu.component';
import {MainHeaderComponent} from '@shared/components/main-header/main-header.component';
import {ContributionRatioComponent} from '@shared/components/contribution-ratio/contribution-ratio.component';
import {BreadcrumbComponent} from '@shared/components/breadcrumb/breadcrumb.component';
import {ProgressSpinnerComponent} from '@shared/components/progress-spinner/progress-spinner.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {ProgressButtonComponent} from '@shared/components/progress-button/progress-button.component';
import {NotificationComponent} from '@shared/components/notification/notification.component';
import {DxBarGaugeModule, DxCircularGaugeModule, DxPieChartModule, DxChartModule} from 'devextreme-angular';
import {DataEntryFiltersComponent} from '@shared/components/papers-filters/paper-filters.component';
import {Ng5SliderModule} from 'ng5-slider';
import {BreadcrumbTagComponent} from '@shared/components/breadcrumb-tag/breadcrumb-tag.component';
import {VariablesTreeComponent} from '@shared/components/variables-tree/variables-tree.component';
import {PaginationComponent} from '@shared/components/pagination/pagination.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {EmailDesignPaginationComponent} from '@shared/components/email-design-pagination/email-design-pagination.component';
import {ReviewCommentComponent} from '@shared/components/review-comment/review-comment.component';
import {CommentTypePipe} from '@core/pipe/comment-type.pipe';
import {ConfirmModalComponent} from '@shared/components/confirm-modal/confirm-modal.component';
import {NoContentComponent} from '@shared/components/no-content/no-content.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from '@core/core.module';
import { CountoModule } from 'angular2-counto';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { VariableSelectComponent } from '@shared/components/variable-select/variable-select.component';
import { PaperOverallInfoComponent } from './components/paper-overall-info/paper-overall-info.component';
import { PaperDecisionComponent } from '../modules/project/components/paper-decision/paper-decision.component';
import { PaperCommentComponent } from './components/paper-comments/paper-comment.component';
import { GuidePopoverComponent } from './components/guide-popover/guide-popover.component';
import { RcodeModalComponent } from './components/rcode-modal/rcode-modal.component';
import { ShowTagsComponent } from './components/show-tags/show-tags.component';
import { AddTagToPaperComponent } from './components/add-tag-to-paper/add-tag-to-paper.component';
import { EmailNotVerifiedWarningComponent } from './components/email-not-verified-warning/email-not-verified-warning.component';
import { DropboxNotConnectedWarningComponent } from './components/dropbox-not-connected-warning/dropbox-not-connected-warning.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { HandleAssignmentComponent } from './components/handle-assignment/handle-assignment.component';
import { ShowAssignmentComponent } from './components/show-assignment/show-assignment.component';
import { CiteUsComponent } from './components/cite-us/cite-us.component';
import { NoAccessPageComponent } from './components/no-access-page/no-access-page.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NebularModule,
    MaterialModule,
    NgxFileDropModule,
    NgxDaterangepickerMd.forRoot(),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    DxCircularGaugeModule,
    DxBarGaugeModule,
    DxPieChartModule,
    DxChartModule,
    Ng5SliderModule,
    NgSelectModule,
    NgxPaginationModule,
    CoreModule,
    CountoModule,
    NgxSpinnerModule,

  ],
  declarations: [
    // Components
    SearchInputComponent,
    ProfileActionComponent,
    HubMetaMenuComponent,
    MainHeaderComponent,
    ContributionRatioComponent,
    BreadcrumbComponent,
    ProgressSpinnerComponent,
    ProgressButtonComponent,
    NotificationComponent,
    BreadcrumbTagComponent,
    DataEntryFiltersComponent,
    VariablesTreeComponent,
    PaginationComponent,
    EmailDesignPaginationComponent,
    ReviewCommentComponent,
    ConfirmModalComponent,
    NoContentComponent,
    GuidePopoverComponent,
    RcodeModalComponent,
    CiteUsComponent,
    // Pipes
    CommentTypePipe,
    SubmitButtonComponent,
    VariableSelectComponent,
    PaperOverallInfoComponent,
    PaperDecisionComponent,
    PaperCommentComponent,
    ShowTagsComponent,
    AddTagToPaperComponent,
    EmailNotVerifiedWarningComponent,
    DropboxNotConnectedWarningComponent,
    CopyClipboardDirective,
    HandleAssignmentComponent,
    ShowAssignmentComponent,
    NoAccessPageComponent,
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NebularModule,
    MaterialModule,
    NgxFileDropModule,
    NgCircleProgressModule,
    NgxDaterangepickerMd,
    DxCircularGaugeModule,
    DxBarGaugeModule,
    DxPieChartModule,
    DxChartModule,
    Ng5SliderModule,
    NgSelectModule,
    // Components
    SearchInputComponent,
    ProfileActionComponent,
    HubMetaMenuComponent,
    MainHeaderComponent,
    ContributionRatioComponent,
    BreadcrumbComponent,
    ProgressSpinnerComponent,
    ProgressButtonComponent,
    BreadcrumbTagComponent,
    DataEntryFiltersComponent,
    VariablesTreeComponent,
    NotificationComponent,
    PaginationComponent,
    EmailDesignPaginationComponent,
    ReviewCommentComponent,
    ConfirmModalComponent,
    NoContentComponent,
    CountoModule,
    SubmitButtonComponent,
    // Pipes
    CommentTypePipe,
    NgxSpinnerModule,
    VariableSelectComponent,
    PaperOverallInfoComponent,
    PaperDecisionComponent,
    PaperCommentComponent,
    GuidePopoverComponent,
    RcodeModalComponent,
    ShowTagsComponent,
    ShowAssignmentComponent,
    AddTagToPaperComponent,
    HandleAssignmentComponent,
    EmailNotVerifiedWarningComponent,
    DropboxNotConnectedWarningComponent,
    // directive
    CopyClipboardDirective
  ],
  providers: [
    NgxSpinnerService
  ],
  entryComponents: [
    ConfirmModalComponent,
    AddTagToPaperComponent,
    HandleAssignmentComponent,
    CiteUsComponent
  ]
})
export class SharedModule {
}
