import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent implements OnInit {
  @Input() progressValue;
  @Input() buttonLabel: string;
  @Input() disabled: boolean;
  @Output() handleClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hasExportIcon: boolean;
  newValue: number;
  inProgress: boolean;
  constructor() { }

  ngOnInit() {
  }
  runFunction() {
    if (!this.disabled) {
      this.handleClick.emit(true);
    }
  }
}
