import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '@env';
import {ProfileReducer} from '@modules/profile/state/profile.reducer';
import {AppState} from '@shared/schema/app.state';
import {ProjectListReducer} from '@modules/dashboard/state/project-list.reducer';
import {ProjectReducer} from '@modules/project/state/project.reducer';
import * as mainActions from '@shared/reducers/main.actions';
import {PublicProjectListReducer} from '@modules/dashboard/state/public-project-list.reducer';

export const reducers: ActionReducerMap<AppState> = {
  profile: ProfileReducer,
  projectList: ProjectListReducer,
  publicProjectList: PublicProjectListReducer,
  project: ProjectReducer
};
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log('state', state);
    console.log('action', action);
    return reducer(state, action);
  };
}
export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {

    if (action.type === mainActions.signOuts.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearState] : [clearState];
