import { Action, createReducer, on } from '@ngrx/store';
import {ProjectListState} from '@shared/schema/project-list.state';
import * as projectListAction from '@modules/dashboard/state/project-list.actions';

export const projectListFeatureKey = 'projectList';

export const initialState: ProjectListState = {
  list: null
};

const projectListReducer = createReducer(
  initialState,
  on(projectListAction.loadProjectListsSuccess, (state, action) => ({ ...state, list: action.list })),

);

export function ProjectListReducer(state: ProjectListState | undefined, action: Action) {
  return projectListReducer(state, action);
}
