import { HttpParams } from '@angular/common/http';

export class HelperService {
    constructor() {}
  getHttpParams(object): HttpParams {
    let httpParams = new HttpParams();
    if (object) {
      Object.keys(object).forEach((key) => {
        if (object[key] !== null && object[key] !== undefined) {
          httpParams = httpParams.append(key, object[key]);
        }
      });
    }
    return httpParams;
  }
  getQueryStringParams(object): string {
    return object
      ? Object.keys(object)
          .filter(key => object[key] !== null && object[key] !== undefined)
          .map((key) => (object[key] ? key + '=' + object[key] : ''))
          .join('&')
      : '';
  }
}
