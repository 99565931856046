import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSlideToggleChange } from '@angular/material';
import { AuthenticationService } from '@core/service/authentication.service';
import { ProjectMember as ProfileMember } from '@shared/schema/profile';
import { PermissionItem, ProjectMemberList, ProjectMember } from '@shared/schema/project';
import { addMemberToProject, loadMemberList } from '@modules/project/state/project.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { Subscription } from 'rxjs';
import { ProjectMenu } from '@shared/data/menu';
import { ProjectService } from '@core/service/project.service';
import { MenuItem } from '@shared/schema/menu-item';


@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  users: ProfileMember[] = [];
  loading = false;
  inviteForm: FormGroup;
  permissions: PermissionItem[];
  memberList: ProjectMemberList;
  searchTimeout;
  pages: any[] = [];
  user: ProjectMember;
  pageAccess = {};
  constructor(
    public dialogRef: MatDialogRef<InviteMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: number, user?: ProjectMember },
    private builder: FormBuilder,
    private authService: AuthenticationService,
    private projectService: ProjectService,
    private store: Store<AppState>,
  ) {
    this.getPages();
  }
  getPages() {
    const pages = [];
    ProjectMenu.map((val) => {
      if (val.elementId === 'workspace') {
        pages.push(val);
      } else if (val.children) {
        pages.push(...val.children);
      } else {
        pages.push(val);
      }
    });
    pages.forEach(val => {
      if (val.elementId === 'title_screening' || val.elementId === 'fulltext_screening') {
        this.pageAccess = {
          ...this.pageAccess,
          [val.elementId]: 2,
        };
      } else {
        this.pageAccess = {
          ...this.pageAccess,
          [val.elementId]: true,
        };
      }
    });

    this.pages = pages;
    this.pages.push({
      elementId: 'ai_assistant',
      hasChildren: false,
      link: 'ai-assistant',
      pathMatch: 'prefix',
      title: 'Ai Assistant'
    });

    this.pageAccess = {
      ...this.pageAccess,
      members: false,
      ai_assistant: true
    };
  }

  ngOnInit() {
    this.buildForm();
    if (this.data.user) {
      this.getMemberDetail();
    }
    this.dialogRef.afterOpened().subscribe(() => {
      this.subscription = [
        this.store.select(state => state.project.successAlertMessage).subscribe(
          (state) => {
            if (state) {
              this.cancel({ added: true });
            }
          }
        ),
        this.store.select(state => state.project.projectPermissions).subscribe(
          (state) => {
            if (state) {
              this.permissions = state.data;
            }
          }
        ),
        this.store.select(state => state.project.memberList).subscribe(
          (state) => {
            if (state) {
              this.memberList = state;
            } else {
              this.store.dispatch(loadMemberList({ projectId: this.data.projectId, page: 1, page_size: 10 }));
              this.memberList = null;
            }
          }
        )
      ];
    });
  }
  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }
  getMemberDetail() {
    this.projectService.getMember(this.data.user.pk).subscribe(val => {
      this.user = val;
      for (const page of this.pages) {
        this.pageAccess = {
          ...this.pageAccess,
          [page.elementId]: val.project_access[page.elementId],
        };
      }
    });
  }
  buildForm() {
    if (this.data.user) {
      this.inviteForm = this.builder.group({});
    } else {
      this.inviteForm = this.builder.group({
        user: [null, [!this.data.user && Validators.required]],
      });
    }

  }
  createParams() {
    const { user } = this.inviteForm.value;
    const member = {
      user_id: this.data.user ? this.data.user.user.user_id : user.user_id,
      // permission_id: this.pageAccess[String('title_screening')] === 2 ?
      //   this.permissions.find(val => val.name === 'Reviewer').pk :
      //   this.permissions.find(val => val.name === 'Researcher').pk,
      project: this.data.projectId,
      project_access: this.pageAccess
    };
    return member;
  }
  userSearch(event) {
    if (event.term.length > 2) {
      this.loading = true;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      this.searchTimeout = setTimeout(() => {
        this.authService.getUsers(event.term).subscribe((userList) => {
          this.loading = false;
          this.users = userList.data.filter(
            item => !(this.memberList ? this.memberList.data : []
            ).map(user => user.user.user_id).includes(item.user_id));
        });
      }, 750);
    }
  }
  customSearchFn(term: string, user: ProfileMember) {
    let fullName;
    let username;
    let email;
    if (user) {
      term = term.toLowerCase();
      user.full_name ? fullName = user.full_name.toLowerCase().indexOf(term) : fullName = -1;
      user.username ? username = user.username.toLowerCase().indexOf(term) : username = -1;
      user.email ? email = user.email.toLowerCase().indexOf(term) : email = -1;
      return fullName > -1 || username > -1 || email > -1;
    }

  }
  cancel(value?) {
    this.dialogRef.close(value);
  }
  handleSubmission() {
    if (this.data.user) {
      this.editMember();
    } else {
      this.inviteMember();
    }
  }
  editMember() {
    this.projectService.editProjectMember(this.data.user.pk, this.createParams()).then((result) => {
      this.cancel({ added: true });
    });
  }
  inviteMember() {
    this.store.dispatch(addMemberToProject({ member: this.createParams() }));

  }
  handleChangePageAccess(value: MatSlideToggleChange, page: MenuItem, accessType?: 1 | 2) {
    if (page.elementId === 'title_screening' || page.elementId === 'fulltext_screening') {
      if (value.checked) {
        this.pageAccess = {
          ...this.pageAccess,
          [page.elementId]: accessType,
        };
      } else {
        this.pageAccess = {
          ...this.pageAccess,
          [page.elementId]: 0,
        };
      }
    } else {
      this.pageAccess = {
        ...this.pageAccess,
        [page.elementId]: value.checked
      };
    }
  }

}
