import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectService} from '@core/service/project.service';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import { guides } from '@shared/data/guides';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  validFormats: string[] = ['.csv', '.json', '.hubmeta'];
  projectForm: FormGroup;
  files: NgxFileDropEntry[] = [];
  selectedFile: File;
  progress: number;
  nameError: string;
  slugError: string;
  projectImage: string;
  guide = {
    slug: guides.dashboard.slug,
    import: guides.dashboard.uploadHubmeta
  };
  constructor(
    public dialogRef: MatDialogRef<NewProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private builder: FormBuilder,
    private projectService: ProjectService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
    this.buildForm();
    this.projectForm.get('name').valueChanges.subscribe(
      value => {
        this.projectForm.patchValue({
          slug: this.createSlug(value)
        });
      }
    );
  }
  buildForm() {
    this.projectForm = this.builder.group({
      name: ['', Validators.required],
      slug: ['', [Validators.required, Validators.pattern('[a-zA-z0-9\-]*')]],
      description: [''],
      import_file: [''],
      image: [null]
    });
  }
  get image(): FormControl {
    return this.projectForm.get('image') as FormControl;
  }
  get importFile(): FormControl {
    return this.projectForm.get('import_file') as FormControl;
  }
  get slug(): FormControl {
    return this.projectForm.get('slug') as FormControl;
  }
  projectImageSelect(event) {
    const projectImage: File = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(projectImage);
    reader.onload = (progressEvent) => {
      this.projectImage = progressEvent.target[`result`].toString();
      this.projectForm.patchValue({image: projectImage});
    };
  }
  projectFileDrop(files: NgxFileDropEntry[]) {
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    this.projectForm.patchValue({
      import_file: fileEntry,
    });
    fileEntry.file((file: File) => {
      if (this.validateFormat(file.name)) {
        this.projectForm.patchValue({import_file: file});
      } else {
        this.showToast();
      }
    });
  }
  validateFormat(name): boolean {
    let valid = false;
    this.validFormats.forEach((format) => {
      if (name.indexOf(format) >= 0) {
        valid = true;
      }
    });
    return valid;
  }
  createProject() {
    const { name, slug, description, image, import_file } = this.projectForm.value;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('slug', slug);
    formData.append('description', description);
    if (import_file) {
      formData.append('import_file', import_file, import_file.name);
    }
    if (image) {
      formData.append('image', image, image.name);
    }
    this.projectService.createProject(formData).then(
      (response) => this.dialogRef.close({create: true, id: response.pk})
    ).catch(
      (projectError) => {
      if (projectError instanceof HttpErrorResponse && projectError.error) {
        if (projectError.error.name) {
          this.projectForm.get('name').setErrors({ invalid: true });
          this.nameError = projectError.error.name.toString();
        }
        if (projectError.error.slug) {
          this.projectForm.get('slug').setErrors({ invalid: true });
          this.slugError = projectError.error.slug.toString();
        }
      }
    });
  }
  showToast() {
    this.toastrService.show(
      'Only these extensions can be uploaded(.csv, .json, .hubmeta)',
      'Invalid Extensions',
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'danger',
        duration: 3000
      });
  }
  cancel() {
    this.dialogRef.close({create: false});
  }

  createSlug(text) {
    if (text) {
      return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-and-')         // Replace & with -and-
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');           // Trim - from end of text
    }
    return text;
  }
}
