import {Component, Input, OnInit} from '@angular/core';
import {ProjectMenu} from '@shared/data/menu';
import {MenuItem} from '@shared/schema/menu-item';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NbSidebarService} from '@nebular/theme';
import {filter, map} from 'rxjs/operators';
import {AppState} from '@shared/schema/app.state';
import {Store} from '@ngrx/store';
import {loadAnalysisFlow, loadAnalysisResult} from '@modules/project/state/project.actions';
import {ProjectAccess, ProjectDetails} from '@shared/schema/project';
import {UserInfo} from '@shared/schema/profile';

@Component({
  selector: 'app-hub-meta-menu',
  templateUrl: './hub-meta-menu.component.html',
  styleUrls: ['./hub-meta-menu.component.scss']
})
export class HubMetaMenuComponent implements OnInit {
  projectId: string;
  @Input() menuItems: MenuItem[] = ProjectMenu;
  currentUrl: string;
  activePrent = '';
  parentToChildMap = {};
  projectAccess: ProjectAccess;
  userInfo: UserInfo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sidebarService: NbSidebarService,
    private store: Store<AppState>
  ) {
    if (this.route.snapshot.data.member) {
      this.projectAccess = this.route.snapshot.data.member.project_access;
    } else {
      this.projectAccess = {
        details: true,
        activity: false,
        analytics: false,
        arm: false,
        import_article: false,
        files: true,
        papers: true,
        deduplication: false,
        title_screening: 2,
        fulltext_screening: 2,
        data_entry: true,
        analysis: true,
        general: false,
        members: false,
        workspace: false,
        screening_category: false,
        member: false,
        taxonomy_citation: true,
        taxonomy_ai_variable: false,
        taxonomy_construct: true,
        taxonomy_files: true,
        taxonomy_group_construct: true,
        taxonomy_measurement: true,
        taxonomy_moderators: true,
        taxonomy_tags: true,
        taxonomy_variable_set: true,
        taxonomy_visualization: true,

      };
    }
    this.menuItems = ProjectMenu.map(val => {
      if (val.children) {
        const children = val.children.filter(child => !!this.projectAccess[child.elementId]);
        if (children.length === 0) {
          return null;
        }
        return {
          ...val,
          children
        };
      } else {
        if (!!this.projectAccess[val.elementId]) {
          return val;
        }
        return null;
      }
    }).filter(val => val);
    for (const parent of this.menuItems.filter((i) => i.hasChildren)) {
      parent.children.forEach((child) => {
        this.parentToChildMap[child.link] = parent.elementId;
      });
    }
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.projectId;
    this.setUrl(this.router.url);
    this.router.events.pipe(
      filter(
        (routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd,
        map((route) => route)
      )
    ).subscribe((routeChange) => {
      this.setUrl(routeChange.url);
    });
  }

  setUrl(url: string) {
    const URL = url.substring(10 + this.projectId.length);
    this.currentUrl = URL;
    this.activeChild(URL);
  }

  onMenuAction(elementId: string) {
    if (document.getElementsByTagName('body')[0].classList.contains('compacted')) {
      document.getElementsByTagName('body')[0].classList.remove('compacted');
      document.getElementsByTagName('body')[0].classList.add('expanded');
      this.sidebarService.toggle(true, 'left');
      document.getElementById(`nested-${elementId}`).classList.add(`active`);
      document.getElementById(`nested-${elementId}`).classList.add(`animated`);
      document.getElementById(`caret-${elementId}`).classList.add(`caret-down`);
    } else {
      document.getElementById(`nested-${elementId}`).classList.toggle(`active`);
      document.getElementById(`nested-${elementId}`).classList.toggle(`animated`);
      document.getElementById(`caret-${elementId}`).classList.toggle(`caret-down`);
    }
    this.sidebarService.expand();
  }

  navigate(url, toChild = false) {
    if (url === 'analysis') {
      this.store.dispatch(loadAnalysisFlow({
        flow: {
          project: null,
          analys_type: null,
          analysis_method: null,
          filter_id: null,
          return_fields: [],
          api_format: 'api',
          structure: []
        }
      }));
      this.store.dispatch(loadAnalysisResult({result: null}));
    }
    this.router.navigate([`project/${this.projectId}/${url}`]).then(
      () => {
        this.currentUrl = url;
        this.activeChild(this.currentUrl);
      });
  }

  activeChild(url: string) {
    this.activePrent = this.parentToChildMap[url];
  }
}
