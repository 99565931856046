import { Pipe, PipeTransform } from '@angular/core';
import { CommentType } from '@shared/schema/project';

@Pipe({
  name: 'commentType'
})

export class CommentTypePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const type = CommentType[value];
    if (type) {
      return type;
    }
    switch (value) {
      case 1:
        return 'Yes';
      case 2:
        return 'No';
      case 3:
        return 'Maybe';
      case 6:
        return 'Contact';
      default:
        return '';
    }
  }

}
