import {NbComponentStatus} from '@nebular/theme';
import {UserInfo} from '@shared/schema/profile';
import {CommentStatusOptions, CorrelationOutcomeVariable, PaperComment} from '@shared/schema/statistic';

// Details
export interface ProjectDetails {
  pk: number;
  name: string;
  error_lines: number[];
  description: string;
  slug: string;
  image: string;
  members: ProjectMemberActivityStatus[];
  moderator_count: string;
  group_construct_count: number;
  tags_count: number;
  construct_count: number;
  measurement_count: number;
  owner: UserInfo;
  my_role: PermissionItem;
  import_article_pending: boolean;
  is_deduplicated: boolean;
  deduplication_task_id?: string;
  import_task_id?: string;
  reliability_default_value: number;
  libmeta_last_update?: string;
  composite_task_id?: string;
  asreview_comment_task_id?: string;
  has_active_import?: boolean;
  papers_count?: number;
  fulltext_to_data_entry_assignment?: number;
  title_to_fulltext_assignment?: number;
  feature_flag?: FeatureFlag;
  role_status: string;
}

export interface FeatureFlag {
  id: number;
  ai_variable: boolean;
  project: number;
}

export interface ProjectMemberActivityStatus {
  profile: ProjectMemberProfile;
  deduplication_progress: number;
  title_progress: number;
  full_text_progress: number;
  data_progress: number;
}

export interface ProjectMemberProfile {
  full_name: string;
  username: string;
  country: string;
  avatar: string;
  institute: string;
  job_title: string;
  bio: string;
  email: string;
}

// Import Article
export interface ImportArticleStatusList {
  num_of_pages: number;
  count: number;
  data: ImportArticle[];
}

export interface ImportArticle {
  pk: number;
  name: string;
  keyword: string;
  source: string;
  file: string;
  created_at: string;
  articles_count: number;
  new_articles_count: number;
  new_percent: number;
  total_count: number;
  error_lines?: any[];
  total_percent: number;
  status: number; // 1 => Pending, 2 => Added, 3 => Failed
  progress: number;
  user: UserInfo;
  task_id: string;
  is_deduplicated: boolean;
  tags?: Tag[];
  fetchingDownloadUrl?: boolean;
  data_entry_directly_imported: any;
  date_of_search: any;
  deduplication_need: any;
  count_in_prisma: boolean;
}

export interface ImportArticleFilterParams {
  tags__name?: string;
  tags__name__in?: string;
  tags__name__icontains?: string;
  source?: string;
  keyword__in?: string;
  keyword__icontains?: string;
  ordering?: string;
  page?: string;
  page_size?: string;
  project?: string;
  name__icontains?: string;
  name?: string;
}

export interface ImportArticleStatus {
  pk: number;
  progress: number;
}

export interface DeduplicationStatus {
  progress: number;
  isDeduplicated: boolean;
}

export interface DeduplicationReviewList {
  num_of_pages: number;
  count: number;
  data: DeduplicationReviewItem[];
}

export interface DeduplicationReviewItem {
  pk: number;
  name: string;
  author: string;
  journal: string;
  year: number;
  link: string;
  abstract: string;
  status: number;
  related_paper?: DeduplicationReviewItem;
  type: string;
}

// Project Activity
export interface ProjectActivityItem {
  member: UserInfo;
  comment: ActivityComment;
  correlation: ActivityCorrelation;
  batch_paper: ActivityBatchPaper;
  created_at: string;
}

export interface ActivityComment {
  id: number;
  comment: string;
  status: number;
  var_type: number;
  paper: number;
  user: number;
}

export interface ActivityCorrelation {
  id: number;
  first_variable: number;
  second_variable: number;
  correlation: number;
  user: number;
}

export interface ActivityBatchPaper {
  id: number;
  created_at: string;
  keyword: string;
  source: string;
  file: string;
  articles_count: number;
  new_articles_count: number;
  project: number;
  user: number;
}

// Project Member
export interface ProjectPermissionList {
  num_of_pages: number;
  count: number;
  data: PermissionItem[];
}

export interface PermissionItem {
  pk: number;
  name: 'Owner' | 'Researcher' | 'Reviewer';
}

export interface ProjectMemberList {
  num_of_pages?: number;
  count?: number;
  data: ProjectMember[];
}

export interface ProjectMember {
  pk: number;
  created_at: number;
  user: UserInfo;
  role?: string;
  project: number;
  project_access: ProjectAccess;
  assignments_summary: any;
}

export interface ProjectAccess {
  details: boolean;
  activity: boolean;
  analytics: boolean;
  arm: boolean;
  import_article: boolean;
  files: boolean;
  papers: boolean;
  deduplication: boolean;
  title_screening: PapersAccess;
  fulltext_screening: PapersAccess;
  data_entry: boolean;
  analysis: boolean;
  general: boolean;
  members: boolean;
  workspace: boolean;
  screening_category: boolean;
  member: boolean;
  taxonomy_citation: boolean;
  taxonomy_ai_variable: boolean;
  taxonomy_construct: boolean;
  taxonomy_files: boolean;
  taxonomy_group_construct: boolean;
  taxonomy_measurement: boolean;
  taxonomy_moderators: boolean;
  taxonomy_tags: boolean;
  taxonomy_variable_set: boolean;
  taxonomy_visualization: boolean;
}

export enum PapersAccess {
  'researcher' = 1,
  'reviewer' = 2,
  'no-access' = 1,
}

export interface PaperItemInfo {
  pk: number;
  name: string;
  author: string;
  journal: string;
  year: number;
  link?: string;
  abstract?: string;
  status?: PaperStatusType;
  my_comment?: PaperComment;
  comments?: PaperComment[];
  language?: string;
}

export interface PrismaGraphData {
  records_identified_through_database_searching: number;
  additional_records_identified_through_other_source: number;
  records_after_duplicates_removed: number;
  records_screen: number;
  records_excluded: number;
  fulltext_articles_assessed_for_eligibility: number;
  fulltext_articles_excluded_with_reasons: number;
  studies_included_in_qualitative_synthesis: number;
  studies_included_in_qualitative_synthesis_analysis: number;
  fulltext_reports_excluded: any;
  records_searching_data_entry_directly: any;
}

export interface ProjectVariable {
  id: number;
  parent: number;
  name: string;
  var_type: number;
  usage?: string;
  parent__isnull?: boolean;
}

// Data Entry
export interface CreateDataEntryFilters {
  filter_set: FilterSet;
  name: string;
  project: number;
}

export interface FilterSet {
  page?: number;
  page_size?: number;
  project?: number;
  status?: number | number[];
  status__in?: number[] | string;
  name_without_punc__icontains?: string;
  author_without_punc__icontains?: string;
  journal__icontains?: string;
  batch_source?: string;
  year_rng?: string;
  year_rng_max?: number;
  year_rng_min?: number;
  sample_size_rng_max?: number;
  sample_size_rng_min?: number;
  with_number_of_variables_rng_max?: number;
  with_number_of_variables_rng_min?: number;
  sample_size_rng?: string;
  with_number_of_variables_rng?: string;
  has_outcome?: string;
  contains_some_variables?: string;
  contains_all_variables?: string;
  not_contains_some_variables?: string;
  moderator_variable_value?: string;
  ordering?: string;
  language?: string;
  review?: string;
  decision?: string;
  discrepancy?: string;
  comment_type?: string;
  comment_status?: CommentStatusOptions;
  id?: string;
  id__in?: string;
  subject__name__in?: string;
  subject__name?: string;
  subjects__id__in?: string;
  libmeta_exist?: boolean;
  has_pdf?: boolean;
  paper_pdf__isnull?: boolean;
  selectedPaperId?: number;
  change_status_to?: number;
  comment__comment__icontains?: string;
  note__icontains?: string;
  abstract__icontains?: string;
  comment__comment_icontains?: string;
  user_comments?: string;
  comment_numbers?: string;
  yes_comment_ratio?: string;
  id__exact?: number;
  link__in?: string;
  link?: string;
  doi?: string;
  section?: string;
  tags?: string;
  tmpSelectedTags?: any;
  tmpSelectedSubjects?: any;
  export_format?: any;
  ai_variables_need?: string[];
}

export interface ProjectRanges {
  with_number_of_variables_min: number;
  with_number_of_variables_max: number;
  year_min: number;
  year_max: number;
  sample_size_min: number;
  sample_size_max: number;
  number_of_comments_min: number;
  number_of_comments_max: number;
}

export interface Moderator {
  id: number;
  name: string;
  var_type: number; // [ 1 => string; 2 => choice, 3 => int ]
  default: any;
  project: number;
}

export interface AiVariable {
  id: number;
  name: string;
  var_type: number; // [ 1 => string; 2 => choice, 3 => int ]
  default: any;
  project: number;
}

export enum ModeratorType {
  'string' = 1,
  'choice',
  'int',
}

export interface ModeratorList {
  num_of_pages: number;
  count: number;
  data: Moderator[];
}

export interface AiVariableList {
  num_of_pages: number;
  count: number;
  data: AiVariable[];
}

export interface ModeratorDefaultOption {
  id: number;
  name: string;
}

export interface PaperFilter {
  filter_set: FilterSet;
  name?: string;
  project?: number;
  id?: number;
}

export interface PaperList {
  num_of_pages: number;
  count: number;
  data: PaperItem[];
}

export interface PaperItem {
  id: number;
  pk?: number;
  modified_at: string;
  name: string;
  name_without_punc: string;
  author: string;
  author_without_punc: string;
  journal: string;
  year: number;
  link: string;
  abstract: string;
  status: number;
  language: string;
  project: number;
  batch: number;
  related_paper: number;
  used_variables: number[];
  file_data?: string;
  my_comment?: UserComment[];
  comments?: PaperComment[];
  libmeta_exist?: boolean;
  libmeta_last_update?: string;
  pdf_files?: UploadedPdf[];
  note?: string;
  google_scholar_link?: string;
  tags?: Tag[];
  assignments: any[];
  importer: any;
}

export interface UserComment {
  comment: string;
  pk?: number;
  paper: number;
  created_at?: string;
  status: 1 | 2;
  var_type: CommentType;
  screening_reason: number;
}

export interface UploadedPdf {
  id?: number;
  file_server: number;
  file: string[];
  name?: string;
}

export interface AnalysisFlow {
  project: number;
  // this typo is from back end guys do not correct it!
  analys_type: number;
  filter_id: number;
  analysis_method: number;
  return_fields: string[];
  api_format?: string; // [ api => api, export_r => export_r ]
  structure: AnalysisFlowStructure[];
}

export interface AnalysisFlowStructure {
  confidence: number;
  credibility: number;
  variable_a: number[];
  variable_b: number[];
  skip_time: boolean;
  moderator_variable: string;
  moderator_value: string;
  outlier_distance: number;
  excluded_correlations?: number[];
}

export interface IExportWideFormat {
  analysis_type: number;
  filter_id: number;
  excluded_correlations: number[];
  variable_a: number[];
  variable_b: number[];
  skip_time: boolean;
}

export interface SaveAnalysisInfo {
  project: number;
  title: string;
  analysis_type: number;
  filter_id: number;
  analysis_method: number;
  return_fields: string[];
  api_format?: string; // [ api => api, export_r => export_r ]
  structure: AnalysisStructure[];
}

export interface AnalysisExportR {
  project: number;
  analysis_method: number;
  filter_id: number;
  structure: AnalysisStructure[];
}

export interface ExportRResult {
  project: number;
  analysis_method: number;
  filter_id: number;
  structure: AnalysisStructure[];
  res: string;
}

export interface AnalysisStructure {
  confidence: number;
  credibility: number;
  variable_a: number[];
  variable_b: number[];
  moderator_variable: string;
  moderator_value: string;
}

export interface AnalysisResult {
  analysis_method: number;
  variable_a: CorrelationOutcomeVariable[];
  variable_b: CorrelationOutcomeVariable[];
  aggregate_sample_size?: number;
  average_sample_size?: number;
  number_of_correlation?: number;
  K: number;
  N: number;
  mean_r: number;
  sd_r: number;
  se_r: number;
  sd_res: number;
  CI_LL_95: number;
  CR_LL_80: number;
  CR_UL_80: number;
}

export interface GroupVariables {
  name: string;
  variables: CorrelationVariable[];
  project: number;
  id: number;
}

export interface CorrelationVariable {
  readonly id: number;
  name: string;
  correlation_type?: string;
  definition: string;
  var_type: CorrelationVariableType;
  readonly deleted: boolean;
  project: number;
  parent: number;
  relation_with_parent?: VariableRelationWithParent;
  citations: Citation[];
  tags: Tag[];
  survey?: string;
  usage?: string;
  readonly created_at?: string;
  readonly modified_at?: string;
  reliability_default_value_data?: number;
}

export type WorkspaceType =
  | 'Group Construct'
  | 'Construct'
  | 'Measurement'
  | 'Moderator Variables'
  | 'Citations'
  | 'Variable set'
  | 'Visualization'
  | 'correlationVariable';

export enum VariableRelationWithParent {
  'indirect',
  'direct',
}

export interface CorrelationVariableToPost {
  readonly id?: number;
  name: string;
  definition?: string;
  var_type: CorrelationVariableType;
  project: number;
  parent?: number;
  relation_with_parent?: VariableRelationWithParent;
  citations_id?: number[];
  tags_id?: number[];
  survey?: string;
  reliability_default_value_data: number;
  readonly deleted?: boolean;
}

export enum CorrelationVariableType {
  'measurement' = 1,
  'construct',
  'group construct'
}

export interface CorrelationVariableList {
  num_of_pages: number;
  count: number;
  data: CorrelationVariable[];
}

export interface ProjectVariablesQueryParams {
  project?: string;
  parent?: string;
  parent__isnull?: boolean;
  parent__in?: string;
  name?: string;
  name__icontains?: string;
  var_type?: string;
  var_type__in?: number;
  page?: number;
  page_size?: number;
  tags__name__in?: any;
  tags__name?: string;
  tags__in?: any;
  variablefile__in?: any;
  tags__name__icontains?: string;
  citations__name__in?: any;
  citations__in?: any;
  citations__name?: string;
  citations__name__icontains?: string;
  definition__icontains?: string;
  citations?: string;
  ordering?: CorrelationVariablesOrdering;
  author?: string;
  author__icontains?: string;
  year?: string;
  year_rng_min?: string;
  year_rng_max?: string;
  year__in?: string;
  journal?: string;
  journal__icontains?: string;
  link?: string;
  link__icontains?: string;
  search?: string;
}

export enum CorrelationVariablesOrdering {
  parent_asc = 'parent',
  parent_desc = '-parent',
  name_asc = 'name',
  name_desc = '-name',
  definition_asc = 'definition',
  definition_desc = '-definition',
  modified_at_asc = 'modified_at',
  modified_at_desc = '-modified_at',
  created_at_asc = 'created_at',
  created_at_desc = '-created_at',
  pk_asc = 'pk',
  pk_desc = '-pk',
  usage_asc = 'usage',
  usage_desc = '-usage',
  correlation_usage_asc = 'correlation_usage',
  correlation_usage_desc = '-correlation_usage',
}

export interface Citation {
  readonly id: number;
  name: string;
  journal: string;
  author: string;
  year: number;
  link: string;
  project: number;
  readonly created_at?: string;
  readonly modified_at?: string;
}

export interface CitationList {
  num_of_pages: number;
  count: number;
  data: Citation[];
}

export interface Tag {
  readonly id: number;
  name: string;
  slug: string;
  color?: string;
  usage?: number;
  project?: number;
  readonly created_at?: string;
  readonly modified_at?: string;
}

export interface TagList {
  num_of_pages: number;
  count: number;
  data: Tag[];
}

export interface VariablesGroupQueryParams {
  project?: string;
  ordering?: string;
}

export interface VariablesGroup {
  name: string;
  readonly id?: number;
  variables?: CorrelationVariable[];
  project: number;
}

export interface VariablesGroupToCreate {
  readonly id?: number;
  name: string;
  variables_id: number[];
  project: number;
}

export interface SearchPapersAndVariablesResult {
  readonly id?: number;
  type: SearchPapersAndVariablesType;
  status: SearchPapersAndVariableStatus;
  name: string;
}

export enum SearchPapersAndVariablesType {
  'Correlation Variable' = 1,
  'Moderator Variable',
  'Paper'
}

export enum SearchPapersAndVariableStatus {
  'measurement' = 1,
  'construct',
  'group construct',
  'title screening',
  'reject in full text screening',
  'full text screening',
  'data entry',
  'analysis'
}

export enum CommentType {
  'Yes' = 1,
  'No',
  'Maybe',
  'Accept' = 7,
  'Reject' = 8,
}

export enum Review {
  'Yes' = 1,
  'No',
  'All'
}

export enum Decision {
  'Yes' = 1,
  'No',
  'All'
}

export enum Discrepancy {
  'same' = 1,
  'different'
}

export enum PaperStatusType {
  'reject in deduplication' = 1,
  'deduplication',
  'reject in title screening',
  'title screening',
  'reject in full text screening',
  'full text screening',
  'data entry',
  'analysis'
}

export enum HistoryType {
  'Created',
  'Changed',
  'Deleted'
}

export enum BatchFileStatusType {
  'Pending' = 1,
  'Added',
  'Faild'
}

export interface BatchFile {
  pk: number;
  name: string;
  created_at: string;
  keyword: string;
  source: string;
  file: string;
  articles_count: number;
  is_deduplicated: boolean;
  new_articles_count: number;
  new_percent: string;
  total_count: number;
  total_percent: string;
  status: number;
  tags: Tag[];
  date_of_search: string;
  owner?: UserInfo;
  history_change_reason?: string;
  history_date: string;
  history_id?: number;
  history_type?: string;
  history_user?: number;
  user?: number;
  task_id?: string;
}

export interface BatchFileList {
  count: number;
  num_of_pages: number;
  data: BatchFile[];
}

export interface HistoryQueryParams {
  project?: number;
  page?: number;
  page_size?: number;
  name?: string;
  name__icontains?: string;
  ordering?: string;
  paper__project?: number;
}

export interface PaperCommentList {
  count: number;
  num_of_pages: number;
  data: PaperComment[];
}

export interface PaperHistory {
  history_id: number;
  id: number;
  modified_at: string;
  name: string;
  name_without_punc: string;
  author: string;
  author_without_punc: string;
  journal: string;
  year: number;
  link: string;
  abstract: string;
  status: PaperStatusType;
  language: string;
  history_date: string;
  history_change_reason: string;
  history_type: HistoryType;
  project: number;
  batch: number;
  related_paper: number;
  history_user: number;
  user?: number;
}

export interface PaperHistoryList {
  count: number;
  num_of_pages: number;
  data: PaperHistory[];
}

export interface TaskProgress {
  status: TaskStatus;
  id: string;
  result?: {
    current: number
  } | any;
}

export enum TaskStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PROGRESS = 'PROGRESS',
  PENDING = 'PENDING',
}

export interface CorrelationTableSelectableVariable {
  name: string;
  id: number;
  tag: number;
}

export interface NewProjectMember {
  project: number;
  user_id: number;
}

export enum StaticFieldsOfCorrelationTable {
  'MIN' = 1,
  'MAX',
  'MEAN',
  'SD',
  'ALPHA',
  'REVERSE SCALE',
  'AGGREGATED',
  '# OF ITEMS',
}

export interface Toast {
  message: string;
  title?: string;
  status: NbComponentStatus;
}

export interface ContactList {
  num_of_pages: number;
  count: number;
  data: Contact[];
}

export interface Contact {
  pk: number;
  name: string;
  author: string;
  journal: string;
  type: string;
  year: number;
  link: string;
  abstract: string;
  status: PaperStatusType;
  contact_list_comments: ContactComment[];
  libmeta_exist: boolean;
  language: string;
  libmeta_last_update: string; // DateTime
  fulltext_note: string;
}

export interface ContactComment {
  pk: number;
  status: number;
  var_type: number;
  comment: string;
  paper: number;
  screening_reason: null;
  user: UserInfo;
}
