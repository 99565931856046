import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CorrelationVariable, CorrelationVariableList, ProjectVariable } from '@shared/schema/project';
import { Subscription } from 'rxjs';
import { AppState } from '@shared/schema/app.state';
import { Store } from '@ngrx/store';
import { filterVariables } from '@modules/project/state/project.actions';
import { NgSelectSearch } from '@modules/project/components/add-new-workspace/add-new-workspace.component';
import { ProjectService } from '@core/service/project.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-variable-select',
  templateUrl: './variable-select.component.html',
  styleUrls: ['./variable-select.component.scss']
})
export class VariableSelectComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;
  @Input() projectId: number;
  @Input() selectedValues: number[] = [];
  @Output() emitSelectedVariables = new EventEmitter<number[]>();
  visuals: ProjectVariable[];
  variables: CorrelationVariable[] = [];
  subscription: Subscription[];
  variablesLoading = true;
  searchTerm = '';
  selectedVariables: CorrelationVariable[] = [];
  form: FormControl;
  constructor(
    private store: Store<AppState>,
    private service: ProjectService,
  ) { }

  ngOnInit() {
    this.subscription = [
      this.store
        .select(state => state.project.filteredVariables)
        .subscribe(filteredVariables => {
          if (filteredVariables) {
            this.variables = filteredVariables.data;
            this.variablesLoading = false;
          } else {
            this.loadVariable();
          }
        }),
    ];
  }
  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }
  customFilter(term: string, item: ProjectVariable) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1;
  }
  loadVariable() {
    this.service.getProjectVariableList({
      page: 1,
      page_size: 10,
      project: this.projectId.toString(),
      name__icontains: this.searchTerm
    }, true).subscribe(
      response => {
        if (response) {
          this.variables = response.data;
          this.variablesLoading = false;
        }
      },
      error => {
        this.variablesLoading = false;
      }
    );
    // this.store.dispatch(
    //   filterVariables({ queryParams: {
    //     page: 1,
    //     page_size: 10,
    //     project: this.projectId.toString(),
    //     name__icontains: this.searchTerm
    //   }})
    // );
  }
  handleSearchVariables(search: NgSelectSearch) {
    const term = search && search.term;
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    if (term && term.trim().length > 2) {
      this.searchTerm = term;
      this.variablesLoading = true;
      timer = setTimeout(() => {
        this.loadVariable();
      }, 1500);
    }


  }
  addVariable(variable) {
    this.emitSelectedVariables.emit(this.selectedValues);
  }
  removeVariable(event) {
    this.emitSelectedVariables.emit(this.selectedValues);
  }
  reset() {
    this.emitSelectedVariables.emit(this.selectedValues);
  }

}
