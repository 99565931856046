import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { StatisticService } from '@core/service/statistic.service';
import * as projectActions from '@modules/project/state/project.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ActivityComment,
  PaperItem,
  UserComment,
} from '@shared/schema/project';
import { Subscription } from 'rxjs';
import * as statisticsActions from '@modules/project/state/statistics.actions';
import { showToastMessage } from '@modules/project/state/project.actions';
import { PaperComment, ScreeningReason } from '@shared/schema/statistic';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfo } from '@shared/schema/profile';
import { guides } from '@shared/data/guides';

@Component({
  selector: 'app-review-comment',
  templateUrl: './review-comment.component.html',
  styleUrls: ['./review-comment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewCommentComponent implements OnChanges, OnDestroy {
  @Input() paperItem: PaperItem;
  @Input() projectId: number;
  @Input() status: 1 | 2 = 1;
  @Output() submitted: EventEmitter<PaperItem> = new EventEmitter<PaperItem>();
  @Input() compacted;
  flag: boolean;
  commentForm: FormGroup;

  commentType: { name: string; value: number }[] = [
    {
      name: 'Yes',
      value: 1,
    },
    {
      name: 'No',
      value: 2,
    },
    {
      name: 'Maybe',
      value: 3,
    },
    {
      name: 'Contact',
      value: 6,
    },
  ];
  screeningReasons: ScreeningReason[];
  filteredScreeningReasons: ScreeningReason[];
  // tmpFilteredScreeningReasons: ScreeningReason[];
  subscriptions: Subscription[] = [];
  myComment: UserComment;
  userInfo: UserInfo;
  guide = {
    reason: guides.fullTextScreening.reason
  };
  constructor(
    private statisticService: StatisticService,
    private store: Store<AppState>,
    private builder: FormBuilder,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnChanges() {
    this.getUser();
    this.buildForm();
    this.setScreeningReason();
    this.handleScreeningReasonChange();
    this.patchValue();
    this.handleReviewChange();
  }
  ngOnDestroy() {
    for (const item of this.subscriptions) {
      item.unsubscribe();
    }
  }
  buildForm() {
    this.commentForm = this.builder.group({
      var_type: [null, Validators.required],
      comment: ['', Validators.required],
      screeningReason: ['', Validators.required],
    });

  }
  patchValue() {
    const { my_comment } = this.paperItem;
    if (!my_comment) {
      return;
    }
    let reason;
    this.myComment = my_comment.find((val) => val.status === this.status);
    if (!this.myComment) {
      return;
    }
    if (this.filteredScreeningReasons && this.myComment) {
      reason = this.filteredScreeningReasons.find(
        (val) =>
          val.reason &&
          val.reason.toLowerCase().trim() === this.myComment.comment &&
          this.myComment.comment.toLowerCase().trim()
      );
      if (!reason) {
        reason = 'others';
      }
    }
    this.commentForm.patchValue({
      var_type: this.myComment.var_type,
      comment: this.myComment.comment,
      screeningReason: this.myComment.screening_reason
        ? this.myComment.screening_reason
        : reason && reason.id
          ? reason.id
          : reason
            ? reason
            : null,
    });
  }
  get commentTypeFormValue() {
    return this.commentForm.get('var_type').value;
  }

  handleCommentSubmission(reviewType?: string) {
    const myComment = this.paperItem.my_comment.find(
      (val) => val.status === this.status
    );
    const param = this.createBodyParameter(reviewType);
    if (!param) {
      return;
    }

    this.spinnerService.show('comment-dialog-' + this.paperItem.pk);

    if (myComment) {
      this.editComment(param, myComment.pk);
    } else {
      this.submitComment(param);
    }
  }
  submitComment(param: UserComment) {
    this.statisticService.submitComment(param).then((response) => {
      this.handlePostCallActions(response);
    }).catch(() => {
      this.spinnerService.hide('comment-dialog-' + this.paperItem.pk);
    });
  }

  editComment(param: UserComment, commentId?: number) {
    this.statisticService.editComment(param, commentId).then((response) => {
      this.handlePostCallActions(response, true);
    }).catch(() => {
      this.spinnerService.hide('comment-dialog-' + this.paperItem.pk);
    });
  }
  createBodyParameter(reviewType?: string): UserComment {
    const { comment } = this.commentForm.value;
    let { var_type, screeningReason } = this.commentForm.value;
    if (!comment && screeningReason === 'others') {
      this.store.dispatch(
        showToastMessage({
          toast: {
            status: 'warning',
            message: `Please add your comment.`,
          },
        })
      );
      return;
    }
    if (
      comment &&
      var_type === 6 &&
      !/\S+@\S+\.\S+/.test(comment)
      ) {
      this.store.dispatch(
        showToastMessage({
          toast: {
            status: 'warning',
            message: `Please Add Valid Email.`,
          },
        })
      );
      return;
    }

    if (comment) {
      screeningReason = this.getReasonId(comment);
    }
    if (reviewType === '1') {
      var_type = reviewType;
    }
    const param = {
      status: this.status,
      var_type,
      comment,
      paper: this.paperItem.pk,
      screening_reason: screeningReason,
    };
    return param;
  }
  getReasonId(comment: string) {
    const reason = this.filteredScreeningReasons.find(
      (val) => val.reason.toLowerCase().trim() === comment.toLowerCase().trim()
    );
    if (reason) {
      return reason.id;
    }
    return null;
  }
  handlePostCallActions(response: PaperComment, editFlag?: boolean) {
    this.spinnerService.hide('comment-dialog-' + this.paperItem.pk);
    let newCommentList = [...this.paperItem.my_comment];
    const findIndex = this.paperItem.my_comment.findIndex(
      (val) => val.pk === response.id
    );
    const newComment = {
      ...this.paperItem.my_comment[findIndex],
      ...response,
    };
    const myNewComment: UserComment = {
      pk: response.id,
      comment: response.comment,
      screening_reason: response.screening_reason,
      status: response.status,
      var_type: response.var_type,
      paper: response.paper,
      created_at: response.created_at,
    };
    if (findIndex > -1) {
      newCommentList.splice(findIndex, 1, myNewComment);
    } else {
      newCommentList = [...newCommentList, myNewComment];
    }
    // replace added/edited comment
    this.paperItem = { ...this.paperItem, my_comment: newCommentList };
    this.flag = false;
    this.submitted.emit(this.paperItem);
    this.store.dispatch(
      showToastMessage({
        toast: {
          status: 'success',
          message: `Comment successfully ${editFlag ? 'edited' : 'added'}.`,
        },
      })
    );
    // replace user edited comment in comment list of paper
    let commentList = [...this.paperItem.comments];
    if (editFlag) {
      commentList = commentList.map((comment) => {
        if (comment.pk === response.id || comment.id === response.id) {
          return { ...comment, ...newComment };
        } else {
          return comment;
        }
      });
    } else {
      commentList = [...commentList, { ...newComment, user: this.userInfo }];
    }
    this.paperItem = {
      ...this.paperItem,
      comments: commentList,
    };
    this.submitted.emit(this.paperItem);
    this.updateDiagrams();
  }

  firstReview(type: number) {
    this.commentForm.get('var_type').patchValue(type);
    this.flag = true;
  }

  selectComment(item) {
    this.commentForm.get('comment').patchValue(item);
    this.handleCommentSubmission();
  }
  updateDiagrams() {
    const type = this.status === 1 ? 'title_screening' : 'fulltext_screening';
    this.store.dispatch(
      projectActions.loadProjectStatistics({ projectId: +this.projectId, section: type })
    );
    // this.store.dispatch(projectActions.loadTitleScreeningReviewerStatistic({ projectId: this.projectId }));
  }
  handleReviewChange() {
    this.commentForm.get('var_type').valueChanges.subscribe((value) => {
      if (value && value !== 6) {
        this.flag = true;
        const connotation = value === 1 ? 2 : 1;
        this.filteredScreeningReasons = this.screeningReasons.filter(
          (val) => val.connotation === connotation
        );
      }

    });
  }
  getScreeningReason() {
    this.store.dispatch(
      statisticsActions.getScreeningReason({ projectId: this.projectId })
    );
  }
  setScreeningReason() {
    this.subscriptions = [
      ...this.subscriptions,
      this.store
        .select((state) => state.project.screeningReasons)
        .subscribe((reasons) => {
          if (!reasons) {
            this.getScreeningReason();
          } else {
            const valueToFilter = this.status === 1 ? 1 : 2;

            this.filteredScreeningReasons = reasons
              .filter((reason) =>
                reason.screening_type === valueToFilter
              )
              .sort((a, b) => a.priority - b.priority);
            this.screeningReasons = this.filteredScreeningReasons;

            if (this.paperItem.my_comment.length > 0) {
              const connotation = this.paperItem.my_comment[0].var_type === 1 ? 2 : 1;
              this.filteredScreeningReasons = this.filteredScreeningReasons.filter(
                (val) => val.connotation === connotation
              );
            }

            this.patchValue();
          }
        }),
    ];
  }
  handleScreeningReasonChange() {
    this.commentForm
      .get('screeningReason')
      .valueChanges.subscribe((selectedReasonId) => {
        if (selectedReasonId === 'others') {
          return;
        }
        if (selectedReasonId && this.screeningReasons) {
          const reason = this.screeningReasons.find(
            (val) => val.id === selectedReasonId
          );
          if (reason && reason.reason) {
            if (
              this.paperItem &&
              this.paperItem.my_comment &&
              this.myComment &&
              reason.reason === this.myComment.comment
            ) {
              return;
            }
            this.selectComment(reason && reason.reason);
          }
        }
      });
  }
  getUser() {
    this.store.select(state => state.profile.user).subscribe(user => {
      this.userInfo = user;
    });
  }
}
