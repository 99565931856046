import { createAction, props } from '@ngrx/store';
import { ProjectList } from '@shared/schema/project-list';

export const loadProjectLists = createAction(
  '[ProjectList] Load ProjectLists',
  props<{ page: number, page_size: number, reportProgress?: boolean, deleted: boolean, name__icontains?: string }>()
);
export const loadPublicProjectLists = createAction(
  '[ProjectList] Load PublicProjectLists',
  props<{ page: number, page_size: number, reportProgress?: boolean, deleted: boolean, name__icontains?: string }>()
);

export const loadProjectListsSuccess = createAction(
  '[ProjectList] Load ProjectLists Success',
  props<{ list: ProjectList }>()
);
export const loadPublicProjectListsSuccess = createAction(
  '[ProjectList] Load PublicProjectLists Success',
  props<{ list: ProjectList }>()
);

export const loadProjectListsFailure = createAction(
  '[ProjectList] Load ProjectLists Failure',
  props<{ error: any }>()
);
