import { Injectable } from '@angular/core';
import { CorrelationVariable } from '@shared/schema/project';
import { Subject } from 'rxjs';

@Injectable()
export class ModalService {
    private openAddProjectModalSubject = new Subject();
    private openVariableModalSubject = new Subject<any>();
    private openAddMemberModalSubject = new Subject();
    private openScreeningModalSubject = new Subject<any>();
    private openAddNewFileModalSubject = new Subject<any>();
    private openAddManualPaperModalSubject = new Subject<any>();

    openAddProjectModal$ = this.openAddProjectModalSubject.asObservable();
    openAddMemberModal$ = this.openAddMemberModalSubject.asObservable();
    openVariableModal$ = this.openVariableModalSubject.asObservable();
    openScreeningModal$ = this.openScreeningModalSubject.asObservable();
    openAddNewFileModal$ = this.openAddNewFileModalSubject.asObservable();
    openAddManualPaperModal$ = this.openAddManualPaperModalSubject.asObservable();

    announceAddProjectModalOpen() {
        this.openAddProjectModalSubject.next();
    }
    announceProjectVariableModalOpen(value: string, editFlag: boolean, variable?: CorrelationVariable) {
        this.openVariableModalSubject.next({workspaceType: value, editFlag, variable});
    }
    announceAddMemberModalOpen() {
        this.openAddMemberModalSubject.next();
    }
    announceScreeningModalOpen(type: number, paperStatus: number, id: number) {
        this.openScreeningModalSubject.next({screeningType : type, paperStatus, paperId: id});
    }
    announceAddNewFileModalOpen() {
        this.openAddNewFileModalSubject.next();
    }
    announceAddManualPaperModalOpen() {
        this.openAddManualPaperModalSubject.next();
    }
}
