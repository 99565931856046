const linkRegex = new RegExp(
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
);
const yearRegex = new RegExp(/^19[5-9][0-9]$|^20[0-1][0-9]$|^202[0-4]$/);
const between1and0Regex = /^(0(\.\d+)?|1(\.0+)?)$/;
const positiveNomRegex = /^\d+([.]\d+)?$/;
const positiveNonZeroNomRegex =   /^[1-9][0-9]*$/;
const positiveFloatNonZeroRegex = /^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/;
export {
  linkRegex,
  yearRegex,
  between1and0Regex,
  positiveNomRegex,
  positiveNonZeroNomRegex,
  positiveFloatNonZeroRegex
};
