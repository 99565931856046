import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentication-layout',
  templateUrl: './authentication-layout.component.html',
  styleUrls: ['./authentication-layout.component.scss']
})
export class AuthenticationLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
