import { createAction, props } from '@ngrx/store';
import { FilterSet } from '@shared/schema/project';
import { ProjectTasks, Task } from '@shared/schema/project.statistic';
import { KappaDiscrepancy, ScreeningReason } from '@shared/schema/statistic';
export const getScreeningReason = createAction(
    '[Statistics] Get Screening Reason',
    props<{projectId?: number}>()
);
export const getScreeningReasonSuccess = createAction(
    '[Statistics] Get Screening Reason Success',
    props<{reasons: ScreeningReason[]}>()
);
export const loadProjectTasks = createAction(
  '[Project] Load Tasks',
  props<{id: number, params: FilterSet}>()
);
export const loadProjectTasksSuccess = createAction(
  '[Project] Load Tasks Success',
  props<{tasks: ProjectTasks}>()
);
