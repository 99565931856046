import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-user-modrators',
  templateUrl: './filter-user-modrators.component.html',
  styleUrls: ['./filter-user-modrators.component.scss']
})
export class FilterUserModratorsComponent implements OnInit {
  @Input() projectId: number;
  @Input() form: FormGroup;
  constructor() { }

  ngOnInit() {
  }

}
