import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as profileActions from '@modules/profile/state/profile.actions';
import {ProfileService} from '@core/service/profile.service';
import {UserInfo} from '@shared/schema/profile';



@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService
  ) {}

  loadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(profileActions.loadProfile),
    mergeMap(() => this.profileService.getProfile()
      .pipe(
        map((profile: UserInfo) => (profileActions.loadProfileSuccess({user: profile})),
        catchError(() => EMPTY)
      ))
    )
  ));
}
