export const fullTextScreeningStatus = {
  all: [5, 6, 7, 8],
  accepted: [7, 8],
  rejected: [5],
  decided: [5, 7, 8],
  notDecided: [6]
};
export const titleScreeningStatus = {
  all: [0, 3, 4, 5, 6, 7],
  accepted: [5, 6, 7, 8],
  rejected: [3],
  decided: [3, 5, 6, 7, 8],
  notDecided: [4]
};
export const paperDecisionOptions = [
  {
    id: 'all',
    label: 'All',
    titleScreeningStatus: null,
    fullTextScreeningStatus: null
  },
  {
    id: 'decided',
    label: 'Decided',
    titleScreeningStatus: 1,
    fullTextScreeningStatus: 1
  },
  {
    id: 'notDecided',
    label: 'Haven\'t decided yet',
    titleScreeningStatus: 2,
    fullTextScreeningStatus: 2
  },
  {
    id: 'accepted',
    label: 'Accepted',
    titleScreeningStatus: 3,
    fullTextScreeningStatus: 3
  },
  {
    id: 'rejected',
    label: 'Rejected',
    titleScreeningStatus: 4,
    fullTextScreeningStatus: 4
  },
];
export const getStatusList = (prop: string, isFullText: boolean) => {
  if (isFullText) {
    return fullTextScreeningStatus[prop || 'all'];
  } else {
    return titleScreeningStatus[prop || 'all'];
  }
};
export const getDecisionFromStatusList = (statusList: number[], isFullText: boolean) => {
  if (!statusList) {
    return 'all';
  }
  const sortedStatus = [...statusList].sort().toString();
  const refScreening = isFullText ? fullTextScreeningStatus : titleScreeningStatus;
  for (const prop in refScreening) {
    if (refScreening[prop] === sortedStatus) {
      return [prop];
    }
  }
};
