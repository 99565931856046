import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@core/service/project.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { HttpEventType } from '@angular/common/http';
import { Tag } from '@shared/schema/project';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import * as projectActions from '@modules/project/state/project.actions';
import { Subscription } from 'rxjs';
import { SOURCES } from '@shared/data/sources';
import * as moment from 'moment';
import { guides } from '@shared/data/guides';

@Component({
  selector: 'app-import-new-file',
  templateUrl: './import-new-file.component.html',
  styleUrls: ['./import-new-file.component.scss']
})
export class ImportNewFileComponent implements OnInit, OnDestroy {
  validFormats: string[] = ['.ris', '.bib'];
  progress: number;
  sources: any[] = SOURCES;
  importArticle: FormGroup;
  files: NgxFileDropEntry[];
  tags: Tag[] = [];
  tagsLoading = false;
  selectedTagList = [];
  tagsId: number[];
  subscription: Subscription[];
  submitted = false;
  tagTermToSearch = '';
  tagSubmited = false;
  endnoteFiles: NgxFileDropEntry[];
  isDropboxConnected = false;
  countInPrismaOptions = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},
  ];
  guide = {
    import: guides.importArticle.import,
    importFile: guides.importArticle.importFile,
  };
  constructor(
    public dialogRef: MatDialogRef<ImportNewFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: number },
    private builder: FormBuilder,
    private projectService: ProjectService,
    private toastrService: NbToastrService,
    private store: Store<AppState>,

  ) { }

  ngOnInit() {
    this.buildForm();
    this.subscription = [
      this.store.select(state => state.project.projectDetails).subscribe(
        detail => {
          if (detail && detail.owner && detail.owner.is_dropbox_connected) {
            this.isDropboxConnected = detail.owner.is_dropbox_connected;
          }
        }
      ),
    this.store.select(store => (store.project.tagList)).subscribe(
      tagList => {
        if (this.tagTermToSearch) {
          if (tagList) {
            this.tags = tagList.data;
            this.tagsLoading = false;
          }
        }

      }
    ),
    this.store.select(store => (store.project.tagsId)).subscribe(
      tagsId => {
        if (tagsId) {
          if (this.tagSubmited) {
            this.tagsId = tagsId;
            this.import();
            this.tagSubmited = false;
          }

        }
      }
    ),

    ];
  }
  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }
  buildForm() {
    this.importArticle = this.builder.group({
      source               : ['', Validators.required],
      keyword              : ['', Validators.required],
      file                 : [null, Validators.required],
      endnoteFile          : [null],
      pdf_dropbox_root_dir : [null, []],
      date                 : [null, Validators.required],
      tag                  : [''],
      dataEntryDirectImport: [false],
      crossRefImport       : [true],
      deduplication        : [{value: true, disabled: true}],
      countInPrisma        : [true]
    });
    this.importArticle.get('dataEntryDirectImport').valueChanges.subscribe(val => {
      if (val) {
        this.importArticle.controls.deduplication.enable();
      } else {
        this.importArticle.controls.deduplication.disable();
      }
    });
  }
  get file() {
    return this.importArticle.get('file') as FormControl;
  }
  fileDropped(files: NgxFileDropEntry[]) {
    this.files = files;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      if (this.validateFormat(file.name)) {
        this.importArticle.patchValue({ file });
      } else {
        this.showToast('Only this extension can be uploaded(.ris,.bib)');
      }
    });
  }
  validateFormat(name): boolean {
    let valid = false;
    this.validFormats.forEach((format) => {
      if (name.indexOf(format) >= 0) {
        valid = true;
      }
    });
    return valid;
  }
  import() {
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    const {
      source,
      keyword,
      file,
      date,
      deduplication,
      dataEntryDirectImport,
      crossRefImport,
      endnoteFile,
      pdf_dropbox_root_dir,
      countInPrisma
    } = this.importArticle.value;
    const formData = new FormData();
    formData.append('source', source);
    formData.append('keyword', keyword);
    formData.append('count_in_prisma', countInPrisma);
    if (pdf_dropbox_root_dir) {
      formData.append('pdf_dropbox_root_dir', pdf_dropbox_root_dir);
    }
    if (dataEntryDirectImport) {
      formData.append('deduplication_need', deduplication);
    }
    formData.append('crossref_import', crossRefImport);
    formData.append('data_entry_directly_imported', dataEntryDirectImport);
    formData.append('date_of_search', moment(date).format('YYYY-MM-DD'));
    formData.append('project', this.data.projectId.toString());
    if (this.tagsId) {
      for (const tag of this.tagsId) {
        formData.append('tags', tag.toString());
      }
    }

    formData.append('file', file, file.name);
    if (endnoteFile) {
      formData.append('pdf_files', endnoteFile, endnoteFile.name);
    }
    this.projectService.importArticle(formData).subscribe(
      (res) => {
        if (res && res.type === HttpEventType.UploadProgress) {
          setTimeout(() => this.progress = Math.round((res.loaded / res.total) * 100), 2000);
        } else if (res.status === 201) {
          this.submitted = false;
          this.dialogRef.close(true);
        }
      },
      (error) => {
        this.submitted = false;
      },

    );
  }
  showToast(text) {
    this.toastrService.show(
      text,
      'Invalid Extensions',
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'danger',
        duration: 3000
      });
  }
  cancel() {
    this.dialogRef.close();
  }

  handleSearchTags(searchTerm) {
    let timer;
    if (searchTerm.term) {
      if (searchTerm.term.length > 2) {
        this.tagsLoading = true;
        if (timer) {
          clearTimeout(timer);
        }
        this.tagTermToSearch = searchTerm.term;
        timer = setTimeout(() => {
          if (searchTerm.term) {
            this.searchTags(searchTerm.term);
          }
        }, 1500);
      }
    } else {
      this.tagTermToSearch = '';
    }

  }
  searchTags(searchTerm: string) {
    if (this.tagTermToSearch) {
      this.store.dispatch(
        projectActions.getTags({
          queryParams: {
            name__icontains: searchTerm,
            page_size: 20,
            page: 1,
            project: this.data.projectId.toString(),
          },
          reportProgress: true
        })
      );
    }

  }
  addTag(value) {
    if (value) {
      if (value.name) {
        if (value.name.length < 3) {
          return;
        }
        if (!this.selectedTagList.includes(value.name)) {
          this.selectedTagList.push(value.name);
        }
      }
    }
  }
  removeTag(event) {
    const value = event.value;
    this.selectedTagList = this.selectedTagList.filter(val => val !== value.name);
  }

  submitTags() {
    this.tagSubmited = true;
    this.store.dispatch(
      projectActions.createTags({
        projectId: this.data.projectId,
        tags: this.selectedTagList,
      })
    );
  }
  handleImportArticle() {
    if (this.selectedTagList.length > 0) {
      this.submitTags();
    } else {
      this.import();
    }
  }
  get endnoteFile() {
    return this.importArticle.get('endnoteFile') as FormControl;
  }
  endnoteFileDropped(files: NgxFileDropEntry[]) {
    this.endnoteFiles = files;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      if (file.name.indexOf('zip') >= 0) {
        this.importArticle.patchValue({ endnoteFile: file });
      } else {
        this.showToast('Files with (.zip) extension can be uploaded');
      }
    });
  }

}
