import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-screening-category',
  templateUrl: './filter-screening-category.component.html',
  styleUrls: ['./filter-screening-category.component.scss']
})
export class FilterScreeningCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
