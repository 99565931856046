import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@core/service/modal.service';
import * as projectActions from '@modules/project/state/project.actions';
import { AppState } from '@shared/schema/app.state';
import { Store } from '@ngrx/store';
import { ProjectActivityItem, BatchFile } from '@shared/schema/project';
import { PaperComment } from '@shared/schema/statistic';
import { Subscription } from 'rxjs';
import * as projectAction from '@modules/project/state/project.actions';
import { MatMenuTrigger, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportNewFileComponent } from '@modules/project/components/import-articles-section/import-new-file/import-new-file.component';
import { ManualAddPaperComponent } from '@modules/project/components/import-articles-section/manual-add-paper/manual-add-paper.component';
import {CiteUsComponent} from '@shared/components/cite-us/cite-us.component';
const PAGE_SIZE = 3;
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Input() projectRoot: boolean;
  @Output() openAddProjectModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openAddVariableModal: EventEmitter<any> = new EventEmitter<any>();
  activities: ProjectActivityItem[];
  comments: PaperComment[] = [];
  batchFiles: BatchFile[] = [];
  subscriptions: Subscription[] = [];
  members = [];
  projectAccess = null;
  projectId: number;
  lastHistories = [];
  notificationNumber = 0;
  // @ViewChild('clickMenuTrigger', null) clickMenuTrigger: MatMenuTrigger;
  constructor(
    private modalService: ModalService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.projectId = +this.route.snapshot.params.projectId;
    this.projectAccess = this.route.snapshot.data.member ?
    this.route.snapshot.data.member.project_access : null;

    if (this.projectRoot) {
      this.store.dispatch(
        projectAction.loadProjectNotification({
          query: { project: this.projectId, page: 1, page_size: PAGE_SIZE },
          reportProgress: true,
        })
      );

      this.subscriptions = [
        this.store
          .select((state) => state.project.notifications)
          .subscribe((notification) => {
            // Reviwer just Can see His Notification
            if (notification &&
              this.projectAccess.fulltext_screening === 2 &&
              this.projectAccess.title_screening === 2
            ) {
              if (notification.length > 0) {
                const currentUserId = this.route.snapshot.data.member.user.user_id;
                this.lastHistories = [];
                this.notificationNumber = 0;

                for (const item of notification) {
                  if (item.data) {
                    this.lastHistories = [
                      ...this.lastHistories,
                      ...item.data,
                    ].sort((a, b) => +b.history_date - +a.history_date)
                     .filter(el => el.history_user === currentUserId);
                  }
                  this.notificationNumber = this.lastHistories.length;
                }
              }
            } else {
              if (notification && notification.length > 0) {
                this.lastHistories = [];
                this.notificationNumber = 0;
                for (const item of notification) {
                  if (item.data) {
                    this.lastHistories = [
                      ...this.lastHistories,
                      ...item.data,
                    ].sort((a, b) => +b.history_date - +a.history_date);
                  }
                }
                this.notificationNumber = this.lastHistories.length;
              }
            }
          }),
        this.store
          .select((state) => state.project.projectDetails)
          .subscribe((state) => {
            if (state) {
              this.members = state.members;
            }
          }),
      ];
    }
  }
  ngOnDestroy() {
    for (const item of this.subscriptions) {
      item.unsubscribe();
    }
  }
  handleAddProject() {
    this.modalService.announceAddProjectModalOpen();
  }
  handleOpenAddVariableModal(value: string) {
    this.modalService.announceProjectVariableModalOpen(value, false);
  }
  handleOpenAddMemberModal() {
    this.modalService.announceAddMemberModalOpen();
  }
  getMemberInfo(id: number) {
    if (this.members) {
      const found = this.members.find(
        (member) => member.profile && member.profile.user_id === id
      );
      if (found) {
        return found.profile;
      }
    }
  }
  handleOpenAddNewFileModal() {
    this.modalService.announceAddNewFileModalOpen();
  }
  handleOpenAddPaperModal() {
    this.modalService.announceAddManualPaperModalOpen();
  }
  resetNotification() {
    this.notificationNumber = 0;
  }
  routeToActivityPage() {
    this.router.navigate([`/project/${this.projectId}/activity/logs`]);
  }

  citeUs() {
    const dialogRef = this.dialog.open(CiteUsComponent, {
      width: '80%',
      data: {}
    });
  }

}
