import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ChangePassword, MyProfile, UserInfo} from '@shared/schema/profile';
import { AppRuntimeConfig, CONFIG } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    @Inject(CONFIG) private appRuntimeConfig: AppRuntimeConfig,
    private http: HttpClient
  ) { }
  // Get Profile Info
  getProfile(): Observable<UserInfo> {
    return this.http.get(`${this.appRuntimeConfig.api_url}/auth/authentication/me/`)
      .pipe(
        map((result: UserInfo) => {
          return result;
        })
      );
  }
  // Patch Profile
  patchProfile(info: MyProfile): Observable<UserInfo> {
    return this.http.patch(`${this.appRuntimeConfig.api_url}/auth/authentication/me/`, info)
      .pipe(
        map((result: UserInfo) => {
          return result;
        })
      );
  }
  // Patch Profile
  uploadAvatar(info: any): Observable<UserInfo> {
    return this.http.patch(`${this.appRuntimeConfig.api_url}/auth/authentication/me/`, info)
      .pipe(
        map((result: UserInfo) => {
          return result;
        })
      );
  }
  // Change Password
  async changePassword(newPass: ChangePassword): Promise<any> {
    return (await this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/change_password/`, newPass).toPromise()) as any;
  }
  connectToDropbox(params) {
    return this.http.post(`${this.appRuntimeConfig.api_url}/auth/oauth/dropbox/`, params).pipe(
      map(res => res)
    );
  }
  disconnectFromDropbox() {
    return this.http.delete(`${this.appRuntimeConfig.api_url}/auth/oauth/dropbox/`).pipe(
      map(res => res)
    );
  }

}
