import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@core/service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.hubmetaToken) {
      const inviteToken = next.queryParams.invite_token;
      this.authService.hubmetaToken = null;
      this.authService.hubmetaExpire = null;
      this.router.navigate(['/auth'], {
        queryParams: {
          invite_token: inviteToken
        }
      }).then();
      return false;
    }
    return true;
  }
}
