import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SearchPapersAndVariablesResult } from '@shared/schema/project';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import * as projectActions from '@modules/project/state/project.actions';
import * as projectListAction from '@modules/dashboard/state/project-list.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@core/service/modal.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ProjectService } from '@core/service/project.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  result: SearchPapersAndVariablesResult[];
  searchLoading = false;
  projectId: number;
  emptySearch = false;
  projectListsearchTerm$ = new Subject<string>();
  @Input() projectRoot: boolean;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private projectService: ProjectService,
    private router: Router) {
  }

  ngOnInit() {
    if (this.projectRoot) {
      this.projectId = +this.route.snapshot.paramMap.get('projectId');
      this.subscription = [
        this.store.select(store => store.project.searchPapersAndVariablesResult)
          .subscribe(state => {
            if (state) {
              this.result = state;
              if (this.emptySearch) {
                this.result = null;
              }
              this.searchLoading = false;

            }
          })
      ];
    } else {
      this.projectListsearchTerm$
      .pipe(
        distinctUntilChanged(),
        debounceTime(500)
      )
      .subscribe(term => {
        this.handleProjectList(term);
      });

    }
  }
  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }

  resetResult() {
    this.result = null;
  }

  handleSearchPapersAndVariables(event) {
    if (event.term.trim() === '') {
      this.emptySearch = true;
      this.result = null;
    } else {
      this.emptySearch = false;
    }
    let timer;
    if (event.term.length > 2) {
      this.searchLoading = true;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        if (event.term) {
          this.searchPapersAndVariable(event.term);
        }
      }, 2000);
    }
  }
  searchPapersAndVariable(searchTerm: string) {
    this.store.dispatch(projectActions.searchPapersAndVariables({ searchQuery: searchTerm, projectId: this.projectId }));
  }
  customFilter(term: string, item: any) {
    return item;
  }
  handleResultSelected(event: SearchPapersAndVariablesResult) {
    if (event) {
      if (event.type === 1) {
        this.store.dispatch(projectActions.getProjectVariableDetail({ variableId: event.id }));
        this.announceEditProjectVariableModalOpen(event);
      } else if (event.type === 2) {
        this.store.dispatch(projectActions.getModeratorDetail({ moderatorId: event.id }));
        this.announceEditProjectVariableModalOpen(event);
      } else if (event.type === 3) {
        if (event.status === 7) {
          this.router.navigate([`project/${this.projectId}/data-entry/paper-data-entry/${event.id}`]);
        } else if (event.status === 4) {
          this.modalService.announceScreeningModalOpen(1, event.status, event.id);
        } else if (event.status === 6) {
          this.modalService.announceScreeningModalOpen(2, event.status, event.id);
        }
      }
    }

  }
  handleProjectList(searchTerm: string) {
      const param = {
        page: 1,
        deleted: false,
        reportProgress: false,
        name__icontains: searchTerm,
      };
      this.projectService.setProjectListParam(param);
      this.projectService.setProjectlistAppend(false);
      this.store.dispatch(projectListAction.loadProjectLists(this.projectService.getProjectListParam()));
    }
    resetProjectList() {
      this.handleProjectList(null);
    }
  announceEditProjectVariableModalOpen(event) {
    this.modalService.announceProjectVariableModalOpen(this.setVariableType(event.type, event.status), true);
  }
  setVariableType(type: number, status: number) {
    if (type === 3) {
      return 'Paper';
    } else if (type === 2) {
      return 'Moderator Variable';
    } else if (type === 1) {
      if (status === 1) {
        return 'Measurement';
      } else if (status === 2) {
        return 'Construct';
      } else if (status === 3) {
        return 'Group Construct';
      }
    }
  }
}
