import { Component, OnInit, OnDestroy } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@shared/schema/app.state';
import {Observable, Subscription} from 'rxjs';
import {ProfileState} from '@shared/schema/profile-state';
import {UserInfo} from '@shared/schema/profile';
import {AuthenticationService} from '@core/service/authentication.service';
import {Router} from '@angular/router';
import * as mainActions from '@shared/reducers/main.actions';
import {environment} from '@env';
@Component({
  selector: 'app-profile-action',
  templateUrl: './profile-action.component.html',
  styleUrls: ['./profile-action.component.scss']
})
export class ProfileActionComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  profile$: Observable<ProfileState> = this.store.select(state => state.profile);
  user: UserInfo;
  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.subscription = [
      this.profile$.subscribe((profile) => this.user = profile.user)
    ];
  }
  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }
  signOut() {
    this.store.dispatch(mainActions.signOuts());
    this.authService.hubmetaToken = null;
    this.authService.hubmetaExpire = null;
    this.authService.hubmetaRefreshToken = null;
    this.router.navigate(['/auth']).then();
  }

}
