import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { guides } from '@shared/data/guides';

@Component({
  selector: 'app-rcode-modal',
  templateUrl: './rcode-modal.component.html',
  styleUrls: ['./rcode-modal.component.scss'],
})
export class RcodeModalComponent implements OnInit {
  guide = {
    info: guides.analysis.RCode,
    action: guides.analysis.RCodeAction,
  };
  constructor(
    public dialogRef: MatDialogRef<RcodeModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
    }
  ) {}

  ngOnInit() {}
  close() {
    this.dialogRef.close();
  }
  submit() {
    const textarea = document.createElement('textarea');
    textarea.textContent = this.data.message;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
    } catch (error) {
      console.log(error);
    }
    document.body.removeChild(textarea);
    setTimeout(() => {}, 3000);
  }
}
