import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProjectService } from '@core/service/project.service';
import { ProjectDetails } from '@shared/schema/project';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProjectMemberResolver implements Resolve<ProjectDetails> {
    constructor(private service: ProjectService) {}
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,

    ): Observable<any> {
        return this.service.getUser(+route.params.projectId);
    }
}
