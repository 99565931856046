import { getLocaleDateFormat } from '@angular/common';
import { Injectable } from '@angular/core';
import { PaperItem } from '@shared/schema/project';
import { Subject } from 'rxjs';

@Injectable()
export class PaperService {
    private goForwardOfPapersReviewSubject = new Subject<{prevFlag: boolean, paper: PaperItem}>();
    private setSelectedPaperSubject = new Subject<PaperItem>();
    goForwardOfPapersReview$ = this.goForwardOfPapersReviewSubject.asObservable();
    setSelectedPaper$ = this.setSelectedPaperSubject.asObservable();
    announceGoForwardOfPapersReview(prevFlag: boolean, paper: PaperItem) {
        this.goForwardOfPapersReviewSubject.next({prevFlag, paper});
    }
    announceSetSelectedPaper(paper: PaperItem) {
        this.setSelectedPaperSubject.next(paper);
    }
}
