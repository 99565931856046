import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReadMorePipe } from './pipe/read-more.pipe';
import { AuthenticationService } from '@core/service/authentication.service';
import { BrowserStorageService } from '@core/service/browser-storage.service';
import { HttpClientModule } from '@angular/common/http';
import { ProfileService } from '@core/service/profile.service';
import { ProjectService } from '@core/service/project.service';
import { FromNowPipe } from './pipe/from-now.pipe';
import { StatisticService } from '@core/service/statistic.service';
import { ModalService } from '@core/service/modal.service';
import { LoadVariableService } from '@core/service/load-variables.service';
import { LoadingService } from './service/loading.service';
import { HighlightPipe } from './pipe/highlight.pipe';
import { UserIdle } from './service/user-idle.service';
import { DatePipe } from './pipe/date.pipe';
import { FilterService } from '@core/service/filter.service';
import { PaperService } from '@core/service/paper.service';
import { LongitudinalPipe } from './pipe/longitudinal.pipe';
import { Doi2libkeyPipe } from './pipe/doi2libkey.pipe';
import { PaperStatusPipe } from './pipe/paper-status.pipe';
import { CONFIG, ConfigService, getAppExternalConfig } from './service/config.service';
@NgModule({
  imports: [
    HttpClientModule,
  ],
  declarations: [
    ReadMorePipe,
    FromNowPipe,
    HighlightPipe,
    DatePipe,
    LongitudinalPipe,
    Doi2libkeyPipe,
    PaperStatusPipe
  ],
  providers: [
    LongitudinalPipe
  ],
  exports: [
    ReadMorePipe,
    FromNowPipe,
    HttpClientModule,
    HighlightPipe,
    DatePipe,
    LongitudinalPipe,
    Doi2libkeyPipe,
    PaperStatusPipe
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CONFIG,
          useFactory: getAppExternalConfig,
          deps: [ConfigService]
       },
        AuthenticationService,
        BrowserStorageService,
        ProfileService,
        ProjectService,
        StatisticService,
        UserIdle,
        ModalService,
        LoadingService,
        LoadVariableService,
        FilterService,
        PaperService
      ]
    };
  }
}
