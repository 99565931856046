import { Injectable, InjectionToken } from '@angular/core';

export interface AppRuntimeConfig {
  api_url: string;
  host_name: string;
  libmeta_url: string;
}

export const CONFIG = new InjectionToken<AppRuntimeConfig>('CONFIG');

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() {
  }

  loadConfigJSON() {
    return JSON.parse(this.loadTextFileAjaxSync('./assets/env/config.json', 'application/json'));
  }

  loadTextFileAjaxSync(filePath, mimeType) {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', filePath, false);

    xmlhttp.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0');
    xmlhttp.setRequestHeader('Expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
    xmlhttp.setRequestHeader('Pragma', 'no-cache');

    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    if (xmlhttp.status === 200) {
      return xmlhttp.responseText;
    } else {
      return null;
    }
  }
}

export function ConfigFactory(configService: ConfigService, property: string) {
  const value = configService.loadConfigJSON();
  return value;
}

export function getAppExternalConfig(configService: ConfigService): AppRuntimeConfig {
  return ConfigFactory(configService, '');
}
