import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'readMore'
})
export class ReadMorePipe implements PipeTransform {

  transform(value: string, limit: number): any {

    return value.substring(0, limit) + (value.length > limit ? ` ...` : '');
  }

}
