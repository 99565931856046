import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-guide-popover',
  templateUrl: './guide-popover.component.html',
  styleUrls: ['./guide-popover.component.scss']
})
export class GuidePopoverComponent implements OnInit {
  @Input() information: string;
  @Input() component: TemplateRef<any>;
  @Input() popOverRef: TemplateRef<any>;
  constructor() { }

  ngOnInit() {
  }

}
