import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import {
  NbSidebarService,
  NbToastrService,
  NbGlobalPhysicalPosition,
} from '@nebular/theme';
import {
  PaperFilter,
  FilterSet,
  Moderator,
  ProjectRanges,
  ProjectVariable,
  ProjectMember,
  Tag
} from '@shared/schema/project';
import { AppState } from '@shared/schema/app.state';
import { Store } from '@ngrx/store';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProjectService } from '@core/service/project.service';
import { StatisticService } from '@core/service/statistic.service';
import { ActivatedRoute } from '@angular/router';
import {
  setFilterPapers,
  loadSavedPaperFilters,
  changeAllPaperStatus,
  showActionSuccessMessage,
  loadMemberList,
  loadProjectVariables,
  loadModerators,
} from '@modules/project/state/project.actions';
import { Subscription } from 'rxjs';
import { SOURCES } from '@shared/data/sources';
import { LANGUAGES } from '@shared/data/const-data';
import { FilterService } from '@core/service/filter.service';
import { NgSelectSearch } from '@modules/project/components/add-new-workspace/add-new-workspace.component';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { FilterPapers, FilterPaperType } from '@shared/schema/filter-project';
import { ScreeningReason } from '@shared/schema/statistic';
import { getScreeningReason } from '@modules/project/state/statistics.actions';
import { UserInfo } from '@shared/schema/profile';
import { MatDialog } from '@angular/material';
import { getDecisionFromStatusList, getStatusList, paperDecisionOptions } from '@shared/utils/papers-status';
import { guides } from '@shared/data/guides';
import * as projectActions from '@modules/project/state/project.actions';
@Component({
  selector: 'app-papers-filters',
  templateUrl: './paper-filters.component.html',
  styleUrls: ['./paper-filters.component.scss'],
})
export class DataEntryFiltersComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  projectId: number;
  ranges: ProjectRanges;
  visuals: ProjectVariable[];
  moderators: Moderator[];
  filterForm: FormGroup;
  // variablesLoading = false;
  moderatorFormArray: FormArray;
  sources: any[] = SOURCES;
  filterList: PaperFilter[] = [];
  /***************
   * TAGS
   **************/
  tags: Tag[] = [];
  tagsLoading = false;
  selectedTagList = [];
  tagsId: number[];
  tagTermToSearch = '';
  tagSubmited = false;
  reviewFilterOptions = [
    { id: '1', label: 'Reviewed' },
    { id: '2', label: 'Haven\'t reviewed yet' },
    { id: '3', label: 'All' },
  ];
  commentType: { name: string; value: number }[] = [
    {
      name: 'Yes',
      value: 1,
    },
    {
      name: 'No',
      value: 2,
    },
    {
      name: 'Maybe',
      value: 3,
    },
  ];
  userCommentTypeOptions: { name: string; value: number }[] = [
    {
      name: 'Yes',
      value: 1,
    },
    {
      name: 'No',
      value: 2,
    },
    {
      name: 'Maybe',
      value: 3,
    },
  ];
  languages: { name: string; value: string }[] = [];
  decisionFilter = paperDecisionOptions;
  filteredDecisionFilter;
  discrepancyFilterOptions = [
    { id: '0', label: 'All' },
    { id: '2', label: 'Reviewer Discrepancy' },
    { id: '1', label: 'Reviewer same opinion' },
  ];

  adminLevel = false;
  screeningFiltered = false;
  filterType = FilterPaperType.NONE;
  subject = {
    term: '',
    loading: false,
    filtered: [],
    selected: []
  };
  filterPapers: FilterPapers;
  targetFilter: FilterSet;
  selectedSavedFilter = -1;
  filterSection: string;

  newFilterName: FormControl = new FormControl();
  screeningCategories: ScreeningReason[];
  showCommentControl = false;
  pdfIsNullOptions = [
    { label: 'None', value: null },
    { label: 'Pdf Imported', value: false },
    { label: 'Pdf Not Imported', value: true },
  ];
  reviewers: ProjectMember[];
  reviewerCommentsFormArray: FormArray;
  FilterPaperType = FilterPaperType;
  guide = {
    overall: guides.titleScreening.filter
  };
  constructor(
    private sidebarService: NbSidebarService,
    private store: Store<AppState>,
    private builder: FormBuilder,
    private service: ProjectService,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private filterService: FilterService,
    private statisticService: StatisticService,
    public dialog: MatDialog,
  ) { }
  ngOnInit() {
    this.projectId = +this.route.snapshot.params.projectId;
    this.targetFilter = {
      project: this.projectId
    };
    this.subscription = [
      ...this.subscription,
      this.store
        .select((state) => state.project.filterPapers)
        .subscribe((state) => {
          if (state) {
            this.filterPapers = state;
            console.log(state);

          }
        })
    ];
    this.handleChangesOfFilterType();

    this.loadSavedFilters();

    this.getSubjects();

    this.getLanguages();

    this.buildForm();


    // this.handleSelectFilter();
    // this.variablesLoading = true;
  }

  addSubscriptions() {
    this.subscription = [
      ...this.subscription,
      this.subscribeProjectRanges(),
      this.subscribeSavedFilters(),
      this.subscribeModerators(),
      this.subscribeScreeningCategory(),
      this.subscribeMembers(),
      this.subscribeTag(),
    ];
  }
  subscribeTag() {
    return this.store.select(store => (store.project.tagList)).subscribe(
      tagList => {

        if (this.tagTermToSearch) {
          if (tagList) {
            this.tags = tagList.data;
            this.tagsLoading = false;
          }
        }

      }
    );
  }

  setPermissionLevel() {
    // const projectAccess = this.route.snapshot.data.member.project_access;
    let projectAccess = null;
    if (this.route.snapshot.data.member) {
      // console.log('mrnu member data run');

      projectAccess = this.route.snapshot.data.member.project_access;
    } else {
      // Set default values for this.projectAccess
      // todo set default project access values in a true file and use it in all of the project

      projectAccess = {
        details: true,
        activity: false,
        analytics: false,
        arm: false,
        import_article: false,
        files: true,
        papers: true,
        deduplication: false,
        title_screening: 2,
        fulltext_screening: 2,
        data_entry: true,
        analysis: true,
        general: false,
        members: false,
        workspace: false,
        screening_category: false,
        member: false,
        taxonomy_citation: true,
        taxonomy_ai_variable: false,
        taxonomy_construct: true,
        taxonomy_files: true,
        taxonomy_group_construct: true,
        taxonomy_measurement: true,
        taxonomy_moderators: true,
        taxonomy_tags: true,
        taxonomy_variable_set: true,
        taxonomy_visualization: true,

      };
    }
    if (this.filterType === FilterPaperType.FULL_TEXT_SCREENING) {
      if (projectAccess.fulltext_screening === 2) {
        this.adminLevel = false;
      } else {
        this.adminLevel = true;
      }
    } else if (this.filterType === FilterPaperType.TITLE_SCREENING) {
      if (projectAccess.title_screening === 2) {
        this.adminLevel = false;
      } else {
        this.adminLevel = true;
      }
    }
    if (this.adminLevel) {
      this.filteredDecisionFilter = this.decisionFilter;
    } else {
      this.filteredDecisionFilter = this.decisionFilter.filter(val => val.id !== 'accepted' && val.id !== 'rejected');
    }
  }

  subscribeProjectRanges() {
    return this.store
      .select((state) => state.project.projectRanges)
      .subscribe((state) => {
        this.ranges = state;
      });
  }

  subscribeSavedFilters() {
    return this.store
      .select((state) => state.project.savedPaperFilter)
      .subscribe((filter) => {
        this.filterList = filter;
      });
  }

  subscribeModerators() {

    if (this.filterType !== FilterPaperType.DATA_ENTRY) {
      return;
    }
    return this.store
      .select((state) => state.project.moderators)
      .subscribe((state) => {
        if (state) {
          if (state && state.length > 0) {
            const moderators = [...state];
            this.moderators = moderators
              .sort((a, b) => b.id - a.id)
              .sort((a, b) => b.var_type - a.var_type);
            this.addModeratorFormArray();
          }
        } else {
          this.store.dispatch(loadModerators({ projectId: this.projectId }));
        }

      });
  }

  subscribeScreeningCategory() {
    return this.store.select(state => state.project.screeningReasons).subscribe(
      reasons => {
        if (!reasons) {
          this.store.dispatch(getScreeningReason({ projectId: this.projectId }));
        } else {
          if (this.filterType === FilterPaperType.TITLE_SCREENING) {
            this.screeningCategories = reasons.filter(
              (reason) => reason.screening_type === 1
            ).sort((a, b) => b.priority - a.priority);
          } else {
            this.screeningCategories = reasons.filter(
              (reason) => reason.screening_type === 2
            ).sort((a, b) => b.priority - a.priority);
          }
        }
      }

    );
  }

  subscribeMembers() {
    return this.store.select(state => state.project.memberList).subscribe((allMembers) => {
      if (allMembers && allMembers.data) {
        this.reviewers = allMembers.data.filter(item => item.role === null);
        this.createReviewersCommentFormArray();
        (this.filterForm.get('numberOfAllComments') as FormArray).controls.forEach(control => {
          control.get('numberOfComments').patchValue([0, this.reviewers.length]);
        });
      } else {
        this.store.dispatch(loadMemberList({ projectId: this.projectId, page: 1, page_size: 20 }));
      }
    });
  }

  handleChangesOfFilterType() {
    this.filterService.setFilterType$.subscribe((val: FilterPaperType) => {
      this.filterType = val;
      switch (this.filterType) {
        case FilterPaperType.DATA_ENTRY:
          this.screeningFiltered = false;
          this.targetFilter = this.filterPapers.dataEntry;
          this.filterSection = 'data_entry';
          break;
        case FilterPaperType.FULL_TEXT_SCREENING:
          this.screeningFiltered = true;
          this.targetFilter = this.filterPapers.fullTextScreening;
          this.filterSection = 'fulltext_screening';
          break;
        case FilterPaperType.TITLE_SCREENING:
          this.screeningFiltered = true;
          this.targetFilter = this.filterPapers.titleScreening;
          this.filterSection = 'title_screening';
          break;
        default:
          // Handle what happen if not in [Data entry, Title screaning, Full Text screening ]
          break;
      }
      this.setPermissionLevel();
      this.patchFormValues(this.targetFilter);
      this.handleFilter();

      this.addSubscriptions();
    });
  }

  ngOnDestroy() {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  buildForm() {
    this.filterForm = this.builder.group({
      title: [''],
      authors: [''],
      journal: [''],
      abstract: [''],
      source: [''],
      year: [[1900, new Date().getFullYear()]],
      sampleSize: [[0, 500]],
      variablesNumber: [[0, 30]],
      contains: [[]],
      notContains: [[]],
      exactlyContains: [[]],
      moderators: this.builder.array([]),
      reviewFilter: [],
      commentTypeFilter: [null],
      languageFilter: [null],
      decision: [null],
      discrepancy: [null],
      libmetaExist: [null],
      pdfExist: [null],
      comment: [],
      note: [],
      screeningCategory: [],
      reviewersComments: this.builder.array([]),
      numberOfAllComments: this.builder.array([
        this.builder.group({
          screeningId: [1],
          numberOfComments: []
        }),
        this.builder.group({
          screeningId: [2],
          numberOfComments: []
        }),
      ]),
      paperId: [],
      selectedTags: [[]]
    });
    this.filterForm.get('screeningCategory').valueChanges.subscribe(comment => {
      if (comment) {
        this.filterForm.get('comment').patchValue(comment);
      }
      if (comment === 'other') {
        this.filterForm.get('comment').patchValue('');
        this.showCommentControl = true;
      } else {
        this.showCommentControl = false;
      }
    });
  }

  patchFormValues(targetFilter: FilterSet) {
    if (!targetFilter) {
      this.buildForm();
      return;
    }
    const {
      year_rng_min,
      year_rng_max,
      author_without_punc__icontains,
      journal__icontains,
      abstract__icontains,
      batch_source,
      name_without_punc__icontains,
      comment_type,
      discrepancy,
      language,
      review,
      status__in,
      libmeta_exist,
      paper_pdf__isnull,
      sample_size_rng_max,
      sample_size_rng_min,
      contains_some_variables,
      contains_all_variables,
      not_contains_some_variables,
      moderator_variable_value,
      note__icontains,
      comment__comment_icontains,
      user_comments,
      id__exact,
      tags
    } = targetFilter;

    this.patchModeratorValues(moderator_variable_value);
    this.filterForm.patchValue({
      year: [year_rng_min, year_rng_max],
      authors: author_without_punc__icontains,
      journal: journal__icontains,
      abstract: abstract__icontains,
      source: batch_source,
      libmetaExist: libmeta_exist,
      pdfExist: paper_pdf__isnull,
      commentTypeFilter: comment_type,
      discrepancy,
      languageFilter: language,
      reviewFilter: review,
      decision: getDecisionFromStatusList(status__in as number[], this.filterType === FilterPaperType.FULL_TEXT_SCREENING),
      // moderators: moderator_variable_value ? moderator_variable_value.split(',') : null ,
      exactlyContains: contains_all_variables ? contains_all_variables.split(',') : null,
      notContains: not_contains_some_variables ? not_contains_some_variables.split(',') : null,
      contains: contains_some_variables ? contains_some_variables.split(',') : null,
      sampleSize: [sample_size_rng_min, sample_size_rng_max],
      title: name_without_punc__icontains,
      comment: comment__comment_icontains,
      note: note__icontains,
      paperId: id__exact,
      selectedTags: tags
    });
  }

  patchModeratorValues(moderatorValue) {
    let moderators = [];
    if (moderatorValue) {
      moderators = moderatorValue.split(
        ','
      );
      moderators.forEach((item, index) => {
        moderators[index] = item.split(':');
      });
    }
    moderators.forEach((moderator) => {
      this.moderatorFormArray
        .at(
          this.moderatorFormArray.value.findIndex(val => val.pk === Number(moderator[0]))
        )
        .patchValue({
          moderatorValue: moderator[1],
        });
    });
  }

  handleSelectFilter(event: any) {

    this.selectedSavedFilter = event.value;
    if (event.value === -1) {
      this.newFilterName.reset();
      this.buildForm();
    } else {
      const selectedFilter = this.filterList.find((item) => item.id === event.value);
      this.patchFormValues(selectedFilter.filter_set);
    }

  }
  // Moderators Form Array
  get moderatorsArray(): FormArray {
    return this.filterForm.get('moderators') as FormArray;
  }

  createModerator(item: Moderator): FormGroup {
    return this.builder.group({
      pk: [item.id],
      name: [item.name],
      type: [item.var_type],
      default: [item.default],
      moderatorValue: [''],
    });
  }

  addModeratorFormArray(): void {

    this.moderatorFormArray = this.filterForm.get('moderators') as FormArray;
    if (this.moderators) {
      this.moderatorFormArray.clear();
      this.moderators.forEach((item) => {
        this.moderatorFormArray.push(this.createModerator(item));
      });
    }

  }

  closeSideBar() {
    this.sidebarService.collapse('right');
    this.filterService.announceFilterToggle(false);
  }

  handleFilter(page?: number) {
    const filterType = this.getFilterType();
    let filter = this.getFilterFromFormControls();
    filter = {...this.filterPapers[filterType], ...filter};
    if (page) {
      filter = {...filter, page};
    }
    this.targetFilter = filter;
    this.store.dispatch(setFilterPapers({
      filter,
      filterType
    }));

  }

  handleClearFilters() {
    this.filterForm.reset();
    this.buildForm();
    this.handleFilter();
  }

  createFilter() {

    if (
      this.selectedSavedFilter == null &&
      this.selectedSavedFilter === undefined
    ) {
      this.toastrService.show('Please select Filter', 'Error', {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'danger',
        duration: 3000,
      });
      return;
    }
    let filterSet = { ...this.targetFilter };
    // let filterSet = { ...this.filterForm.value };
    if (filterSet.status__in) {
      filterSet = { ...filterSet, status__in: filterSet.status__in.toString() };
    }
    if (filterSet.year_rng) {
      filterSet = { ...filterSet, year_rng_min: +filterSet.year_rng[0] };
      filterSet = { ...filterSet, year_rng_max: +filterSet.year_rng[1] };
      filterSet = { ...filterSet, year_rng: null };
    }
    if (filterSet.sample_size_rng) {
      filterSet = {
        ...filterSet,
        sample_size_rng_min: +filterSet.sample_size_rng[0],
      };
      filterSet = {
        ...filterSet,
        sample_size_rng_max: +filterSet.sample_size_rng[1],
      };
      filterSet = { ...filterSet, sample_size_rng: null };
    }
    if (filterSet.with_number_of_variables_rng) {
      filterSet = {
        ...filterSet,
        with_number_of_variables_rng_max:
          +filterSet.with_number_of_variables_rng[0],
      };
      filterSet = {
        ...filterSet,
        with_number_of_variables_rng_max:
          +filterSet.with_number_of_variables_rng[1],
      };
      filterSet = { ...filterSet, with_number_of_variables_rng: null };
    }
    const filter: PaperFilter = {
      filter_set: filterSet,
      project: this.projectId,
    };
    if (Number(this.selectedSavedFilter) === -1) {
      filter.name = this.newFilterName.value;

      this.service.createFilter(filter).then((val) => {
        this.handleSaveFilterPostAction(val.id);
      });
    } else {
      filter.name = this.filterList.find(
        (item) => item.id === this.selectedSavedFilter
      ).name;
      this.service
        .editFilter(this.selectedSavedFilter, filter)
        .then((val) => {
          this.handleSaveFilterPostAction();
        });
    }
  }

  handleSaveFilterPostAction(editedFilterId?: number) {
    this.store.dispatch(showActionSuccessMessage({
      successMessage: `Filter was successfully ${editedFilterId ? 'edited' : 'added'}`
    }));
    this.loadSavedFilters();
    // set newly created filer as default selected between filter options
    if (editedFilterId) {
      this.selectedSavedFilter = editedFilterId;
    }
  }

  arrayToString(array): string {
    if (!array) {
      return '';
    }
    return array.map(val => val.id).join();
  }

  loadSavedFilters() {
    this.store.dispatch(loadSavedPaperFilters({ projectId: this.projectId }));
  }

  customSearchFn(term: string, item: ProjectVariable) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1;
  }

  getLanguages() {
    this.subscription = [
      ...this.subscription,
      this.service.getProjectLanguages(this.projectId).subscribe((result) => {
        if (result && result.languages.length > 0) {
          for (const lang of result.languages) {
            const language = {
              value: lang,
              name: LANGUAGES[lang],
            };
            this.languages.push(language);
            this.languages.sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            });
          }
        }
      }),
    ];
  }

  handleAcceptRejectAllPapers(isReject: boolean) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      minWidth: '500px',
      maxWidth: '80vw',
      data: {
        title: isReject ? 'Reject All' : 'Accept All',
        message: `Are you sure about ${isReject ? 'rejecting' : 'accepting'} all the papers?`,
        button: 'Yes',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.acceptOrRejectAllPapers(isReject);
      }
    });
  }

  acceptOrRejectAllPapers(reject: boolean) {
    const { decision } = this.filterForm.value;
    let paperBody: FilterSet;
    paperBody = {
      project: this.projectId,
      status: null,
      status__in: getStatusList(decision, this.filterType === FilterPaperType.FULL_TEXT_SCREENING).toString(),
      section: this.filterSection
    };
    if (this.targetFilter) {
      paperBody = {
        ...this.targetFilter,
        ...paperBody,
      };
    }

    if (this.filterType !== FilterPaperType.TITLE_SCREENING) {
      if (reject) {
        paperBody.change_status_to = 5;
      } else {
        paperBody.change_status_to = 7;
      }
    } else {
      if (reject) {
        paperBody.change_status_to = 3;
      } else {
        paperBody.change_status_to = 6;
      }
    }
    const filterType = this.getFilterType();
    let filter = this.getFilterFromFormControls();
    for (const prop in paperBody) {
      if (paperBody[prop] == null) {
        delete paperBody[prop];
      }
    }
    filter = {
      ...filter,
      status__in: filter.status__in
        ? filter.status__in.toString()
        : filter.status__in,
      comment_type: filter.comment_type
        ? filter.comment_type.toString()
        : filter.comment_type,
    };
    this.store.dispatch(
      changeAllPaperStatus({
        filter,
        paper: paperBody,
        filterType
      })
    );
  }

  handleSearchSubject(params: NgSelectSearch) {
    let timer;
    if (params) {
      if (params.term) {
        if (timer) {
          clearTimeout(timer);
        }
        if (params.term.trim().length < 3) {
          return;
        }
        this.subject.loading = true;
        this.subject.term = params.term;
        timer = setTimeout(() => {
          this.getSubjects();

        }, 1500);
      } else {
        this.subject.term = '';
        this.subject.loading = false;
      }
    }
  }

  addSubject(value) {
    if (value) {
      if (!this.subject.selected.some(val => value.name === val.name)) {
        this.subject.selected.push(value);
      }
    }

  }

  removeSubject(event) {
    const value = event.value;
    this.subject.selected = this.subject.selected.filter(val => val.name !== value.name);

  }

  getSubjects() {
    this.statisticService.getPaperSubjects(this.projectId, this.subject.term).subscribe(
      res => {
        this.subject.filtered = res;
        this.subject.loading = false;
      }
    );
  }

  setSelectedVariable(variables, type: string) {
    if (type === 'exactly') {
      this.filterForm.get('exactlyContains').patchValue(variables);
    } else if (type === 'notContain') {
      this.filterForm.get('notContains').patchValue(variables);
    } else {
      this.filterForm.get('contains').patchValue(variables);
    }
  }

  getFilterType(): string {
    if (this.filterType === FilterPaperType.DATA_ENTRY) {
      return 'dataEntry';
    } else if (this.filterType === FilterPaperType.TITLE_SCREENING) {
      return 'titleScreening';
    } else {
      return 'fullTextScreening';
    }
  }

  getFilterFromFormControls() {
    const {
      year,
      authors,
      journal,
      source,
      title,
      libmetaExist,
      pdfExist,
      commentTypeFilter,
      discrepancy,
      languageFilter,
      reviewFilter,
      decision,
      moderators,
      exactlyContains,
      notContains,
      contains,
      sampleSize,
      variablesNumber,
      comment,
      note,
      abstract,
      reviewersComments,
      numberOfAllComments,
      paperId,
      selectedTags
    } = this.filterForm.value;
    let moderatorFilter: string;
    if (moderators) {
      moderatorFilter = moderators
        .filter(val => {
          if (!val) {
            return;
          } else if (typeof val.moderatorValue === 'string') {
            return (val.moderatorValue != null && val.pk && val.moderatorValue.trim() !== '');
          } else {
            return (val.moderatorValue != null && val.pk);
          }
        })
        .map(val => `${val.pk}:${val.moderatorValue}`)
        .join();
    }
    let reviewerCommentsResult;
    if (reviewersComments) {
      reviewerCommentsResult = reviewersComments
        .filter((val) => val.userId)
        .map(val => {
          let commentsInTitle;
          let commentsInFullText;
          if (val.commentsInFullText && val.commentsInFullText.length) {
            commentsInTitle = { 2: val.commentsInFullText };
          }
          if (val.commentsInTitle && val.commentsInTitle.length) {
            commentsInFullText = { 1: val.commentsInTitle };
          }
          if (commentsInTitle || commentsInFullText) {
            return { [val.userId]: { ...(commentsInTitle || {}), ...(commentsInFullText || {}) } };
          }
        }).filter(val => val);
    }
    const filteredNumberOfComments = numberOfAllComments.map(controlValue => {
      if (this.filterType === FilterPaperType.TITLE_SCREENING && controlValue.screeningId === 2) {
        return;
      }
      if (controlValue.numberOfComments) {
        return {
          [controlValue.screeningId]: {
            min: controlValue.numberOfComments[0],
            max: controlValue.numberOfComments[1]
          }
        };
      }
    }).filter(val => val);


    let filter: FilterSet = {
      year_rng_min: year ? Number(year[0]) : null,
      year_rng_max: year ? Number(year[1]) : null,
      author_without_punc__icontains: authors,
      journal__icontains: journal,
      abstract__icontains: abstract,
      batch_source: source,
      name_without_punc__icontains: title,
      comment_type: commentTypeFilter,
      comment_status:
        this.filterType === FilterPaperType.TITLE_SCREENING
          ? 1
          : this.filterType === FilterPaperType.FULL_TEXT_SCREENING
            ? 2
            : null,

      discrepancy: discrepancy === '0' ? null : discrepancy,
      language: languageFilter,
      review: reviewFilter,
      status__in: getStatusList(decision, this.filterType === FilterPaperType.FULL_TEXT_SCREENING),
      libmeta_exist: libmetaExist,
      paper_pdf__isnull: pdfExist,
      project: this.projectId,
      subjects__id__in: this.subject.selected.map((val) => val.id).join(','),
      tags: selectedTags.map((val) => val.id).join(','),
      comment__comment__icontains: comment,
      note__icontains: note,
      comment__comment_icontains: comment,
      user_comments:
        reviewerCommentsResult && reviewerCommentsResult.length ?
          JSON.stringify(reviewerCommentsResult) :
          null,
      comment_numbers:
        filteredNumberOfComments && filteredNumberOfComments.length ?
          JSON.stringify(filteredNumberOfComments) :
          null,
      id__exact: paperId,
    };


    if (this.filterType === FilterPaperType.DATA_ENTRY) {
      const filterSpecificDataEntry = {
        status: 7,
        contains_all_variables: this.arrayToString(exactlyContains),
        not_contains_some_variables: this.arrayToString(notContains),
        contains_some_variables: this.arrayToString(contains),
        sample_size_rng_min: sampleSize ? Number(sampleSize[0]) : 0,
        sample_size_rng_max: sampleSize ? Number(sampleSize[1]) : 500,
        with_number_of_variables_rng: variablesNumber ? variablesNumber.toString() : '0,30',
        moderator_variable_value: moderatorFilter,
      };
      filter = { ...filter, ...filterSpecificDataEntry };
    }
    return filter;
  }

  createReviewersCommentFormArray() {
    if (!this.reviewers || !this.reviewers.length) {
      return;
    }
    const userComments = this.filterForm.get('reviewersComments') as FormArray;
    userComments.clear();
    this.reviewers.forEach(val => {
      userComments.push(
        this.createReviewerComment(val.user));
    });
  }

  createReviewerComment(user: UserInfo): FormGroup {
    return this.builder.group({
      userId: [user.user_id],
      commentsInFullText: [],
      commentsInTitle: [],
    });
  }

  get reviewersCommentsFormArray() {
    return this.filterForm.get('reviewersComments') as FormArray;
  }

  /**********************
   * TAGS
   *********************/
   handleSearchTags(searchTerm) {
    let timer;
    if (searchTerm.term) {
      if (searchTerm.term.length > 2) {
        this.tagsLoading = true;
        if (timer) {
          clearTimeout(timer);
        }
        this.tagTermToSearch = searchTerm.term;
        timer = setTimeout(() => {
          if (searchTerm.term) {
            this.searchTags(searchTerm.term);
          }
        }, 1500);
      }
    } else {
      this.tagTermToSearch = '';
    }

  }
  searchTags(searchTerm: string) {
    if (this.tagTermToSearch) {
      this.store.dispatch(
        projectActions.getTags({
          queryParams: {
            name__icontains: searchTerm,
            page_size: 20,
            page: 1,
            project: this.projectId.toString(),
          },
          reportProgress: true
        })
      );
    }

  }
  addTag(value) {
    if (value) {
      if (value.name) {
        if (value.name.length < 3) {
          return;
        }
        if (!this.selectedTagList.includes(value.name)) {
          this.selectedTagList.push(value.name);
        }
      }
    }
  }
  removeTag(event) {
    const value = event.value;
    this.selectedTagList = this.selectedTagList.filter(val => val !== value.name);
  }
}
