import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from '@core/service/project.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { PaperItem, Tag } from '@shared/schema/project';
import * as projectActions from '@modules/project/state/project.actions';
import { HttpUtils } from '@shared/utils/http-utils';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-add-tag-to-paper',
  templateUrl: './add-tag-to-paper.component.html',
  styleUrls: ['./add-tag-to-paper.component.scss']
})
export class AddTagToPaperComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted: boolean;
  subscription: Subscription[] = [];
  /***************
 * TAGS
 **************/
  tags: Tag[] = [];
  tagsLoading = false;
  selectedTagList = [];
  tagsId: number[];
  tagTermToSearch = '';
  tagSubmited = false;
  constructor(
    private matDialogRef: MatDialogRef<AddTagToPaperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      paper: PaperItem,
      projectId: number
    },
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
  ) { }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }

  ngOnInit() {
    this.careatFrom(this.data.paper);
    this.addSubscription();
  }
  addSubscription() {
    this.subscription = [
      ...this.subscription,
      this.subscribeTag(),
    ];
  }

  careatFrom(item?: PaperItem) {
    this.form = this.formBuilder.group({
      tags: [item ? item.tags : [], []]
    });
  }

  submitTags() {
    const tagsId: number[] = this.form.value.tags.map(x => x.id);
    this.projectService.editPaper(this.data.paper.pk, {tags: tagsId} ).subscribe(res => {
      if (HttpUtils.responceComplate(res)) {
        this.matDialogRef.close(this.form.value.tags);
      }
    });
  }
  /**********************
 * TAGS
 *********************/
  subscribeTag() {
    return this.store.select(store => (store.project.tagList)).subscribe(
      tagList => {
        if (this.tagTermToSearch) {
          if (tagList) {
            this.tags = tagList.data;
            this.tagsLoading = false;
          }
        }
      }
    );
  }

  handleSearchTags(searchTerm) {
    let timer;
    if (searchTerm.term) {
      if (searchTerm.term.length > 2) {
        this.tagsLoading = true;
        if (timer) {
          clearTimeout(timer);
        }
        this.tagTermToSearch = searchTerm.term;
        timer = setTimeout(() => {
          if (searchTerm.term) {
            this.searchTags(searchTerm.term);
          }
        }, 1500);
      }
    } else {
      this.tagTermToSearch = '';
    }

  }
  searchTags(searchTerm: string) {
    if (this.tagTermToSearch) {
      this.store.dispatch(
        projectActions.getTags({
          queryParams: {
            name__icontains: searchTerm,
            page_size: 20,
            page: 1
          },
          reportProgress: true
        })
      );
    }

  }
  addTag = (value: string) => {
    if (value.length < 3) {
      return;
    }
    const newTag: Tag = {
      id: null,
      name: value,
      slug: 'Create New Tag In Add Tag To Paper'
    };
    this.tagsLoading = true;
    const result = this.projectService.createTag(newTag).toPromise();
    this.tagsLoading = false;
    return result;
  }

  removeTag(event) {
    const tag = event.value;
    this.projectService.deleteTag(tag.id).subscribe();
  }
}
