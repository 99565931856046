import { Action, createReducer, on } from '@ngrx/store';
import {PublicProjectListState} from '@shared/schema/public-project-list.state';
import * as projectListAction from '@modules/dashboard/state/project-list.actions';

export const publicProjectListFeatureKey = 'publicProjectList';

export const initialState: PublicProjectListState = {
  list: null
};

const publicProjectListReducer = createReducer(
  initialState,
  on(projectListAction.loadPublicProjectListsSuccess, (state, action) => ({ ...state, list: action.list })),

);

export function PublicProjectListReducer(state: PublicProjectListState | undefined, action: Action) {
  return publicProjectListReducer(state, action);
}
