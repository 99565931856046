import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paperStatus'
})
export class PaperStatusPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    switch (value) {
      case 1:
        return 'Deduplication Rejected';
        break;
      case 2:
        return 'Deduplication';
        break;
      case 3:
        return 'Title Screening Rejected';
        break;
      case 4:
        return 'Deduplication Accepted (Title Screening)';
        break;
      case 5:
        return ' Fulltext Rejected';
        break;
      case 6:
        return 'Title Accepted (FullText)';
        break;
      case 7:
        // return 'Fulltext Accepted';
        return 'Data Entry';
        break;
      default:
        return 'unknown';
        break;
    }
  }

}
