export const FilterSectionPermission = {
  FulltextReseacherReviwe: {
    fulltext_screening: 1
  },

  FulltextReviewerReviwe: {
    fulltext_screening: 2
  },

  FulltextUserComment: {
    fulltext_screening: 1
  },

  TitleReseacherReviwe: {
    title_screening: 1
  },
  TitleReviewerReviwe: {
    title_screening: 2
  },

  TitleUserComment: {
    title_screening: 1,
    fulltext_screening: 1,
  },

  UserModrators: {
    data_entry: true
  },

  Variable: {
    data_entry: true
  }

};
