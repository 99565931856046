import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatAutocompleteSelectedEvent,
} from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import {
  loadProjectVariables,
  getCitations,
  createModerator,
  createVariablesGroup,
  createCitation,
  updateModerator,
  updateCitation,
  createProjectVariable,
  updateProjectVariable,
  createTags,
  updateVariablesGroup,
  getTags,
  resetNewCorrelationValue,
} from '@modules/project/state/project.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Tag,
  CorrelationVariable,
  ProjectVariable,
  Citation,
  VariablesGroup,
  WorkspaceType,
} from '@shared/schema/project';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Subject, Subscription } from 'rxjs';
import { AVERAGE_BIG_NUMBER } from '@shared/data/const-data';
import { linkRegex, yearRegex } from '@shared/utils/regex';
import { ModalService } from '@core/service/modal.service';
import { guides } from '@shared/data/guides';
import { ProjectService } from '@core/service/project.service';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export interface NgSelectSearch {
  items: Array<any>;
  term: string;
}

@Component({
  selector: 'app-add-new-workspace',
  templateUrl: './add-new-workspace.component.html',
  styleUrls: ['./add-new-workspace.component.scss'],
})
export class AddNewWorkspaceComponent implements OnInit, OnDestroy {
  variableForm: FormGroup;
  projectVariablesFiltered: ProjectVariable[] = [];
  projectVariables: ProjectVariable[] = [];
  variablesGroupList: number[];
  defaultSelectedVariablesGroup: VariablesGroup[];
  tagsId: number[] = [];
  // tagList: TagList;
  tags: Tag[] = [];
  selectedTagList = [];
  selectedCitationList: Citation[] = [];
  citations: Citation[] = [];
  filteredCitations: Citation[] = [];
  citationsLoading = false;
  tagsLoading = false;
  varType = 3;
  newCorrelationVariable: CorrelationVariable = {
    name: '',
    definition: '',
    tags: [],
    citations: [],
    var_type: null,
    parent: null,
    project: null,
    deleted: null,
    id: null,
    relation_with_parent: null,
  };
  visualDataLoading = false;

  // tag variables
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  reliabilityAverage = false;
  correctTotal = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  moderatorTypes = [
    { id: 1, name: 'string' },
    { id: 2, name: 'choice' },
    { id: 3, name: 'int' },
  ];
  relationWithParentOptions = [
    { label: 'positive', value: 1 },
    { label: 'negative', value: 0 },
  ];


  selectedDefaultVariable: string[] = [];

  @ViewChild('tagInput', null) tagInput: ElementRef<HTMLInputElement>;

  subscriptions: Subscription[];
  citationTermToSearch = '';
  tagTermToSearch = '';
  tagSubmited = false;
  submitted = false;

  guides = {
    tag: guides.workspace.tags,
    reliability: guides.workspace.reliability,
    parent: guides.workspace.parent,
    sd: guides.workspace.sdValue,
    variableTypeGuide: '',
    addGroupVariables: guides.workspace.addVariablesGroup
  };
  private pendingRequests$ = new Subject<void>();
  doiNotFound = false;
  doiFields = ['name', 'year', 'author', 'link', 'journal'];
  constructor(
    public dialogRef: MatDialogRef<AddNewWorkspaceComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: {
      workspaceType: WorkspaceType,
      variable: any,
      projectId: number,
      actionTitle: string
    },
    private formBuilder: FormBuilder,
    private toastrService: NbToastrService,
    private modalService: ModalService,
    private projectService: ProjectService,
    private spinner: NgxSpinnerService
  ) { }
  ngOnInit() {
    this.store.dispatch(resetNewCorrelationValue(null));
    this.varType = this.setVarType();
    this.setVariableGuide();
    if (this.data.variable) {
      if (this.data.variable.tags) {
        this.selectedTagList = this.data.variable.tags;
      }
      if (this.data.variable.citations) {
        this.selectedCitationList = [...this.data.variable.citations];
      }
    }

    this.subscriptions = [
      this.store
        .select((state) => state.project.projectVariables)
        .subscribe((projectVariables) => {
          if (projectVariables) {
            if (projectVariables.length) {
              this.projectVariables = projectVariables;
              if (this.varType === 1 || this.varType === 2) {
                this.projectVariablesFiltered = projectVariables.filter(
                  (visual) =>
                    visual.var_type.toString() === (this.varType + 1).toString()
                );
              }
            }
          } else {
            this.store.dispatch(loadProjectVariables({
              projectId: +this.data.projectId
            }));
          }
        }),
      this.store
        .select((state) => state.project.tagList)
        .subscribe((tagList) => {
          if (this.tagTermToSearch) {
            if (tagList) {
              if (tagList.data) {
                this.tags = tagList.data;
              }
              this.tagsLoading = false;
            }
          }

        }),
      this.store
        .select((state) => state.project.citationList)
        .subscribe((citationList) => {
          if (this.citationTermToSearch) {
            if (citationList) {
              if (citationList.data) {
                this.citations = citationList.data;
                this.filteredCitations = citationList.data.filter(
                  citation => !this.selectedCitationList.find(val => val.id === citation.id)
                );
              }
              this.citationsLoading = false;
            }
          }

        }),

      this.store
        .select((state) => state.project.successAlertMessage)
        .subscribe((successAlertMessage) => {
          if (successAlertMessage) {
            if (
              (
                successAlertMessage.toLowerCase().indexOf('variable') !== -1 ||
                (successAlertMessage.toLowerCase().indexOf('citation') !== -1 &&
                  this.data.workspaceType.toLowerCase().indexOf('citation') !== -1)
              ) &&
              (successAlertMessage.toLowerCase().indexOf('edited') !== -1 ||
                successAlertMessage.toLowerCase().indexOf('added') !== -1)
            ) {
              this.variableForm.reset();
              this.submitted = false;
              this.closeDialog(true);
            }
          }
        }),
      // this.store.select(store => (store.project.tagsId)).subscribe(
      //   tagsId => {
      //     if (tagsId) {
      //       if (this.tagSubmited) {
      //         this.tagsId = [...this.tagsId, ...tagsId];
      //         if (this.data.variable) {
      //           this.editCorrelationVariable();
      //         } else {
      //           this.createCorrelationVariable();
      //         }
      //         this.tagSubmited = false;
      //       }

      //     }
      //   }
      // ),
      this.store.select(store => store.project.newVariable).subscribe(
        newVariable => {
          if (newVariable) {
            if (this.variableForm && this.variableForm.get('citations')) {
              let addedCitations = [];
              if (this.variableForm.get('citations').value) {
                addedCitations = [...this.variableForm.get('citations').value];
              }
              this.variableForm.get('citations').setValue([...addedCitations, newVariable]);
            }
            this.addCitations(newVariable);
          }

        }
      )
    ];


    this.createVariableForm();

  }
  ngOnDestroy() {
    for (const item of this.subscriptions) {
      item.unsubscribe();
    }
  }

  createVariableForm() {
    if (this.varType === 4) {
      this.createModeratorForm();
    } else if (this.varType === 5) {
      this.createCitationForm();
    } else if (this.varType === 6) {
      this.createVariableSetForm();
    } else {
      this.createCorrelationVariableForm();
    }
  }
  createCorrelationVariableForm() {
    let reliability = null;
    let defaultSD = null;
    if (this.data.variable) {
      const { reliability_default_value_data, default_sd_on_unrestricted_sample } = this.data.variable;
      if (reliability_default_value_data) {
        if (reliability_default_value_data === AVERAGE_BIG_NUMBER) {
          this.reliabilityAverage = true;
        } else {
          reliability = reliability_default_value_data;
        }
      }
      if (default_sd_on_unrestricted_sample) {
        defaultSD = default_sd_on_unrestricted_sample;
      }
    }

    this.variableForm = this.formBuilder.group({
      name: [
        this.data.variable ? this.data.variable.name ? this.data.variable.name : '' : '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(512),
        ],
      ],
      definition: [
        this.data.variable ? this.data.variable.definition ? this.data.variable.definition : '' : '',
        [Validators.required, Validators.minLength(1)],
      ],
      reliabilities: [reliability, [Validators.max(1), Validators.min(-1)]],
      defaultSD: [defaultSD, []],
      citations: [this.data.variable ? this.data.variable.citations ? this.data.variable.citations : [] : [], []],
      tags: [this.data.variable ? this.data.variable.tags ? this.data.variable.tags : [] : [], []],
      parent: [this.data.variable && this.projectVariablesFiltered ?
        this.projectVariablesFiltered.find(val => val.id === this.data.variable.parent) : null, []
      ],
      survey: [this.data.variable ? this.data.variable.survey ? this.data.variable.survey : null : null, []],
      parentRelation: [{
        value: this.data.variable ?
          this.data.variable.relation_with_parent || this.data.variable.relation_with_parent === 0
            ? this.data.variable.relation_with_parent : 1 : 1,
        disabled: this.data.variable && this.data.variable.parent ? false : true
      }, []],
    });
    this.variableForm.get('parent').valueChanges.subscribe(val => {
      if (!val) {
        this.variableForm.get('parentRelation').disable();
        this.variableForm.get('parentRelation').setValidators([]);
      } else {
        this.variableForm.get('parentRelation').enable();
        this.variableForm.get('parentRelation').setValidators([Validators.required]);
      }
    });
  }
  createModeratorForm() {
    this.selectedDefaultVariable =
      this.data.variable && this.data.variable.default && this.data.variable.default.options ? this.data.variable.default.options : [];
    this.variableForm = this.formBuilder.group({
      name: [
        this.data.variable ? this.data.variable.name : '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(512),
        ],
      ],
      var_type: [
        this.data.variable ? this.data.variable.var_type : this.moderatorTypes[0].id,
        [Validators.required],
      ],
      default: [this.data.variable && this.data.variable.default ? this.data.variable.default.options : null, []],
    });
  }
  createCitationForm() {
    this.variableForm = this.formBuilder.group({
      name: [
        this.data.variable ? this.data.variable.name : '',
        [Validators.required, Validators.maxLength(512)],
      ],
      journal: [this.data.variable ? this.data.variable.journal : null, []],
      author: [
        this.data.variable ? this.data.variable.author : null,
        [Validators.maxLength(512)],
      ],
      link: [this.data.variable ? this.data.variable.link : null,
      { validators: [Validators.pattern(linkRegex)] }
      ],
      year: [this.data.variable ? this.data.variable.year : null,
      { validators: [Validators.pattern(yearRegex)] }
      ],
      doi: []
    });
    this.variableForm.controls.doi.valueChanges.subscribe(val => {
      if (val) {
        let timeout;
        if (timeout) {
          clearTimeout(timeout);
        }
        if (val.length > 5) {
          this.spinner.show('doi-loader');
          timeout = setTimeout(() => {
            this.handleSearchDOI();
          }, 2000);
        }
      }
    });
  }
  createVariableSetForm() {
    this.variablesGroupList = this.data.variable ? this.data.variable.variables.map(val => val.id) : [];
    this.defaultSelectedVariablesGroup = this.data.variable ? this.data.variable.variables : [];
    this.variableForm = this.formBuilder.group({
      name: [
        this.data.variable ? this.data.variable.name : '',
        [Validators.required],
      ],
    });
  }

  closeDialog(param) {
    this.dialogRef.close(param);
  }

  handleSearchByText(params: NgSelectSearch, searchType: string) {
    let timer;
    if (params) {
      if (params.term) {
        if (timer) {
          clearTimeout(timer);
        }
        if (params.term.trim().length < 3) {
          return;
        }
        if (searchType === 'tag') {
          this.tagTermToSearch = params.term;
          this.tagsLoading = true;
        } else {
          this.citationTermToSearch = params.term;
          this.citationsLoading = true;
        }
        timer = setTimeout(() => {
          if (searchType === 'tag') {
            this.searchTags(params.term);
          } else {
            this.searchCitations(params.term);
          }

        }, 1500);
      } else {
        if (searchType === 'tag') {
          this.tagTermToSearch = '';
          this.tags = [];
          this.tagsLoading = false;
        } else {
          this.citationTermToSearch = '';
          this.citations = [];
          this.citationsLoading = false;
        }
      }
    }
  }


  searchCitations(term: string) {
    if (term && this.citationTermToSearch) {
      this.store.dispatch(
        getCitations({
          queryParams: {
            search: term,
            page: 1,
            page_size: 20,
            project: this.data.projectId.toString(),
          },
          reportProgress: true
        })
      );
    }
  }
  customSearchFn(term: string, item: Citation) {
    term = term.toLowerCase();
    const searchName = item.name ? item.name.toLowerCase().indexOf(term) !== -1 : false;
    const searchJournal = item.journal ? item.journal.toLowerCase().indexOf(term) !== -1 : false;
    const searchAuthor = item.author ? item.author.toLowerCase().indexOf(term) !== -1 : false;
    const res = searchName || searchJournal || searchAuthor;
    return res;
  }
  addCitations(value) {
    if (value) {
      if (!this.selectedCitationList.some(val => value.name === val.name)) {
        this.selectedCitationList.push(value);
        this.filterCitation(value);
      }
    }
  }
  filterCitation(citation: Citation) {
    this.filteredCitations = this.filteredCitations.filter(val => val.id !== citation.id);
  }
  removeCitation(event) {
    const value = event.value;
    this.selectedCitationList = this.selectedCitationList.filter(val => val.name !== value.name);
    this.filteredCitations = [...this.filteredCitations, value];
  }

  // addTag(value) {
  //   debugger;
  //   if (value) {
  //     if (value.name) {
  //       if (value.name.length < 3) {
  //         return;
  //       }
  //       if (!this.selectedTagList.find(val => val.name === value.name)) {
  //         this.selectedTagList = [...this.selectedTagList, value ];
  //       }
  //     }
  //   }
  // }
  addTag = (value: string) => {
    if (value.length < 3) {
      return;
    }
    const newTag: Tag = {
      id: null,
      name: value,
      slug: 'Create New Tag In New Work Space Variable'
    };
    this.tagsLoading = true;
    const result = this.projectService.createTag(newTag).toPromise();
    this.tagsLoading = false;
    return result;
  }


  searchTags(searchTerm: string) {
    if (this.tagTermToSearch) {
      this.store.dispatch(
        getTags({
          queryParams: {
            name__icontains: searchTerm,
            page_size: 20,
            page: 1,
            project: this.data.projectId.toString(),
          },
          reportProgress: true
        })
      );
    }

  }
  // customTagFilter(term: string, item: any) {
  //   return item;
  // }
  // removeTag(event) {
  //   const value = event.value;
  //   this.selectedTagList = this.selectedTagList.filter(val => val.name !== value.name);

  // }
  // tagSelected(event: MatAutocompleteSelectedEvent) {
  //   console.log(event);
  //   this.selectedTagList.push( this.tagList.data.find(tag => tag.name === event.option.viewValue) );
  //   this.tagInput.nativeElement.value = '';
  //   this.variableForm.get('tags').setValue(null);
  // }
  submitVariable() {
    this.submitted = true;
    if (this.varType === 6) {
      if (this.variablesGroupList.length === 0) {
        return;
      }
    }
    if (this.variableForm.valid) {
      if (this.data.actionTitle.toLowerCase() === 'edit') {
        this.editVariable();
        return;
      } else {
        this.createVariable();
      }
    }

  }
  createVariable() {
    if (this.varType === 4) {
      this.store.dispatch(
        createModerator({
          moderator: {
            var_type: this.variableForm.get('var_type').value,
            default: { options: this.variableForm.get('var_type').value === 2 ? this.selectedDefaultVariable : [] },
            name: this.variableForm.get('name').value,
            project: this.data.projectId,
            id: undefined,
          },
        })
      );
    } else if (this.varType === 6) {
      this.store.dispatch(
        createVariablesGroup({
          variablesGroup: {
            name: this.variableForm.get('name').value,
            variables_id: this.variablesGroupList,
            project: this.data.projectId,
          },
        })
      );
    } else if (this.varType === 5) {
      const { name, year, author, link, journal } = this.variableForm.value;
      this.store.dispatch(
        createCitation({
          citation: {
            name,
            year,
            author,
            journal,
            link,
            id: undefined,
            project: this.data.projectId,
          },
        })
      );
    } else {
      const noNewTag = this.handleNewTagSubmission();
      if (noNewTag) {
        this.createCorrelationVariable();
      }
    }
  }
  editVariable() {
    if (this.varType === 4) {
      this.store.dispatch(
        updateModerator({
          moderator: {
            var_type: this.variableForm.get('var_type').value,
            default: { options: this.variableForm.get('var_type').value === 2 ? this.selectedDefaultVariable : [] },
            name: this.variableForm.get('name').value,
            id: this.data.variable.id,
            project: this.data.projectId,
          },
        })
      );
    } else if (this.varType === 5) {
      this.store.dispatch(
        updateCitation({
          citation: {
            name: this.variableForm.get('name').value,
            year: this.variableForm.get('year').value,
            author: this.variableForm.get('author').value,
            journal: this.variableForm.get('journal').value,
            link: this.variableForm.get('link').value,
            id: this.data.variable ? this.data.variable.id : undefined,
            project: this.data.projectId,
          },
        })
      );
    } else if (this.varType === 6) {
      this.store.dispatch(
        updateVariablesGroup({
          variablesGroup: {
            name: this.variableForm.get('name').value,
            variables_id: this.variablesGroupList,
            project: this.data.projectId,
            id: this.data.variable.id,
          },
        })
      );
    } else {
      const noNewTag = this.handleNewTagSubmission();
      if (noNewTag) {
        this.editCorrelationVariable();
      }
    }
  }
  setVarType() {
    if (this.data.workspaceType.toLowerCase().indexOf('group construct') !== -1) {
      return 3;
    } else if (this.data.workspaceType.toLowerCase().indexOf('construct') !== -1) {
      return 2;
    } else if (this.data.workspaceType.toLowerCase().indexOf('measurement') !== -1) {
      return 1;
    } else if (
      this.data.workspaceType.toLowerCase().indexOf('moderator variable') !== -1
    ) {
      return 4;
    } else if (
      this.data.workspaceType.toLowerCase().indexOf('variable set') !== -1
    ) {
      return 6;
    } else if (this.data.workspaceType.toLowerCase().indexOf('citations') !== -1) {
      return 5;
    }
  }
  showToast(message: string, title?: string) {
    this.toastrService.show(message, title ? title : '', {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      status: 'success',
      duration: 3000,
    });
  }
  submitDefaultVariable(event: MatChipInputEvent) {
    if (!event) {
      return;
    }
    const input = event.input;
    let value;
    if (event.value) {
      value = event.value.trim();
    }
    if (value) {
      if (this.selectedDefaultVariable.find((val) => val === value)) {
        return;
      }
    } else {
      return;
    }
    this.selectedDefaultVariable = [...this.selectedDefaultVariable, ...[value]];
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeDefaultVariable(defaultVar) {
    this.selectedDefaultVariable = this.selectedDefaultVariable.filter(val => val !== defaultVar);
  }

  updateVariablesGroupList(event) {
    this.variablesGroupList = event;
    // for (const key in event) {
    //   if (event.hasOwnProperty(key)) {
    //     const element = event[key];
    //     if (element) {
    //       this.variablesGroupList.push(element.id);

    //     }
    //   }
    // }

  }
  createCorrelationVariable() {
    this.store.dispatch(
      createProjectVariable({
        variable: this.getCorrelationParam(false),
      })
    );
  }
  editCorrelationVariable() {
    this.store.dispatch(
      updateProjectVariable({
        variable: this.getCorrelationParam(true),
      })
    );
  }
  getCorrelationParam(editFlag: boolean) {
    const {
      name,
      definition,
      parent,
      parentRelation,
      reliabilities,
      survey,
      defaultSD,
      tags
    } = this.variableForm.value;
    return {
      name,
      definition,
      tags_id: tags.map(tag => tag.id),
      citations_id: this.selectedCitationList.map((citation) => citation.id),
      id: !editFlag ? undefined : this.data.variable ? this.data.variable.id : undefined,
      parent: parent
        ? parent.id
        : null,
      relation_with_parent: parentRelation || parentRelation === 0
        ? parentRelation
        : 1,
      deleted: this.data.variable ? this.data.variable.deleted : false,
      project: this.data.projectId,
      reliability_default_value_data: this.reliabilityAverage ? AVERAGE_BIG_NUMBER : reliabilities,
      default_sd_on_unrestricted_sample: this.correctTotal ? null : defaultSD,
      var_type: this.varType,
      survey: survey ? survey : ''
    };
  }

  updateForm(type) {
    if (this.variableForm.controls.reliabilities && type === 'reliabilityAverage') {
      if (!this.reliabilityAverage) {
        this.variableForm.controls.reliabilities.setValidators([Validators.required, Validators.max(1), Validators.min(-1)]);
      } else {
        this.variableForm.controls.reliabilities.clearValidators();
        this.variableForm.controls.reliabilities.setValue('');
      }
      this.variableForm.controls.reliabilities.updateValueAndValidity();
    }
    if (this.variableForm.controls.defaultSD && type === 'correctTotal') {
      if (!this.correctTotal) {
        this.variableForm.controls.defaultSD.setValidators([Validators.required]);
      } else {
        this.variableForm.controls.defaultSD.clearValidators();
        this.variableForm.controls.defaultSD.setValue('');
      }
      this.variableForm.controls.defaultSD.updateValueAndValidity();

    }
  }
  submitTags(tags: string[]) {
    this.tagSubmited = true;
    this.store.dispatch(
      createTags({
        projectId: this.data.projectId,
        tags,
      })
    );
  }
  handleNewTagSubmission() {
    const newTags = this.selectedTagList.filter(val => !val.id);
    const prevTags = this.selectedTagList.filter(val => val.id);
    // populate tagsId with previous tags ids
    this.tagsId = prevTags.map(val => val.id);
    if (newTags && newTags.length) {
      this.submitTags(newTags.map(val => val.name));
      return false;
    }
    return true;
  }
  openAddCitationModal() {
    this.modalService.announceProjectVariableModalOpen('citations', false);
  }
  setVariableGuide() {
    if (this.data.workspaceType === 'Moderator Variables') {
      this.guides = { ...this.guides, variableTypeGuide: guides.workspace.addModeratorVar };
    } else if (this.data.workspaceType === 'Citations') {
      this.guides = { ...this.guides, variableTypeGuide: guides.workspace.addCitation };
    }
  }
  handleSearchDOI() {
    const { doi } = this.variableForm.value;
    this.doiNotFound = false;
    this.pendingRequests$.next();
    this.projectService.searchDOI({ doi }).pipe(takeUntil(this.pendingRequests$.asObservable())).subscribe(res => {
      this.spinner.hide('doi-loader');
      for (const field of this.doiFields) {
        this.variableForm.controls[field].setValue(res[field]);
      }
    }, error => {
      this.spinner.hide('doi-loader');
      if (error.status === 404) {
        this.doiNotFound = true;
        for (const field of this.doiFields) {
          this.variableForm.controls[field].setValue('');
        }
      }
    });
  }

  omit_special_char(event) {
    const key = event.charCode;
    // 59=;
    if (key === 59) {
      return false;
    }
    return true;
  }
}
