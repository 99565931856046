import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { UserInfo } from '@shared/schema/profile';
import { ProjectRanges } from '@shared/schema/project';
import { Subscription } from 'rxjs';
import { Ng5SliderService } from '../../../services/ng5-slider.service';
import { FilterUserCommentDialogComponent } from '../filter-user-comment-dialog/filter-user-comment-dialog.component';

@Component({
  selector: 'app-filter-title-user-comment',
  templateUrl: './filter-title-user-comment.component.html',
  styleUrls: ['./filter-title-user-comment.component.scss']
})
export class FilterTitleUserCommentComponent implements OnInit, OnDestroy {
  @Input() projectId: number;
  @Input() form: FormGroup;
  ranges: ProjectRanges;
  subscription: Subscription[] = [];
  userCommentList = [];
  reviewers: UserInfo[] = [];


  /*------------------------------------
   * [NG5SLIDER BUG]
   * slider should be reset afterOpened
   * otherwise this not whow range correctly
  -------------------------------------*/
  ng5SliderManualRefresh;


  constructor(
    private store: Store<AppState>,
    private slidersSevice: Ng5SliderService,
    private dialog: MatDialog,
  ) {
    this.ng5SliderManualRefresh = this.slidersSevice.refresh;
  }

  ngOnInit() {
    this.setSubscription();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  setSubscription() {
    this.subscription.push(this.getProjectRanges());
    this.subscription.push(this.subscribeMembers());
    this.subscription.push(this.subscribeTitleUserComments());
  }
  getProjectRanges() {
    return this.store
      .select((state) => state.project.projectRanges)
      .subscribe((state) => {
        this.ranges = state;
      });
  }

  subscribeMembers() {
    return this.store.select(state => state.project.memberList).subscribe((allMembers) => {
      if (allMembers && allMembers.data) {
        this.reviewers = allMembers.data
          .filter(item => item.role === null)
          .map(item => item.user);
      }
    });
  }

  subscribeTitleUserComments() {
    return this.form.get('titleUserComments').valueChanges.subscribe(value => {
      this.userCommentList = this.preaperDatatoShow(value);
    });
  }

  openFilterUserComment() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '50%',
      maxHeight: '80vh',
      data: {
        projectId: this.projectId,
        userComments: this.form.get('titleUserComments').value,
        reviewers: this.reviewers
      },
    };
    const dialogRef = this.dialog.open(FilterUserCommentDialogComponent, config);
    dialogRef.afterClosed().subscribe((data: { userId: number, value: number[] }[]) => {
      if (data) {
        (this.form.get('titleUserComments') as FormArray).clear();
        data.forEach(item => {
          (this.form.get('titleUserComments') as FormArray).push(new FormControl(item));
        });
      }
    });
  }

  preaperDatatoShow(data: { userId: number, value: number[] }[]) {
    return data.map((item) => {
      return {
        full_name: this.reviewers.find(v => v.user_id === item.userId).full_name,
        value: item.value.map(v => {
          switch (v) {
            case 1:
              return 'Yes';
              break;
            case 2:
              return 'No';
              break;
            case 3:
              return 'Maybe';
              break;
            default:
              return null;
              break;
          }
        })
      };
    });
  }

}
