import { Validator } from '@angular/forms';

export enum filterItemType {
  TEXTBOX,
  DROPDOWN,
  CHECKBOX,
  RADIO,
  DATEPICKER,
  DATETIMEPICKER,
  NUMBER,
  SLIDER,
  RANGE,
  SWITCH,
  TEXTAREA,
  IMAGE,
  FILE,
  TAG,
  LINK,
  MAP,
  GEOLOCATION,
  SUBMIT,
  CUSTOM
}
export enum filterItemAccess {
  OWNER,
  RESEAECHER,
  REVIEWER,
}
export enum filterItemState {
  GENERAL,
  ADD_PAPER_MANUALLY,
  DATA_ENTRY,
  TITLE_SCREENING,
  FULLTEXT_SCREENING,

}
export interface IFilterItem {
  name: string;
  id: string;
  type: filterItemType;
  defaultValue?: any;
  validation?: Validator[] ;
  value: any;
  access: filterItemAccess[];
  state: filterItemState[];
}
