import { Component, OnInit, isDevMode } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { LoadingService } from '@core/service/loading.service';
import { MatDialog } from '@angular/material';
import { BrowserModalComponent } from '@shared/components/browser-modal/browser-modal.component';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { Gtag } from 'angular-gtag';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Hub Meta | Dashboard';
  message = '';
  constructor(
    private iconLibraries: NbIconLibraries,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    public router: Router,
    private gtag: Gtag
  ) {
    this.iconLibraries.registerFontPack('icomoon');
    if (environment && environment.ga && !isDevMode()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.gtag.event('screen_view', {
            page_path: event.urlAfterRedirects
          });
        }
      });
    }
  }
  ngOnInit() {
    this.loadingService.setLoadingMessage.subscribe(message => {
      this.message = message;
    });
    // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    // if (!isChrome) {
    //   this.dialog.open(BrowserModalComponent, {
    //     width: '40%',
    //     maxHeight: '90vh',
    //   });
    // }
  }
}
