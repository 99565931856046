import {Variable, VariableNodesItem} from '@shared/schema/variable';


export class VariableNodes {
  private nodes: Map<number, VariableNodesItem>;

  constructor(variables: Variable[]) {
    const newNode = [];
    for (const variable of variables) {
      newNode.push(...VariableNodes.getNode(variable));
    }
    this.nodes = newNode.reduce(
      (all, current) => all.set(current.id, current),
      new Map<number, VariableNodesItem>()
    );
  }

  static getNode({children, id, name, type}: Variable, parentId: number = null): VariableNodesItem[] {
    const out = [];
    out.push({id, name, type, parentId});
    if (children) {
      for (const child of children) {
        out.push(...VariableNodes.getNode(child, id));
      }
    }
    return out;
  }

  getParents(id: number) {
    const node = this.nodes.get(id);
    let output = {};
    output[node.type] = node.name;
    if (node.parentId) {
      const parent = this.getParents(node.parentId);
      output = {...output, ...parent};
    }
    return output;
  }


}
