import {Component, OnInit, Input} from '@angular/core';
import { UserInfo } from '@shared/schema/profile';
import { PaperStatusType } from '@shared/schema/project';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() history;
  @Input() user: UserInfo;
  @Input() last: boolean;
  historyType = '';
  constructor() {
  }

  ngOnInit() {
    if (this.history) {
      if ('comment' in this.history) {
        this.historyType = 'comment';
      } else if ('file' in this.history) {
        this.historyType = 'batchFile';
      } else if ('author' in this.history) {
        this.historyType = 'paper';
      }
    }

  }
  setCommentStatus(status) {
    if (status === 1) {
      return 'Title Screening';
    } else if (status === 2) {
      return 'Full Text Screening';
    }
  }
  setCommentType(type) {
    if (type === 1) {
      return 'Yes';
    } else if (type === 2) {
      return 'No';
    } else if (type === 3) {
      return 'Maybe';
    }
  }
  setPaperStatus(paper) {
    return PaperStatusType[paper];
  }

}
