import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ProjectService } from '@core/service/project.service';
import { PaperItemInfo, PaperItem, CommentType, UploadedPdf } from '@shared/schema/project';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StatisticService } from '@core/service/statistic.service';
import { AppState } from '@shared/schema/app.state';
import { Store } from '@ngrx/store';
// import * as statisticsActions from '@modules/project/state/statistics.actions';
import { PaperComment, ScreeningReason } from '@shared/schema/statistic';
import { Subscription } from 'rxjs';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {loadProjectStatistics, showToastMessage } from '@modules/project/state/project.actions';
import { HttpEventType } from '@angular/common/http';
import {environment} from '@env';
import { PDFProgressData } from 'pdfjs-dist';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '@core/service/authentication.service';
import { PaperService } from '@core/service/paper.service';
import { UserRoles } from '@shared/schema/profile';
import { take } from 'rxjs/operators';
import { AppRuntimeConfig, CONFIG } from '@core/service/config.service';
import { HandleAssignmentComponent } from '@shared/components/handle-assignment/handle-assignment.component';
@Component({
  selector: 'app-article-comments',
  templateUrl: './article-comments.component.html',
  styleUrls: ['./article-comments.component.scss']
})
export class ArticleCommentsComponent implements OnInit, OnDestroy {
  paperInfo: PaperItem;
  commentForm: FormGroup;
  decisionForm: FormGroup;
  flag: boolean;
  changed = false;
  screeningReasons: ScreeningReason[];
  notAvailableID: number;
  subscriptions: Subscription[] = [];
  terms = [];
  palette = ['#ef9a9a', '#9fa8da', '#a5d6a7', '#ffe082', '#bcaaa4', '#ffab91', '#e6ee9c', '#81d4fa', '#ce93d8'];
  pdf;
  progress: number;
  submitted = false;
  hostName = this.appRuntimeConfig.host_name.substr(0, this.appRuntimeConfig.host_name.length - 1);
  pdfSource = [];
  noteControl: FormControl;
  isDropboxConnected = false;
  commentType = CommentType;
  titleScreeningComments: PaperComment[];
  fullTextScreeningComments: PaperComment[];
  isMember = false;
  constructor(
    public dialogRef: MatDialogRef<ArticleCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      paperId: number,
      paperStatus: number,
      projectId: number,
      status: number,
      userLevel?: string,
      paper: PaperItem
    },
    @Inject(CONFIG) private appRuntimeConfig: AppRuntimeConfig,
    private statisticService: StatisticService,
    private projectService: ProjectService,
    private builder: FormBuilder,
    private store: Store<AppState>,
    private spinnerService: NgxSpinnerService,
    public authService: AuthenticationService,
    private paperService: PaperService,
    private dialog: MatDialog,
  ) {
    this.handlePaper(this.data);
    this.titleScreeningComments = this.getScreeningComment(this.paperInfo, 1);
    this.fullTextScreeningComments = this.getScreeningComment(this.paperInfo, 2);
    if (this.paperInfo && this.paperInfo.pdf_files) {
      this.handlePdfView();
    }
    this.buildForm();
    // this.getPaperInfo();
    this.dialogRef.backdropClick().subscribe(() => this.close());
  }
  async handlePaper(injectedDate: any) {
    if (injectedDate.paper) {
      this.paperInfo = injectedDate.paper;
    } else {
       this.paperInfo = await  this.projectService.getPaperDetail(injectedDate.paperId).pipe(take(1)).toPromise();
    }
  }
  getScreeningComment(paper: PaperItem, status: number): PaperComment[] {
    if (paper) {
      if (paper.comments) {
        return paper.comments.filter(val => val.status === status);
      }
    }
    return [] ;
  }

  ngOnInit() {
    this.getTerms();
    this.noteControl = new FormControl(this.paperInfo.note);
    this.store.select(state => state.project.projectDetails).subscribe(
      detail => {
        if (detail) {
          this.isDropboxConnected = detail.owner.is_dropbox_connected;
        }
      }
    );
    this.paperService.setSelectedPaper$.subscribe(paper => {
      this.pdfSource = [];
      this.paperInfo = paper;
      this.titleScreeningComments = paper && paper.comments ? paper.comments.filter(val => val.status === 1 ) : [];
      this.fullTextScreeningComments = paper && paper.comments ? paper.comments.filter(val => val.status === 2 ) : [];
      this.handlePdfView();
      this.noteControl.patchValue(this.paperInfo.note);
    });
    this.subscriptions = [
      this.store.select(state => state.project.projectDetails).subscribe(
        (state) => {
          if (state && state.role_status === 'member') {
            this.isMember = true;
          }
        }
      )];
  }
  ngOnDestroy() {
    for (const item of this.subscriptions) {
      item.unsubscribe();
    }
  }
  getTerms() {
    const { projectId } = this.data;
    const stored = localStorage.getItem(`hubmeta-${this.data.status}-screening-terms-${projectId}`);
    if (stored && JSON.parse(stored) && JSON.parse(stored).terms) {
      this.terms = JSON.parse(stored).terms;
    }
  }

  buildForm() {
    this.commentForm = this.builder.group({
      var_type: [null, Validators.required],
      comment: ['', Validators.required],
      screeningReason: ['', Validators.required],
    });

  }
  paperTagChange(paper: PaperItem) {
    this.paperInfo = paper;
  }


  close() {
    this.dialogRef.close({ changed: this.changed, editedPaper: this.paperInfo });
  }

  handleClose(status) {
    this.changed = true;
    this.paperInfo = {...this.paperInfo, status };
    this.close();
  }
  handleCommentSubmission(editedPaper) {
    this.paperInfo = {...this.paperInfo, ...editedPaper};
    this.titleScreeningComments = this.paperInfo.comments.filter(val => val.status === 1);
    this.fullTextScreeningComments = this.paperInfo.comments.filter(val => val.status === 2);
    this.changed = true;
    // this.close();
  }
  fileDropped(files: NgxFileDropEntry[]) {
      // this.pdf = files;
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (file.type === 'application/pdf') {
          this.pdf = file;
          // this.importArticle.patchValue({ endnoteFile: file });
        } else {
          this.store.dispatch(
            showToastMessage({
              toast: {
                status: 'danger',
                message: 'Files with (.pdf) extension can be uploaded',
              },
            })
          );
        }
      });
  }
  cancelUpload() {
    this.pdf = null;
  }
  uploadPdf() {
    if (!this.pdf) {
      return;
    }
    const form = new FormData();
    form.append('file', this.pdf);
    this.projectService.editPaper(this.paperInfo.pk, form ).subscribe(
      response => {
        if (response) {
          if (response && response.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((response.loaded / response.total) * 100);
          } else if (response.status === 201 || response.status === 200) {
            this.submitted = false;
            if (response.body) {
              this.changed = true;
              this.paperInfo = {...this.paperInfo, pdf_files: response.body.pdf_files};
              if (this.paperInfo && this.paperInfo.pdf_files) {
                this.handlePdfView();
              }
            }
            this.store.dispatch(
              showToastMessage({
                toast: {
                  status: 'success',
                  message: 'pdf successfully uploaded',
                },
              })
            );

          }
        }

      }
    );
  }
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded === progressData.total) {
      this.spinnerService.hide('pdf-loader');

    }
  }
  handleSelectedPdf(pdfId: number ) {
    this.projectService.downloadPdfWithPdfId(pdfId).subscribe(
      response => {
        this.createPdf(response.bin_file);
      }
    );
  }
  handleRemovedPdf(pdfId: number) {
    this.projectService.DeletePdfWithPdfId(pdfId).subscribe(
      response => {
          const pdflist = this.paperInfo.pdf_files.filter(x => x.id !== pdfId);
          this.paperInfo = {...this.paperInfo, pdf_files: pdflist};
          this.handlePdfView();
      }
    );
  }
  handlePdfView() {
    this.spinnerService.show('pdf-loader');
    if (this.paperInfo  && this.paperInfo.pdf_files.length === 0) {
      return;
    }
    if (this.paperInfo.pdf_files[0].file_server === 1) {
      for (const pdf of this.paperInfo.pdf_files[0].file) {
        this.pdfSource.push({
          url: this.hostName + pdf,
          httpHeaders: {Authorization: 'jwt ' + this.authService.hubmetaToken}
        });
      }
    } else {
      this.handleSelectedPdf(this.paperInfo.pdf_files[0].id);
    }

  }
    // downloadPdfFromDropbox() {
    //   this.projectService.downloadPdfWithPaperId(this.paperInfo.pk).subscribe(
    //     response => {
    //       this.createPdf(response);
    //     });
    // }

  createPdf(response: any) {
    const binaryStrings = window.atob(response);
    const len = binaryStrings.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryStrings.charCodeAt(i);
    }
    this.pdfSource = [];
    this.pdfSource.push(bytes.buffer);
  }

  editPaperNote() {
    if (!this.noteControl.value) {
      return;
    }
    const form = new FormData();
    form.append('note', this.noteControl.value);
    this.projectService.editPaper(this.paperInfo.pk, form).subscribe(
      response => {
        if (response &&
          (response.status === 201 || response.status === 200) &&
           response.body) {
          this.store.dispatch(
            showToastMessage({
              toast: {
                status: 'success',
                message: `note successfully ${ this.paperInfo.note ? 'edited' : 'added'}.`,
              },
            })
          );
          this.changed = true;
          this.paperInfo = {...this.paperInfo, note: response.body.note};


        }

      }
    );
  }
  goForwardForPaperReview(prevFlag: boolean) {
    this.paperService.announceGoForwardOfPapersReview(prevFlag, this.paperInfo);
  }
  handlePostChangeStatusAction(newPaper: PaperItem) {
    const type = this.data.status === 1 ? 'title_screening' : 'fulltext_screening';
    this.paperInfo = {...this.paperInfo, ...newPaper };
    this.store.dispatch(loadProjectStatistics({projectId: +this.data.projectId, section: type}));
  }

  paperAssignmentChange(assignments: any[]) {
    let stage;
    switch (this.data.status) {
      case 1:
        stage = 4;
        break;
      case 2:
        stage = 6;
        break;

      default:
        stage = 7;
        break;
    }
    assignments = assignments.filter(item => item.stage === stage);
    this.paperInfo.assignments = assignments;
  }

}
