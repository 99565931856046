import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbIconModule,
  NbMenuModule,
  NbToastrModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { CoreModule } from '@core/core.module';
import { DashboardLayoutComponent } from '@layout/dashboard-layout/dashboard-layout.component';
import { ProjectLayoutComponent } from '@layout/project-layout/project-layout.component';
import { AuthenticationLayoutComponent } from '@layout/authentication-layout/authentication-layout.component';
import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './shared/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@env';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { ProfileEffects } from '@modules/profile/state/profile.effects';
import { AuthGuard } from '@core/guard/auth.guard';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@core/interceptor/httpconfig.interceptor';
import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AddNewWorkspaceComponent } from './modules/project/components/add-new-workspace/add-new-workspace.component';
import { InviteMemberComponent } from './modules/project/components/setting-section/invite-member/invite-member.component';
import { NewProjectComponent } from './modules/dashboard/components/new-project/new-project.component';
import { ArticleCommentsComponent } from '@modules/project/components/screening/article-comments/article-comments.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SentryErrorHandler } from '@core/handler/errorHandler';
import { BrowserModalComponent } from '@shared/components/browser-modal/browser-modal.component';
import { ManualAddPaperComponent } from '@modules/project/components/import-articles-section/manual-add-paper/manual-add-paper.component';
import { ImportNewFileComponent } from '@modules/project/components/import-articles-section/import-new-file/import-new-file.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaperFilterModule } from '@modules/project/modules';
import { GtagModule } from 'angular-gtag';


const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.google_client_id)
  }
  // {
  //   id: FacebookLoginProvider.PROVIDER_ID,
  //   provider: new FacebookLoginProvider(environment.facebook_id)
  // }
]);

export function provideConfig() {
  return config;
}


@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    ProjectLayoutComponent,
    AuthenticationLayoutComponent,
    AddNewWorkspaceComponent,
    ManualAddPaperComponent,
    ImportNewFileComponent,
    InviteMemberComponent,
    NewProjectComponent,
    BrowserModalComponent,
    ArticleCommentsComponent  // this components is part of project module why import here ?
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    CoreModule.forRoot(),
    SharedModule,
    PaperFilterModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([AppEffects, ProfileEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, name: 'Hub Meta Devtools' }),
    NgxSpinnerModule,
    SocialLoginModule,
    NgxPaginationModule,
    BrowserModule,
    PdfViewerModule,
    NgxSkeletonLoaderModule.forRoot(),
    GtagModule.forRoot({ trackingId: 'G-7RWQE48C6L', trackPageviews: true })
  ],
  providers: [

    AuthGuard,
    NgxSpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddNewWorkspaceComponent,
    ManualAddPaperComponent,
    ImportNewFileComponent,
    BrowserModalComponent,
    InviteMemberComponent,
    NewProjectComponent,
    ArticleCommentsComponent,

  ]
})
export class AppModule { }
