import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-show-assignment',
  templateUrl: './show-assignment.component.html',
  styleUrls: ['./show-assignment.component.scss']
})
export class ShowAssignmentComponent implements OnInit, OnChanges {
  @Input()assignments = [];
  @Input()display = 'inline';
  @Input()importer = null;
  reviwer = [];
  researcher = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.reviwer = this.assignments.filter(item => item.role === 1);
    this.researcher = this.assignments.filter(item => item.role === 2);
  }
  ngOnInit() {

  }
}
