import {Component, OnInit, OnDestroy} from '@angular/core';
import {NbComponentStatus, NbSidebarService} from '@nebular/theme';
import {ProjectMenu} from '@shared/data/menu';
import {ActivatedRoute, Router} from '@angular/router';
import {
  resetProjectState,
  resetAlert,
  loadMemberList,
  loadProject,
  loadArticleList,
  getProjectPermissions,
  loadProjectRanges,
  loadProjectVariables
} from '@modules/project/state/project.actions';
import * as profileActions from '@modules/profile/state/profile.actions';
import {Store} from '@ngrx/store';
import {AppState} from '@shared/schema/app.state';
import {CorrelationVariable, ProjectDetails, ProjectVariable} from '@shared/schema/project';
import {ModalService} from '@core/service/modal.service';
import {LoadVariableService} from '@core/service/load-variables.service';
import {InviteMemberComponent} from '@modules/project/components/setting-section/invite-member/invite-member.component';
import {MatDialog} from '@angular/material';
import {AddNewWorkspaceComponent} from '@modules/project/components/add-new-workspace/add-new-workspace.component';
import {NewProjectComponent} from '@modules/dashboard/components/new-project/new-project.component';
import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
import {Subscription} from 'rxjs';
import {ArticleCommentsComponent} from '@modules/project/components/screening/article-comments/article-comments.component';
import {ImportNewFileComponent} from '@modules/project/components/import-articles-section/import-new-file/import-new-file.component';
import {ManualAddPaperComponent} from '@modules/project/components/import-articles-section/manual-add-paper/manual-add-paper.component';
import {LoadingService} from '@core/service/loading.service';

const DEFAULT_PAGE_SIZE = 100;

@Component({
  selector: 'app-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss']
})
export class ProjectLayoutComponent implements OnInit, OnDestroy {
  subscription: Subscription[];
  menu = ProjectMenu;
  project: ProjectDetails;
  projectId: number;
  // projectPermissions: PermissionItem[];
  openEditDialog = false;
  selectedWorkspace: string;
  // visualizeData: ProjectVariable[];
  message = '';

  constructor(
    private sidebarService: NbSidebarService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private modalService: ModalService,
    private dialog: MatDialog,
    private loadVariableService: LoadVariableService,
    private toastrService: NbToastrService,
    private loadingService: LoadingService
  ) {
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
  }

  ngOnInit() {
    this.store.dispatch(resetProjectState());
    this.subscription = [];
    this.store.dispatch(profileActions.loadProfile());
    if (this.projectId) {
      this.loadProjectDetail();

      this.subscription = [
        this.loadingService.setLoadingMessage.subscribe(message => {
          this.message = message;
        }),
        this.store.select(state => state.project.projectDetails).subscribe(
          (state) => {
            this.project = state;
            if (this.project && this.project.role_status === 'member') {
              this.loadProjectRanges();
              this.getProjectPermissions();
            }
          }
        ),
        // this.store
        // .select((state) => state.project.projectVariables)
        // .subscribe((projectVariables) => {
        //   if (projectVariables) {
        //     if (projectVariables.length > 0) {
        //       this.visualizeData = projectVariables;
        //     }
        //   }
        // }),
        this.store.select(state => state.project.successAlertMessage).subscribe(
          (state) => {
            if (state) {
              this.showSuccessToast(state);
              this.store.dispatch(resetAlert());
            }
          }
        ),
        this.store.select(state => state.project.toast).subscribe(
          (toast) => {
            if (toast.status) {
              this.showToast(toast.status, toast.message, toast.title);
              this.store.dispatch(resetAlert());
            }
          }
        ),
        this.store.select(state => state.project.variableDetail).subscribe(
          (state) => {
            if (state) {
              if (this.openEditDialog) {
                this.openEditVariableModal(this.selectedWorkspace, state);
                this.openEditDialog = false;
              }
            }
          }
        )
      ];
    }
    this.subscription = [
      ...this.subscription,
      this.modalService.openAddMemberModal$.subscribe(
        response => {
          this.inviteMember();
        }
      ),
      this.modalService.openVariableModal$.subscribe(
        response => {
          if (response.workspaceType.toLowerCase().indexOf('group construct') === -1 &&
            response.workspaceType.toLowerCase().indexOf('moderator variable') === -1 &&
            response.workspaceType.toLowerCase().indexOf('citation') === -1) {
          }
          this.selectedWorkspace = response.workspaceType;
          this.openVariableModal(response);
        }
      ),
      this.modalService.openAddProjectModal$.subscribe(
        response => {
          this.addNewProject();
        }
      ),
      this.modalService.openScreeningModal$.subscribe(
        response => {
          this.openShowCommentsModal(response.screeningType, response.paperStatus, response.paperId);
        }
      ),
      this.modalService.openAddNewFileModal$.subscribe(
        response => {
          this.openAddNewFileModal();
        }
      ),
      this.modalService.openAddManualPaperModal$.subscribe(
        response => {
          this.openAddManualPaperModal();
        }
      )
    ];
    if (
      document.getElementsByTagName('body')[0].classList.contains('compacted')
    ) {
      document.getElementsByTagName('body')[0].classList.remove('compacted');
      document.getElementsByTagName('body')[0].classList.add('expanded');
    }
  }

  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }

  toggle() {
    if (
      document.getElementsByTagName('body')[0].classList.contains('compacted')
    ) {
      document.getElementsByTagName('body')[0].classList.remove('compacted');
      document.getElementsByTagName('body')[0].classList.add('expanded');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('expanded');
      document.getElementsByTagName('body')[0].classList.add('compacted');
    }
    this.sidebarService.toggle(true, 'left');
    return false;
  }

  inviteMember(): void {
    const dialogRef = this.dialog.open(InviteMemberComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {
        projectId: this.projectId,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.added) {
        if (this.router.url.indexOf('member') !== -1) {
          this.getMemberList(this.projectId);
        }
      }
    });
  }

  getMemberList(projectId: number, page = 1, pageSize = 10) {
    this.store.dispatch(loadMemberList({projectId, page, page_size: pageSize}));
  }

  openVariableModal(response) {
    if (response.editFlag) {
      this.openEditDialog = true;
    } else {
      this.openAddVariableModal(response.workspaceType, response.variable);
    }
  }

  openAddVariableModal(workspaceType: string, variable?: CorrelationVariable): void {
    const dialogRef = this.dialog.open(AddNewWorkspaceComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {
        workspaceType,
        actionTitle: 'Add',
        variable,
        projectId: +this.route.snapshot.paramMap.get('projectId')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.router.url.indexOf('taxonomy') !== -1) {
          this.loadVariableService.announceLoadVariable(workspaceType, null);
        }
        if (workspaceType.toLowerCase().indexOf('citation') === -1 &&
          workspaceType.toLowerCase().indexOf('variable set') === -1) {
          this.store.dispatch(loadProjectVariables({projectId: this.projectId}));
          this.loadProjectDetail();
        }
      }
    });
  }

  openEditVariableModal(workspaceType, variable) {
    const dialogRef = this.dialog.open(AddNewWorkspaceComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {
        workspaceType,
        actionTitle: 'Edit',
        projectId: +this.route.snapshot.paramMap.get('projectId'),
        variable
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.router.url.indexOf('workspace') !== -1) {
          this.loadVariableService.announceLoadVariable(workspaceType, null);
        }
        this.store.dispatch(loadProjectVariables({projectId: this.projectId}));
      }
    });
  }

  loadProjectDetail() {
    this.store.dispatch(
      loadProject({projectId: this.projectId})
    );
  }

  loadProjectRanges() {
    this.store.dispatch(
      loadProjectRanges({
        projectId: +this.projectId
      })
    );
  }

  getProjectPermissions() {
    this.store.dispatch(getProjectPermissions({
        projectId: this.projectId
      }
    ));
  }

  showSuccessToast(message: string, title?: string) {
    this.toastrService.show(message, title ? title : '', {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      status: 'success',
      duration: 3000,
    });
  }

  showToast(status: NbComponentStatus, message: string, title?: string) {
    this.toastrService.show(message, title ? title : '', {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      status,
      duration: 3000,
      toastClass: status === 'danger' ? 'error-log' : 'custom-toast'
    });
  }

  addNewProject(): void {
    const dialogRef = this.dialog.open(NewProjectComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.create) {
        this.showSuccessToast('Project Successfully Created.');
      }
    });
  }

  openShowCommentsModal(screeningType: number, paperStatus: number, paperId: number) {
    const dialogRef = this.dialog.open(ArticleCommentsComponent, {
      width: '70%',
      maxHeight: '90vh',
      minWidth: '600px',
      data: {
        paperId,
        paperStatus,
        projectId: this.projectId,
        status: screeningType
      },
      panelClass: 'article-comments-dialog'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.changed) {
        if (this.router.url.indexOf('full-text-screening') !== -1 || this.router.url.indexOf('title-screening') !== -1) {
          this.loadVariableService.announceLoadScreeningData();
        }
      }
    });
  }

  openAddNewFileModal() {
    const dialogRef = this.dialog.open(ImportNewFileComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {projectId: this.projectId},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          loadArticleList({
            params: {
              project: this.projectId.toString(),
              page_size: '50'
            },
          })
        );
        this.store.dispatch(
          loadProject({projectId: this.projectId})
        );
      }
    });
  }

  openAddManualPaperModal() {
    const dialogRef = this.dialog.open(ManualAddPaperComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {projectId: this.projectId},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          loadProject({projectId: this.projectId})
        );
        if (result.status === 0 && this.router.url.indexOf('import-articles') !== -1) {
          this.store.dispatch(
            loadArticleList({
              params: {
                project: this.projectId.toString(),
                page_size: '50'
              },
            })
          );
        } else if (result.status === 4 && this.router.url.indexOf('title-screening') !== -1) {

        } else if (result.status === 6 && this.router.url.indexOf('full-text-screening') !== -1) {

        } else if (result.status === 7 && this.router.url.indexOf('data-entry') !== -1) {

        }

      }
    });
  }

}
