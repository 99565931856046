import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserInfo } from '@shared/schema/profile';

@Component({
  selector: 'app-filter-user-comment-dialog',
  templateUrl: './filter-user-comment-dialog.component.html',
  styleUrls: ['./filter-user-comment-dialog.component.scss']
})
export class FilterUserCommentDialogComponent implements OnInit {
  form: FormGroup;
  userCommentTypeOptions:
    { name: string; value: number }[] = [
      {
        name: 'Yes',
        value: 1,
      },
      {
        name: 'No',
        value: 2,
      },
      {
        name: 'Maybe',
        value: 3,
      },
    ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      userComments: {userId: number, value: number[]}[],
      reviewers: UserInfo[]
    },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FilterUserCommentDialogComponent>,
  ) { }

  ngOnInit() {
    this.createForm();
    if (this.data.userComments.length > 0) {
      this.fillData(this.data.userComments);
    } else {
      this.creteNewUserComment();
    }
  }


  createForm() {
    this.form = this.fb.group({
      userComments: this.fb.array([])
    });
  }

  fillData(userComments: Array<any>) {
    userComments.forEach((item, index) => {
      this.creteNewUserComment(item);
    });
  }

  get userComments(): FormArray {
    return this.form.get('userComments') as FormArray;
  }

  creteNewUserComment(item?: any) {
    const newUserComment = this.fb.group({
      userId: [item ? item.userId : null, []],
      value: [item ? item.value : [], []]
    });
    this.userComments.push(newUserComment);
  }

  deleteRow(index: number) {
    this.userComments.removeAt(index);
  }

  handleUserCommnet() {
    const usercomments = this.removeEmptyRow();
    this.dialogRef.close(usercomments);
  }

  removeEmptyRow(): Array<any> {
    return this.userComments.value.map(item => {
      if (item.userId && item.value.length > 0) {
        return item;
      }
      return null;
    })
    .filter(item => item !== null);
  }
}
