import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProjectService } from '@core/service/project.service';
import { PaperItem, PaperStatusType, Tag } from '@shared/schema/project';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import * as projectAction from '@modules/project/state/project.actions';
import { linkRegex, yearRegex } from '@shared/utils/regex';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { HttpEventType } from '@angular/common/http';
import { guides } from '@shared/data/guides';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as projectActions from '@modules/project/state/project.actions';

@Component({
  selector: 'app-manual-add-paper',
  templateUrl: './manual-add-paper.component.html',
  styleUrls: ['./manual-add-paper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualAddPaperComponent implements OnInit, OnDestroy {
  importArticle: FormGroup;
  subscription: Subscription[];
  tags: Tag[] = [];
  tagsLoading = false;
  selectedTagList = [];
  tagsId: number[];
  tagTermToSearch = '';
  tagSubmited = false;
  statusList = [
    // {
    //   id: PaperStatusType['reject in deduplication'],
    //   title: 'Recently Added'
    // },
    {
      id: PaperStatusType['title screening'],
      title: 'Title Screening',
    },
    {
      id: PaperStatusType['full text screening'],
      title: 'Full Text Screening',
    },
    {
      id: PaperStatusType['data entry'],
      title: 'Data Entry',
    },
  ];
  submitted = false;
  progress: number;
  isDropboxConnected = false;
  guide = {
    overall: guides.importArticle.manualImport
  };
  private pendingRequests$ = new Subject<void>();
  doiNotFound = false;
  doiFields = ['name', 'year', 'author', 'link', 'abstract', 'journal'];
  deletedPaper?: number;

  constructor(
    public dialogRef: MatDialogRef<ManualAddPaperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: number, selectedPaper: PaperItem },
    private builder: FormBuilder,
    private projectService: ProjectService,
    private store: Store<AppState>,
    private toastrService: NbToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }

  ngOnInit() {
    this.buildForm(this.data.selectedPaper);
    this.selectedTagList = this.importArticle.get('tag').value ?
     Array.from(this.importArticle.get('tag').value).map( (x: Tag) => x.name) :
      [];
    this.subscription = [
      this.store.select(state => state.project.projectDetails).subscribe(
        detail => {
          if (detail && detail.owner && detail.owner.is_dropbox_connected) {
            this.isDropboxConnected = detail.owner.is_dropbox_connected;
          }
        }
      ),
      this.store.select(store => (store.project.tagList)).subscribe(
        tagList => {
          if (this.tagTermToSearch) {
            if (tagList) {
              this.tags = tagList.data;
              this.tagsLoading = false;
            }
          }

        }
      ),
      this.store.select(store => (store.project.tagsId)).subscribe(
        tagsId => {
          if (tagsId) {
            if (this.tagSubmited) {
              this.tagsId = tagsId;
              this.import();
              this.tagSubmited = false;
            }

          }
        }
      ),
    ];
  }
  buildForm(item?: PaperItem) {
    this.importArticle = this.builder.group({
      name    : [item ? item.name : '', Validators.required],
      author  : [item ? item.author : '', []],
      journal : [item ? item.journal : '', []],
      link    : [item ? item.link : '', []],
      doi     : [null],
      abstract: [item ? item.abstract : '', []],
      year    : [item ? item.year : null, [Validators.required, Validators.pattern(yearRegex)]],
      status  : [item ? item.status : null, Validators.required],
      file    : [item ? item.pdf_files[0] : null],
      tag     : [item ? item.tags : null]
    });
    this.importArticle.controls.doi.valueChanges.subscribe(val => {
      if (val) {
        let timeout;
        if (timeout) {
          clearTimeout();
        }
        if (val.length > 5) {
          timeout = setTimeout(() => {
            this.handleSearchDOI();
          }, 2000);
        }

      }
    });
  }

  import() {
    // [disabled]='!importArticle.invalid'
    if (this.importArticle.invalid) {
      return;
    }
    this.submitted = true;
    const formData = new FormData();
    formData.append('project', this.data.projectId.toString());

    for (const val in this.importArticle.value) {
      if (val && val !== 'doi' && val !== 'tag') {
        formData.append(val, this.importArticle.value[val]);
      }
    }

    if (!(this.importArticle.get('file').value instanceof File)) {
      formData.delete('file');
    }
    if (this.tagsId) {
      for (const tag of this.tagsId) {
        formData.append('tags', tag.toString());
      }
    }
    this.checkForDeleteFile();
    if (this.data.selectedPaper) {
      this.projectService.updatePaper(this.data.selectedPaper.id, formData).subscribe(
        (res) => {
          if (res) {
            if (res && res.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((res.loaded / res.total) * 100);
            } else if (res.status === 200) {
              this.submitted = false;
              this.store.dispatch(
                projectAction.showToastMessage({
                  toast: {
                    status: 'success',
                    message: 'paper successfully Update',
                  },
                })
              );
              this.dialogRef.close(res);

            }
          }
        },
        (error) => {
          this.submitted = false;
        }
      );
    } else {
      this.projectService.createPaper(formData).subscribe(
        (res) => {
          if (res) {
            if (res && res.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((res.loaded / res.total) * 100);
            } else if (res.status === 201 &&  res.type === HttpEventType.Response) {
              this.submitted = false;
              this.store.dispatch(
                projectAction.showToastMessage({
                  toast: {
                    status: 'success',
                    message: 'paper successfully added',
                  },
                })
              );
              this.dialogRef.close(res);

            }
          }
        },
        (error) => {
          this.submitted = false;
        }
      );
    }
  }
  checkForDeleteFile() {
    if (this.deletedPaper) {
      this.projectService.DeletePdfWithPdfId(this.deletedPaper).subscribe(
        response => { }
      );
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
  get pdfFile() {
    return this.importArticle.get('file') as FormControl;
  }
  fileDropped(files: NgxFileDropEntry[]) {
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((selectedFile: File) => {
      if (selectedFile.name.indexOf('pdf') >= 0) {
        this.importArticle.patchValue({ file: selectedFile });
      } else {
        this.showToast('Only this extension can be uploaded(.pdf)');
      }
    });
  }
  showToast(text) {
    this.toastrService.show(text, 'Invalid Extensions', {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      status: 'danger',
      duration: 3000,
    });
  }
  handleSearchDOI() {
    const { doi } = this.importArticle.value;
    this.spinner.show('doi-loader');
    this.doiNotFound = false;
    this.pendingRequests$.next();
    this.projectService.searchDOI({ doi }).pipe(takeUntil(this.pendingRequests$.asObservable())).subscribe(res => {
      this.spinner.hide('doi-loader');
      for (const field of this.doiFields) {
        this.importArticle.controls[field].setValue(res[field]);
      }
    }, error => {
      this.spinner.hide('doi-loader');
      if (error.status === 404) {
        this.doiNotFound = true;
        for (const field of this.doiFields) {
          this.importArticle.controls[field].setValue('');
        }

      }
    });
  }
  handleSelectedPdf(pdfId: number) {
    // Do nothing
  }
  handleRemovedPdf(pdfId: number) {
    const pdflist = this.data.selectedPaper.pdf_files.filter(x => x.id !== pdfId);
    this.data.selectedPaper = { ...this.data.selectedPaper, pdf_files: pdflist };
    this.importArticle.get('file').setValue(null);
    this.deletedPaper = pdfId;
  }

  /**********************
   * TAGS
   *********************/
  handleSearchTags(searchTerm) {
    let timer;
    if (searchTerm.term) {
      if (searchTerm.term.length > 2) {
        this.tagsLoading = true;
        if (timer) {
          clearTimeout(timer);
        }
        this.tagTermToSearch = searchTerm.term;
        timer = setTimeout(() => {
          if (searchTerm.term) {
            this.searchTags(searchTerm.term);
          }
        }, 1500);
      }
    } else {
      this.tagTermToSearch = '';
    }

  }
  searchTags(searchTerm: string) {
    if (this.tagTermToSearch) {
      this.store.dispatch(
        projectActions.getTags({
          queryParams: {
            name__icontains: searchTerm,
            page_size: 20,
            page: 1,
            project: this.data.projectId.toString(),
          },
          reportProgress: true
        })
      );
    }

  }
  addTag(value) {
    if (value) {
      if (value.name) {
        if (value.name.length < 3) {
          return;
        }
        if (!this.selectedTagList.includes(value.name)) {
          this.selectedTagList.push(value.name);
        }
      }
    }
  }
  removeTag(event) {
    const value = event.value;
    this.selectedTagList = this.selectedTagList.filter(val => val !== value.name);
  }

  submitTags() {
    this.tagSubmited = true;
    this.store.dispatch(
      projectActions.createTags({
        projectId: this.data.projectId,
        tags: this.selectedTagList,
      })
    );
  }
  handlePaper() {
    if (this.selectedTagList.length > 0) {
      this.submitTags();
    } else {
      this.import();
    }
  }
}
