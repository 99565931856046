import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (args && args[0] === 'time') {
      return moment(new Date(value)).format('YYYY/MM/DD hh:mm');
    } else {
      return moment(new Date(value)).format('YYYY/MM/DD');

    }
  }

}
