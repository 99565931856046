import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenInterceptor } from '@core/interceptor/httpconfig.interceptor';

@Injectable()
export class LoadingService {
    constructor(
        private spinner: NgxSpinnerService,
    ) { }
    spinnerPendingList = [];
    manualLoading = false;

    private setLoadingMessageSubject = new Subject<string>();

    setLoadingMessage = this.setLoadingMessageSubject.asObservable();

    announceSetLoadingMessage(message: string) {
        this.setLoadingMessageSubject.next(message);
    }

    announceRemoveLoadingMessage() {
        this.setLoadingMessageSubject.next('');
    }

    show(url?: string) {
        if (url) {
            this.spinnerPendingList.push(url);
        } else {
            this.manualLoading = true;
        }
        this.spinner.show();
    }

    solvePendigReq(url: string) {
        setTimeout(() => {
            this.spinnerPendingList = this.spinnerPendingList.filter(item => item !== url);
            if (!this.manualLoading) {
                this.checkToHide();
            }
        }, 250);
    }

    hide() {
        this.manualLoading = false;
        this.checkToHide();
    }

    checkToHide() {
        if (this.spinnerPendingList.length === 0) {
            this.spinner.hide();
        }
    }
}
