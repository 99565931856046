import {NgModule} from '@angular/core';
import {
  NbAccordionModule,
  NbCardModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSidebarModule, NbSpinnerModule,
  NbToastrModule,
  NbTooltipModule,
  NbListModule,
  NbWindowModule,
  NbPopoverModule
} from '@nebular/theme';


@NgModule({
  imports: [
    NbLayoutModule,
    NbSidebarModule,
    NbIconModule,
    NbProgressBarModule,
    NbTooltipModule,
    NbMenuModule,
    NbCardModule,
    NbAccordionModule,
    NbRadioModule,
    NbToastrModule.forRoot(),
    NbSpinnerModule,
    NbListModule,
    NbWindowModule,
    NbPopoverModule
  ],
  exports: [
    NbLayoutModule,
    NbSidebarModule,
    NbIconModule,
    NbProgressBarModule,
    NbTooltipModule,
    NbMenuModule,
    NbCardModule,
    NbAccordionModule,
    NbRadioModule,
    NbToastrModule,
    NbSpinnerModule,
    NbListModule,
    NbWindowModule,
    NbPopoverModule
  ],
  declarations: [],
  providers: [],
})
export class NebularModule {
}
