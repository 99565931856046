import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longitudinal'
})
export class LongitudinalPipe implements PipeTransform {
  timeOptions = [
    {label: 'None', value: null},
    {label: 'Before', value: 1},
    {label: 'Now', value: 2},
    {label: 'After', value: 3},
  ];
  transform(value: number, ...args: any[]): string {
    if (value) {
      const foundTime = this.timeOptions.find(val => val.value === value);
      if (foundTime) {
        return foundTime.label;
      }
    }
    return null;
  }

}
