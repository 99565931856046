export const environment = {
  type:"production",  // or "development" of "local"
  production: true,
  google_client_id: '355490807214-83nap2fpt6adnt4sivi78vjses1m9d56.apps.googleusercontent.com',
  facebook_id: '1460509394113402',
  microsoft_client_id: '3daa15b4-dff5-4423-9bd9-b4ab0772b63f',
  linked_in_client_id: '77acv9pq06bjk3',
  sentry_dsn: 'https://9d75f82e53bc46c0b31199822e3cc482@o205259.ingest.sentry.io/3587926',
  libmeta_url: 'https://lib.hubmeta.com',
  api_url: 'https://api.hubmeta.com/api/v1',
  host_name: 'https://api.hubmeta.com/',
  ga: 'G-NY9GM1L4W4',
  dropbox_client_id: 'j0jyr5pgftitzwb',
  recaptcha: { 
        siteKey: '6LdYBsMgAAAAANLCU4Kw0nO8BR3nxmiIlp0L6xaA', 
  }

};