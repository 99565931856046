import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as _ from 'lodash';
import {Breadcrumb} from '@shared/schema/breadcrumb';
import {Store} from '@ngrx/store';
import {AppState} from '@shared/schema/app.state';
import { Subscription } from 'rxjs';
import { PaperItem, ProjectDetails, TaskStatus } from '@shared/schema/project';
import { ProjectService } from '@core/service/project.service';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy, OnInit {
  routeSubscription: Subscription;
  projectDetailsSubscription: Subscription;
  @Input() backUrl: string;
  @Input() customTitle: string;
  @Input() libmeta: boolean;
  @Input() paper: PaperItem;
  @Input() isAnalysisPage: boolean;
  @Output() downloadPdf = new EventEmitter();
  breadcrumbs: Breadcrumb[];
  textValue: string;
  modal;
  valid = false;
  currentRoute;
  libmetaProgress = null;
  projectId;
  projectDetail: ProjectDetails;
  compositeProgress: number;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store<AppState>,
    private service: ProjectService,
    private toastrService: NbToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.routeSubscription = this.router.events
      .subscribe(event => {  // note, we don't use event
        if ( event instanceof NavigationEnd) {
          this.breadcrumbs = [];
          this.currentRoute = this.activeRoute.root;
          let url = '';
          do {
            const childrenRoutes = this.currentRoute.children;
            this.currentRoute = null;
            childrenRoutes.forEach( (route) => {
              if (route.outlet === 'primary') {
                const routeSnapshot = route.snapshot;
                const path = routeSnapshot.url.map(segment => segment.path);
                if (path.length > 0) {
                  url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
                  if (path[0] === 'project') {
                    if (path[1]) {
                      this.projectId = +path[1];
                    }
                  }

                }
                if (route.snapshot.data.breadcrumb !== undefined) {
                  let status = true;
                  if (route.snapshot.data.status !== undefined) {
                    status = route.snapshot.data.status;
                  }

                  this.breadcrumbs.push({
                    label: route.snapshot.data.breadcrumb,
                    status,
                    url,
                    guide: route.snapshot.data.guide
                  });
                }
                this.currentRoute = route;
              }
            });
          } while (this.currentRoute);
        }

      });


  }
  ngOnInit() {
    if (this.isAnalysisPage) {
      this.store.select(state => state.project.projectDetails).subscribe(details => {
        if (details) {
          this.projectDetail = details;
          if (this.projectDetail && this.projectDetail.composite_task_id) {
            this.getTaskStatus(this.projectDetail.composite_task_id);
          }
        }
      });
    }
  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    if (this.projectDetailsSubscription) {
      this.projectDetailsSubscription.unsubscribe();
    }
  }
  filter(breadCrumbs) {
    return _.uniqBy(breadCrumbs, 'label');
  }
  Close() {
    this.modal.close();
  }
  Navigate(url: string) {
    this.projectDetailsSubscription = this.store.select(state => state.project.projectDetails).subscribe((state) => {
      if (state && state.pk) {
        if (!url || url === `/project/${state.pk}`) {
          this.router.navigate([`/project/${state.pk}/details`]).then();
          return;
        }
        this.router.navigate([url]).then();
      }
    });
  }
  refreshLibmetaSync() {
    this.service.syncWithLibmeta({project: this.projectId, id: this.paper.id.toString()}).subscribe(
      response => {
        if (response && response.task_id) {
          this.getTaskStatus(response.task_id, true);
        }

      },
      error => {
        console.log(error);
      }
    );
  }
  getTaskStatus(taskId: string, isLibmeta?: boolean) {
    if (!taskId) {
      return;
    }
    if (this.libmetaProgress === null) {
      this.libmetaProgress = 1;
    }
    let getTaskStatus = null;
    this.service.getTaskStatus(taskId).subscribe((response) => {
      if (response.status === TaskStatus.PROGRESS || response.status === TaskStatus.PENDING) {
        getTaskStatus = 0;
        if (isLibmeta) {
          this.libmetaProgress = 0;
        } else {
          this.spinner.show('compositePending');
          this.compositeProgress = 0;
        }
        if (response.result) {
          if (isLibmeta) {
            this.libmetaProgress = response.result.current;
          } else {
            this.compositeProgress = response.result.current;
          }
        }
        setTimeout(() => {
          this.getTaskStatus(taskId, isLibmeta);
        }, 3000);
      } else if (response.status === TaskStatus.SUCCESS) {
        if (isLibmeta) {
          if (response.result && response.result.count) {
            this.handleLibmetaFoundSuccess();
            this.libmetaProgress = 100;
          } else {
            this.handleLibmetaNotFound();

          }
        } else {
          this.compositeProgress = 100;
          this.handleCompositeSuccess(getTaskStatus);
        }

      } else if (response.status === TaskStatus.FAILURE) {
        if (isLibmeta) {
          this.handleLibmetaSearchError();
        } else {
          this.handleCompositeError();
        }
      }

    });
  }

  handleDownloadPdf() {
    this.downloadPdf.emit();
  }
  handleLibmetaFoundSuccess() {
    this.paper.libmeta_exist = true;
    this.paper = {...this.paper, libmeta_last_update: new Date().toString()};
    const alert =  'Paper was verified at Libmeta.';
    this.toastrService.show(
      alert,
      'Success',
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'success',
        duration: 4000,
      });
  }
  handleLibmetaNotFound() {
    this.toastrService.show(
      'Paper was not found at Libmeta',
      'Success',
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'success',
        duration: 4000,
      });
  }
  handleLibmetaSearchError() {
    this.toastrService.show(
      'Libmeta search error',
      'Error',
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'danger',
        duration: 2000,
        toastClass: 'error-log'
      });
  }
  handleCompositeSuccess(isTaskPending?) {
    if (isTaskPending === 0) {
      this.toastrService.show(
        'Composite Task was done',
        'Success',
        {
          position: NbGlobalPhysicalPosition.TOP_RIGHT,
          status: 'success',
          duration: 4000,
        });
    }
  }

  handleCompositeError() {
    this.toastrService.show(
      'Error in getting composite task status',
      'Success',
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'danger',
        duration: 4000,
        toastClass: 'error-log'
      });
  }
  doiLink(link: string): boolean {
    return link.includes('https://doi.org/');
  }
}
