export const SOURCES = [
  {
      value: 'ACM Digital Library',
      name: 'ACM Digital Library'
  },
  {
      value: 'AIAA',
      name: 'AIAA'
  },
  {
      value: 'American Chemical Society',
      name: 'American Chemical Society'
  },
  {
      value: 'Annual Reviews',
      name: 'Annual Reviews'
  },
  {
      value: 'APA PsycNET',
      name: 'APA PsycNET'
  },
  {
      value: 'APS Journals',
      name: 'APS Journals'
  },
  {
      value: 'Artstor',
      name: 'Artstor'
  },
  {
      value: 'ASCE Library',
      name: 'ASCE Library'
  },
  {
      value: 'ASME Digital Collection',
      name: 'ASME Digital Collection'
  },
  {
      value: 'ASTM Compass',
      name: 'ASTM Compass'
  },
  {
      value: 'Begell House Digital Library',
      name: 'Begell House Digital Library'
  },
  {
      value: 'Cambridge Core',
      name: 'Cambridge Core'
  },
  {
      value: 'CNKI',
      name: 'CNKI'
  },
  {
      value: 'Dalloz',
      name: 'Dalloz'
  },
  {
      value: 'Ebook Central',
      name: 'Ebook Central'
  },
  {
      value: 'EBSCOhost',
      name: 'EBSCOhost'
  },
  {
      value: 'Emerald Insight',
      name: 'Emerald Insight'
  },
  {
      value: 'ESI',
      name: 'ESI'
  },
  {
      value: 'Existing Meta Analysis/Systematic Review',
      name: 'Existing Meta Analysis/Systematic Review'
  },
  {
      value: 'GeoScienceWorld',
      name: 'GeoScienceWorld'
  },
  {
      value: 'Google Scholar',
      name: 'Google Scholar'
  },
  {
      value: 'HeinOnline',
      name: 'HeinOnline'
  },
  {
      value: 'Human Kinetics',
      name: 'Human Kinetics'
  },
  {
      value: 'IEEE Xplore',
      name: 'IEEE Xplore'
  },
  {
      value: 'IMF eLibrary Statistics',
      name: 'IMF eLibrary Statistics'
  },
  {
      value: 'INFORMS PubsOnline',
      name: 'INFORMS PubsOnline'
  },
  {
      value: 'IOPscience',
      name: 'IOPscience'
  },
  {
      value: 'JCR',
      name: 'JCR'
  },
  {
      value: 'JSTOR',
      name: 'JSTOR'
  },
  {
      value: 'Kluwer Law',
      name: 'Kluwer Law'
  },
  {
      value: 'LexisNexis',
      name: 'LexisNexis'
  },
  {
      value: 'Mary Ann Liebert',
      name: 'Mary Ann Liebert'
  },
  {
      value: 'MathSciNet',
      name: 'MathSciNet'
  },
  {
      value: 'Max Planck Encyclopedia of Public International Law',
      name: 'Max Planck Encyclopedia of Public International Law'
  },
  {
      value: 'Nature Publishing',
      name: 'Nature Publishing'
  },
  {
      value: 'OSA_Publishing',
      name: 'OSA_Publishing'
  },
  {
      value: 'Ovid HTTPS (updated 20190128)',
      name: 'Ovid HTTPS (updated 20190128)'
  },
  {
      value: 'Oxford Journals',
      name: 'Oxford Journals'
  },
  {
      value: 'Pathway Studio',
      name: 'Pathway Studio'
  },
  {
      value: 'Project Muse',
      name: 'Project Muse'
  },
  {
      value: 'ProQuest',
      name: 'ProQuest'
  },
  {
      value: 'PubMed',
      name: 'PubMed'
  },
  {
      value: 'reaxys',
      name: 'reaxys'
  },
  {
      value: 'Royal Society of Chemistry',
      name: 'Royal Society of Chemistry'
  },
  {
      value: 'SAE Digital Library',
      name: 'SAE Digital Library'
  },
  {
      value: 'Sage Journals',
      name: 'Sage Journals'
  },
  {
      value: 'Science - AAAS',
      name: 'Science - AAAS'
  },
  {
      value: 'ScienceDirect',
      name: 'ScienceDirect'
  },
  {
      value: 'Scitation',
      name: 'Scitation'
  },
  {
      value: 'SciVal',
      name: 'SciVal'
  },
  {
      value: 'Scopus',
      name: 'Scopus'
  },
  {
      value: 'SIAM',
      name: 'SIAM'
  },
  {
      value: 'SPIE Digital Library',
      name: 'SPIE Digital Library'
  },
  {
      value: 'Springer',
      name: 'Springer'
  },
  {
      value: 'Taylor &amp; Francis',
      name: 'Taylor &amp; Francis'
  },
  {
      value: 'Taylor Francis eBooks',
      name: 'Taylor Francis eBooks'
  },
  {
      value: 'The Institution of Engineering and Technology Description theiet',
      name: 'The Institution of Engineering and Technology Description theiet'
  },
  {
      value: 'Web of Science/Web of Knowledge',
      name: 'Web of Science/Web of Knowledge'
  },
  {
      value: 'WestlawNext Campus Research',
      name: 'WestlawNext Campus Research'
  },
  {
      value: 'Wiley Online Library',
      name: 'Wiley Online Library'
  },
  {
      value: 'World Scientific Publishing',
      name: 'World Scientific Publishing'
  },
  {
      value: 'Import PDF',
      name: 'Import PDF'
  },
  {
      value: 'Other',
      name: 'Other'
  }
];
