import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from '@core/service/project.service';
import { Tag } from '@shared/schema/project';

@Component({
  selector: 'app-show-tags',
  templateUrl: './show-tags.component.html',
  styleUrls: ['./show-tags.component.scss']
})
export class ShowTagsComponent implements OnInit {
  @Input() tags: Tag[];
  @Input() canRemoveTag = true;
  @Output() removeTag: EventEmitter<number> = new EventEmitter<number>();
  constructor(private projectService: ProjectService) { }

  ngOnInit() {
  }
  tagRemove(tagId: number) {
    this.removeTag.emit(tagId);
  }

}
