import { CorrelationVariable, Tag } from '@shared/schema/project';
import { PaperSubject } from '@shared/schema/statistic';
import { ObjectHelper } from './object-helper';

export class HubmetaFilterHelper {
  static createQueryParam(filterFormValue: object) {
    return new ObjectHelper(filterFormValue)
      .kebabCaceKey()
      .valueArrayObjectToSimpleArrayWithMapper('tags', HubmetaFilterHelper.tagsMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('subjects', HubmetaFilterHelper.subjectsMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('contains', HubmetaFilterHelper.variablesMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('notContains', HubmetaFilterHelper.variablesMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('exactlyContains', HubmetaFilterHelper.variablesMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('moderators', HubmetaFilterHelper.modratorMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('aiVariables', HubmetaFilterHelper.aiVariableMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('fulltextUserComments', HubmetaFilterHelper.userCommentsMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('titleUserComments', HubmetaFilterHelper.userCommentsMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('userAssignment', HubmetaFilterHelper.userAssignmentsMapperFunc)
      .value;
  }
  static createForm(filterFormValue: object) {
    return new ObjectHelper(filterFormValue)
      .kebabCaceKey()
      .valueArrayObjectToSimpleArrayWithMapper('tags', HubmetaFilterHelper.tagsMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('subjects', HubmetaFilterHelper.subjectsMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('contains', HubmetaFilterHelper.variablesMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('notContains', HubmetaFilterHelper.variablesMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('exactlyContains', HubmetaFilterHelper.variablesMapperFunc)
      .valueArrayObjectToSimpleArrayWithMapper('moderators', HubmetaFilterHelper.removeNullModrator)
      .valueArrayObjectToSimpleArrayWithMapper('aiVariables', HubmetaFilterHelper.removeNulAiVariable)
      .valueArrayObjectToSimpleArrayWithMapper('fulltextUserComments', HubmetaFilterHelper.removeNulluserComment)
      .valueArrayObjectToSimpleArrayWithMapper('titleUserComments', HubmetaFilterHelper.removeNulluserComment)
      .valueArrayObjectToSimpleArrayWithMapper('userAssignment', HubmetaFilterHelper.removeNullUserAssignment)
      .value;
  }
  static queryToForm(object: object) {
    return new ObjectHelper(object)
      .CamelCaseKey()
      .removeNull()
      .value;
  }
  /**
   * -----------------------
   * MAPPERS FUNCTIONS
   * -----------------------
   */
  static modratorMapperFunc = (item: FilterModrator) => {
    if (
      item.moderatorValue !== '' &&
      item.moderatorValue !== undefined &&
      item.moderatorValue !== null
    ) {
      const tmp = {
        pk: item.pk,
        moderatorValue: item.moderatorValue
      };
      return JSON.stringify(tmp);
    }
    return null;
  }
  static aiVariableMapperFunc = (item: FilterAiVariable) => {
    if (
      item.aiVariableValue !== '' &&
      item.aiVariableValue !== undefined &&
      item.aiVariableValue !== null
    ) {
      const tmp = {
        pk: item.pk,
        aiVariableValue: item.aiVariableValue
      };
      return JSON.stringify(tmp);
    }
    return null;
  }

  static userCommentsMapperFunc = (item: FilterUserComments) => {
    if (item.value !== null && !!item.value.length) {
      return JSON.stringify(item);
    }
    return null;
  }
  static userAssignmentsMapperFunc = (item: FilterUserAssignments) => {
    if (item.roleId !== null &&
      item.stageId !== null
    ) {
      return JSON.stringify(item);
    }
    return null;
  }
  static tagsMapperFunc = (item: FilterTags) => {
    if (item.id !== null) {
      return item.id;
    }
    return null;
  }
  static subjectsMapperFunc = (item: FilterSubjects) => {
    if (item.id !== null) {
      return item.id;
    }
    return null;
  }
  static variablesMapperFunc = (item: FilterVariables) => {
    if (item.id !== null) {
      return item.id;
    }
    return null;
  }
  static removeNullModrator = (item: FilterModrator) => {
    if (
      item.moderatorValue !== '' &&
      item.moderatorValue !== undefined &&
      item.moderatorValue !== null

    ) {
      return item;
    }
    return null;
  }
  static removeNulAiVariable = (item: FilterAiVariable) => {
    if (
      item.aiVariableValue !== '' &&
      item.aiVariableValue !== undefined &&
      item.aiVariableValue !== null

    ) {
      return item;
    }
    return null;
  }
  static removeNulluserComment = (item: FilterUserComments) => {
    if (item.value !== null && !!item.value.length) {
      return item;
    }
    return null;
  }
  static removeNullUserAssignment = (item: FilterUserAssignments) => {
    if (item.roleId !== null &&
      item.stageId !== null
      ) {
      return item;
    }
    return null;
  }

}

//#region helper Intetface

// --------------------
//      MODELS
// --------------------
export interface FilterModrator {
  pk: number;
  name: string;
  type: number;
  default: any;
  moderatorValue: any;
}
export interface FilterAiVariable {
  pk: number;
  name: string;
  type: number;
  default: any;
  aiVariableValue: any;
}
export interface FilterUserComments {
  userId: number;
  value: Array<number> | null;
}
export interface FilterUserAssignments {
  userId: number;
  roleId: number | null;
  stageId: number | null;
}
// tslint:disable-next-line:no-empty-interface
export interface FilterTags extends Tag {
}
// tslint:disable-next-line:no-empty-interface
export interface FilterSubjects extends PaperSubject {
}
// tslint:disable-next-line:no-empty-interface
export interface FilterVariables extends CorrelationVariable {
}
//#endregion
