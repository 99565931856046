import { HttpEventType, HttpResponse } from '@angular/common/http';

export  class HttpUtils {
  static responceComplate(res: any): boolean {
    return res instanceof (HttpResponse) &&
           res.type === HttpEventType.Response &&
           res.ok;
  }
}

