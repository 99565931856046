import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaperFilterRoutingModule } from './paper-filter-routing.module';
import { PaperFilterComponent } from './paper-filter.component';
import { SharedModule } from '@shared/shared.module';

import {
  FilterTextboxComponent,
  FilterTextareaComponent,
  FilterIntTextboxComponent,
  FilterDropdownComponent,
  FilterRangeComponent,
  FilterSearchDropdownComponent,
  FilterScreeningCategoryComponent,
  FilterReviwerCommentsComponent,
  FilterModratorsComponent,
  FilterInfoComponent,
  FilterTitleReseacherReviweComponent,
  FilterTitleReviwerReviweComponent,
  FilterFulltextReseacherReviweComponent,
  FilterFulltextReviwerReviweComponent,
  FilterFulltextUserCommentComponent,
  FilterTitleUserCommentComponent,
  FilterVariableComponent,
  FilterUserModratorsComponent,
  HandleFilterComponent,
  FilterSectionDialogComponent,
} from './components';
import { Ng5SliderService } from './services/ng5-slider.service';
import { FilterUserCommentDialogComponent } from './components/sections/filter-user-comment-dialog/filter-user-comment-dialog.component';
import { FilterUserAssignmentDialogComponent
 } from './components/sections/filter-user-assignment-dialog/filter-user-assignment-dialog.component';
import { FilterUserAssignmentComponent } from './components/sections/filter-user-assignment/filter-user-assignment.component';
import { FilterUserAiVarablesComponent } from './components/sections/filter-user-ai-varables/filter-user-ai-varables.component';
import { FilterAiVariabelsComponent } from './components/elements/filter-ai-variabels/filter-ai-variabels.component';

@NgModule({
  declarations: [
    PaperFilterComponent,
    FilterTextboxComponent,
    FilterTextareaComponent,
    FilterIntTextboxComponent,
    FilterDropdownComponent,
    FilterRangeComponent,
    FilterSearchDropdownComponent,
    FilterScreeningCategoryComponent,
    FilterReviwerCommentsComponent,
    FilterModratorsComponent,
    FilterInfoComponent,
    FilterTitleReseacherReviweComponent,
    FilterTitleReviwerReviweComponent,
    FilterFulltextReseacherReviweComponent,
    FilterFulltextReviwerReviweComponent,
    FilterFulltextUserCommentComponent,
    FilterTitleUserCommentComponent,
    FilterVariableComponent,
    FilterUserModratorsComponent,
    HandleFilterComponent,
    FilterSectionDialogComponent,
    FilterUserCommentDialogComponent,
    FilterUserAssignmentDialogComponent,
    FilterUserAssignmentComponent,
    FilterUserAiVarablesComponent,
    FilterAiVariabelsComponent
  ],
  imports: [
    CommonModule,
    PaperFilterRoutingModule,
    SharedModule
  ],
  exports: [
    PaperFilterComponent,
    FilterInfoComponent,
    FilterVariableComponent,
    FilterUserModratorsComponent
  ],
  entryComponents: [
    FilterSectionDialogComponent,
    FilterUserCommentDialogComponent,
    FilterUserAssignmentDialogComponent
  ],
  providers: [
    Ng5SliderService
  ]

})
export class PaperFilterModule { }
