import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ProjectService } from '@core/service/project.service';
import { loadSavedPaperFilters, showActionSuccessMessage } from '@modules/project/state/project.actions';
import { Store } from '@ngrx/store';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { AppState } from '@shared/schema/app.state';
import { PaperFilter } from '@shared/schema/project';
import { Subscription } from 'rxjs';
import { HubmetaFilterHelper } from '../../../utils/filter-util';

@Component({
  selector: 'app-handle-filter',
  templateUrl: './handle-filter.component.html',
  styleUrls: ['./handle-filter.component.scss']
})
export class HandleFilterComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() projectId: number;
  @Output() clear = new EventEmitter<any>();
  subscription: Subscription[] = [];
  isMember = false;

  selectedSavedFilter = -1;
  newFilterName: FormControl = new FormControl();
  filterList: PaperFilter[] = [];

  constructor(
    private service: ProjectService,
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.setSubscription();
    this.subscription = [
      this.store.select(state => state.project.projectDetails).subscribe(
        (state) => {
          if (state && state.role_status === 'member') {
            this.isMember = true;
          }
        }
      )];
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  setSubscription() {
    this.subscription.push(this.subscribeSavedFilters());
  }

  handleSelectFilter(event: any) {
    this.selectedSavedFilter = event.value;
    this.clear.emit(); // clear form
    if (event.value === -1) {
      this.newFilterName.reset();
    } else {
      this.service.getFilter(event.value).subscribe((result) => {
        this.patchValue(HubmetaFilterHelper.queryToForm(result.filter_set));
      });
    }
  }

  createFilter() {
    const filter: PaperFilter = {
      filter_set: HubmetaFilterHelper.createForm(this.form.value),
      project: this.projectId,
    };
    if (Number(this.selectedSavedFilter) === -1) {
      filter.name = this.newFilterName.value;
      this.service.createFilter(filter).then((val) => {
        this.handleSaveFilterPostAction(val.id);
      });
    } else {
      filter.name = this.filterList.find(
        (item) => item.id === this.selectedSavedFilter
      ).name;
      this.service
        .editFilter(this.selectedSavedFilter, filter)
        .then((val) => {
          this.handleSaveFilterPostAction();
        });
    }
  }

  handleSaveFilterPostAction(editedFilterId?: number) {
    this.store.dispatch(showActionSuccessMessage({
      successMessage: `Filter was successfully ${editedFilterId ? 'added' : 'edited'}`
    }));
    this.loadSavedFilters();
    // set newly created filer as default selected between filter options
    if (editedFilterId) {
      this.selectedSavedFilter = editedFilterId;
    }
  }

  subscribeSavedFilters() {
    return this.store
      .select((state) => state.project.savedPaperFilter)
      .subscribe((filter) => {
        this.filterList = filter;
      });
  }

  loadSavedFilters() {
    this.store.dispatch(loadSavedPaperFilters({ projectId: this.projectId }));
  }

  deleteFilter(filterId: number, event: MouseEvent) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {
        title: 'Delete Filter',
        message: 'Are you sure you want to delete this Filter?',
        button: 'Delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(filterId);
        this.service.deleteFilter(filterId).then(res => {
          this.loadSavedFilters();
        });
      }
    });
  }



  /**
   * NOTE:
   * we cant use form.patchValue() directly
   * becase of fields like modrator are array
   * and array pach value based on index NOT value
   * so we need to create our patch function
   */
  patchValue(obj: any) {
    const { moderators, fulltextUserComments, titleUserComments } = obj;
    const filterField = { ...obj };
    delete filterField.moderators;
    delete filterField.fulltextUserComments;
    delete filterField.titleUserComments;

    // Patch Modrators
    if (moderators) {
      (moderators as Array<{ pk: number, name: string, type: number, moderatorValue: string }>)
        .forEach(moderator => {
          const index = (this.form.get('moderators').value as Array<{ pk: number, name: string, type: number, moderatorValue: string }>)
            .findIndex(item => item.pk === moderator.pk);
          (this.form.get('moderators') as FormArray).at(index).patchValue(moderator);
        });
    }

    // Patch fulltextUserComments
    if (fulltextUserComments) {
      (this.form.get('fulltextUserComments') as FormArray).clear();
      fulltextUserComments.forEach(item => {
        (this.form.get('fulltextUserComments') as FormArray).push(new FormControl(item));
      });
    }

    // Patch titleUserComments
    if (titleUserComments) {
      (this.form.get('titleUserComments') as FormArray).clear();
      titleUserComments.forEach(item => {
        (this.form.get('titleUserComments') as FormArray).push(new FormControl(item));
      });
    }

    // Patch other fileld
    this.form.patchValue(filterField);
  }

}
