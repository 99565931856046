import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProjectService } from '@core/service/project.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { UserInfo } from '@shared/schema/profile';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-handle-assignment',
  templateUrl: './handle-assignment.component.html',
  styleUrls: ['./handle-assignment.component.scss']
})
export class HandleAssignmentComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription: Subscription[] = [];
  users: UserInfo[] = [];
  userAssignment = [];
  userRoleOptions:
    { name: string; value: number }[] = [
      {
        name: 'Reviwer',
        value: 1,
      },
      {
        name: 'Researcher',
        value: 2,
      }
    ];
  userStageOptions:
    { name: string; value: number }[] = [
      {
        name: 'Title Screening',
        value: 4,
      },
      {
        name: 'Full Text Screening',
        value: 6,
      },
      {
        name: 'Data Entry',
        value: 7,
      },
    ];
  displayedColumns: string[] = ['position', 'name', 'section', 'role', 'action'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { paperId: number, importer: any },
    private dialogRef: MatDialogRef<HandleAssignmentComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.setSubscription();
    this.createForm();
    this.getPaperAssignment();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  getPaperAssignment() {
    // Note: responce are in pagination mode but we not handle in this time
    const pagination = {
      page: 1,
      page_size: 100,
    };

    this.projectService.getPaperAssignment(this.data.paperId, pagination).subscribe(res => {
      const data = res.data;
      this.userAssignment = data;
    });
  }

  setSubscription() {
    this.subscription.push(this.subscribeMembers());
  }


  subscribeMembers() {
    return this.store.select(state => state.project.memberList).subscribe((allMembers) => {
      if (allMembers && allMembers.data) {
        this.users = allMembers.data
          .filter(item => item.role === null)
          .map(item => item.user);
      }
    });
  }


  createForm() {
    this.form = this.fb.group({
      user: [null, []],
      stage: [null, []],
      role: [null, []]
    });
  }

  addAssignment() {
    const data = {
      ...this.form.value,
      paper: this.data.paperId,
    };
    this.projectService.addPaperAssignment(data).subscribe(res => {
      this.userAssignment = [
        ...this.userAssignment,
        res
      ];

    });
    this.form.reset();
  }

  deleteItem(item) {
    this.projectService.deletePaperAssignment(item.id).subscribe(res => {
      this.userAssignment = this.userAssignment.filter(i => i.id !== item.id );
    });
  }

  close() {
    this.dialogRef.close(this.userAssignment);
  }

}
