import { Action, createReducer, on } from '@ngrx/store';
import {ProfileState} from '@shared/schema/profile-state';
import {loadProfileSuccess} from '@modules/profile/state/profile.actions';


export const profileFeatureKey = 'profile';


export const initialState: ProfileState = {
  user: null
};

const profileReducer = createReducer(
  initialState,
  on(loadProfileSuccess, (state, action) => ({ ...state, user: action.user })),

);

export function ProfileReducer(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}
