import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectService } from '@core/service/project.service';
import { StatisticService } from '@core/service/statistic.service';
import { Store } from '@ngrx/store';
import { LANGUAGES } from '@shared/data/const-data';
import { SOURCES } from '@shared/data/sources';
import { AppState } from '@shared/schema/app.state';
import { ProjectRanges } from '@shared/schema/project';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap, switchMap, catchError } from 'rxjs/operators';
import { Ng5SliderService } from '../../../services/ng5-slider.service';

@Component({
  selector: 'app-filter-info',
  templateUrl: './filter-info.component.html',
  styleUrls: ['./filter-info.component.scss']
})
export class FilterInfoComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() projectId: number;


  /*------------------------------------
   * [NG5SLIDER BUG]
   * slider should be reset afterOpened
   * otherwise this not whow range correctly
  -------------------------------------*/
  ng5SliderManualRefresh ;

  /*------------------------------------
   * Observable list.
   * Uncubscribe automaticly
   * with Async Pipe in ui
  -------------------------------------*/
  Languges$: Observable<any[]>;
  subjects$: Observable<any[]>;

  /*------------------------------------
   * Subjects TypeAhead For Serchable Drop Down
   * [NG-SELECT]
  -------------------------------------*/
  searchSubject$ = new Subject<string>();

  /*------------------------------------
   * Loading For Serchable Drop Down
   * [NG-SELECT]
  ------------------------------------*/
  subjectLoading = false;

  subscription: Subscription[] = [];
  sources: any[] = SOURCES;
  ranges: ProjectRanges;


  pdfIsNullOptions = [
    { label: 'None', value: null },
    { label: 'Pdf Imported', value: true },
    { label: 'Pdf Not Imported', value: false },
  ];

  constructor(
    private store: Store<AppState>,
    private service: ProjectService,
    private statisticService: StatisticService,
    private slidersSevice: Ng5SliderService
    ) {
      this.ng5SliderManualRefresh = this.slidersSevice.refresh;
    }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  ngOnInit() {
    this.setSubscription();
    this.setObservables();
  }

  setObservables() {
    this.Languges$ = this.getLanguages();
    this.subjects$ = this.getSubjects();
  }

  setSubscription() {
    this.subscription.push(this.getProjectRanges());
  }

  getProjectRanges() {
    return this.store
      .select((state) => state.project.projectRanges)
      .subscribe((state) => {
        this.ranges = state;
      });
  }

  getLanguages() {
    return this.service.getProjectLanguages(this.projectId).pipe(
      map(result => result.languages as Array<string>),
      map((result: string[]) => {
        return result.map(item => {
          const language = {
            value: item,
            label: LANGUAGES[item],
          };
          return language;
        });
      })
    );
  }

  getSubjects() {
    return concat(
      of([]),
      this.searchSubject$.pipe(
        distinctUntilChanged(),
        tap(() => { this.subjectLoading = true; }),
        switchMap((term: string) => {
          if (term !== '') {
            return this.statisticService.getPaperSubjects(this.projectId, term).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => { this.subjectLoading = false; }),
            );
          }
        }
        )
      )
    );
  }

  getTagCallbackFunction = (term: string): Observable<{
    num_of_pages: number;
    count: number;
    data: any[];
  }> => {
    return this.service.getTags({
      name__icontains: term,
      page_size: 10,
      page: 1,
      project: this.projectId.toString(),
    }, true);
  }

  // getSubjectCallbackFunction = (term: string): Observable<{
  //   num_of_pages: number;
  //   count: number;
  //   data: any[];
  // }> => {
  //   return // get Sunject service here
  // }

}
