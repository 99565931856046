import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap, filter, take, mergeMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/service/authentication.service';
import { MatSnackBar } from '@angular/material';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@env';
import { LoadingService } from '@core/service/loading.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  list = [
    { url: '/auth/authentication/login/' },
    { url: '/auth/authentication/register/' },
    { url: '/auth/authentication/verify/' },
    { url: '/auth/authentication/forget_password/' },
    { url: '/auth/authentication/reset_password/' },
    { url: '/auth/authentication/oauth/' },
    { url: '/auth/authentication/refresh/' },

  ];
  private tokenRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  refreshPeriod = 2 * 60 * 60 * 1000;
  timeout;

  constructor(
    public auth: AuthenticationService,
    public router: Router,
    public http: HttpClient,
    private snackBar: MatSnackBar,
    private toastrService: NbToastrService,
    private loading: LoadingService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // here we check the expire time of token if is expired by more than two hours then we call refresh
    // if the expire token did not exist as a result of null number conversion, it is calculated as unix epoch and end in refresh call
    // if (
    //   (new Date(this.auth.hubmetaExpire)).getTime() - (new Date()).getTime() < this.refreshPeriod &&
    //   this.refreshPermission &&
    //   this.auth.hubmetaRefreshToken !== null &&
    //   this.auth.hubmetaRefreshToken !== undefined &&
    //   this.BlockList(request.url)
    // ) {
    //   if (this.timeout !== null && this.timeout !== undefined) {
    //     clearTimeout(this.timeout);
    //   }
    //   this.timeout = setTimeout(() => {
    //     this.refreshPermission = false;
    //     this.auth.refreshToken().then(() => {
    //       this.timeout = null;
    //       this.refreshPermission = true;
    //     }).catch(error => {
    //       this.auth.hubmetaToken = null;
    //       this.auth.hubmetaExpire = null;
    //       this.auth.hubmetaRefreshToken = null;
    //       this.router.navigate(['/auth']);
    //     });
    //   }, 250);
    // }
    request = this.setProjectId(request);

    if (!request.reportProgress) {
      this.loading.show(request.url);
    }
    if (this.BlockList(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `jwt ${this.auth.hubmetaToken}`
        }
      });
    }
    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !request.reportProgress) {
          this.loading.solvePendigReq(request.url);
        }
      },

      (errorInfo: any) => {
        // Handle errors here!
        if (!request.reportProgress) {
          this.loading.solvePendigReq(request.url);
        }
        // if (request.url.includes('refresh')) {
        //   this.logoutUser();
        // }
        if (errorInfo instanceof HttpErrorResponse) {
          if (errorInfo.status === 401) {
            return this.handleUnauthorized(request, next);
          }
          if ([400, 405, 406].indexOf(errorInfo.status) > -1) {
            return this.handleErrors(errorInfo);
          }
          // Handle Unuthorize request . Redirect to no-access page
          if ([403].indexOf(errorInfo.status) > -1) {
            this.handleErrors(errorInfo);
            return this.router.navigate(['/no-access']);
          }
        }
      }
    ));
  }
  addAuthenticationToken(request) {
    const accessToken = this.auth.hubmetaToken;
    if (!accessToken) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `jwt ${accessToken}`
      }
    });
  }
  handleUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    return this.logoutUser(); // this line is temprory
    if (this.tokenRefreshing) {
      this.refreshTokenSubject
        .pipe(
          filter(req => req !== null),
          take(1),
          switchMap(() => next.handle(this.addAuthenticationToken(request)))
        ).subscribe(
          res => {
            console.log(res);
          }
        );
    } else {
      this.tokenRefreshing = true;
      this.auth.hubmetaToken = null;
      this.refreshTokenSubject.next(null);
      if (!this.auth.refreshToken) {
        return this.router.navigate(['/auth']);
      }
      return this.auth.refreshToken().pipe(
        switchMap(
          response => {
            if (response.access) {
              this.tokenRefreshing = false;
              this.auth.hubmetaToken = response.access;
              this.refreshTokenSubject.next(response);
              return next.handle(this.addAuthenticationToken(request));
            } else {
              this.tokenRefreshing = false;
              this.auth.hubmetaToken = null;
              this.auth.hubmetaExpire = null;
              this.auth.hubmetaRefreshToken = null;
              return this.router.navigate(['/auth']);
            }
          }
        ),
        catchError(() => {
          return this.logoutUser();
        })
      ).subscribe();
    }

  }
  logoutUser() {
    this.tokenRefreshing = false;
    this.auth.hubmetaToken = null;
    this.auth.hubmetaExpire = null;
    this.auth.hubmetaRefreshToken = null;
    return this.router.navigate(['/auth']);
  }
  handleErrors(errorInfo) {
    let message = '';
    if (errorInfo.error) {
      for (const prop in errorInfo.error) {
        if (prop && errorInfo.error[prop]) {
          message += prop + ': ';
          message += errorInfo.error[prop] + '\n';
        }

      }
    }
    this.showToast(message);
  }

  BlockList(url: string): boolean {
    for (const item of this.list) {
      if (url.includes(item.url)) {
        return false;
      }
    }
    return true;
  }
  openSnackBar(message = 'Bad Request', title = 'Error') {
    this.snackBar.open(message, title, {
      panelClass: 'error-log',
      duration: 2000,
    });
  }
  showToast(message = 'Bad Request', title = 'Error') {
    this.toastrService.show(
      message,
      title.toUpperCase(),
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        status: 'danger',
        duration: 4000,
        toastClass: 'error-log'
      });
  }
  setProjectId(request: HttpRequest<any>) {
    const path = window.location.pathname;
    const list = path.split('/');
    const foundIndex = list.findIndex(val => val === 'project');
    if (foundIndex === -1) {
      return request;
    }
    const projectId = list[foundIndex + 1];
    if (!projectId) {
      return request;
    }
    if (request.method === 'GET' || request.method === 'DELETE') {
      if (projectId && !request.params.get('project')) {
        request = request.clone({
          setParams: {
            ...request.params.getAll,
            project: projectId
          },
        });
      }
    } else {
      if (request.body) {
        if (Array.isArray(request.body)) {
          return request;
        }
        if (request.body instanceof FormData) {
          const hasProject = request.body.has('project') || request.body.has('project_id');
          if (!hasProject) {
            request = request.clone({
              body: request.body.append('project', projectId)
            });
          }

        } else {
          const keys = Object.keys(request.body);
          const hasProject = keys.includes('project') || keys.includes('project_id');
          if (!hasProject) {
            request = request.clone({
              body: { ...request.body, project: projectId }
            });
          }
        }


      }
    }
    return request;
  }
}
