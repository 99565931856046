import { Injectable } from '@angular/core';
import { FilterPaperType } from '@shared/schema/filter-project';
import { Subject } from 'rxjs';

const DEFAULT_PAPER_PAGE_SIZE = 10;
@Injectable({
  providedIn: 'root'
})
export class FilterService {
  /**
   * Paper Paginations
   */
  paginations = {
    manaullaAddPaper: {
      page: 1,
      page_size: DEFAULT_PAPER_PAGE_SIZE,
      ordering: null
    },
    titleScreening: {
      page: 1,
      page_size: DEFAULT_PAPER_PAGE_SIZE,
      ordering: null
    },
    fullTextScreeing: {
      page: 1,
      page_size: DEFAULT_PAPER_PAGE_SIZE,
      ordering: null
    },
    dataEntry: {
      page: 1,
      page_size: DEFAULT_PAPER_PAGE_SIZE,
      ordering: null
    }
  };

  private setFilterTypeSubject = new Subject<FilterPaperType>();
  private setFilterToggleSubject = new Subject<any>();

  setFilterType$ = this.setFilterTypeSubject.asObservable();
  setFilterToggle$ = this.setFilterToggleSubject.asObservable();
  constructor() { }

  announceFilterType(type: FilterPaperType) {
    this.setFilterTypeSubject.next(type);
  }
  announceFilterToggle(toggle: boolean) {
    this.setFilterToggleSubject.next(toggle);
  }

  setPagination(type: string, value: { page?: number, page_size?: number, ordering?: string , selectedPaperId?: number}) {
    this.paginations[type] = { ...this.paginations[type], ...value };
  }
  resetAllPagination() {
    Object.keys(this.paginations).forEach(section => {
      this.paginations[section] = {
        page: 1,
        page_size: DEFAULT_PAPER_PAGE_SIZE,
        ordering: null
      };
    });
  }
  resetAllPaginationToOne() {
    Object.keys(this.paginations).forEach(section => {
      this.paginations[section] = {
        ...this.paginations[section],
        page: 1
      };
    });
  }
  getPagination(type: string) {
    return this.paginations[type];
  }
}
