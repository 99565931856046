import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardLayoutComponent } from '@layout/dashboard-layout/dashboard-layout.component';
import { AuthenticationLayoutComponent } from '@layout/authentication-layout/authentication-layout.component';
import { ProjectLayoutComponent } from '@layout/project-layout/project-layout.component';
import { AuthGuard } from '@core/guard/auth.guard';
import { ProjectMemberResolver } from '@core/resolver/ProjectMemberResolver';
import { NoAccessPageComponent } from '@shared/components/no-access-page/no-access-page.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          breadcrumb: 'Dashboard',
          status: true
        }
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@modules/profile/profile.module').then(m => m.ProfileModule),
        data: {
          breadcrumb: 'Profile',
          status: true
        }
      },
    ]
  },
  {
    path: 'project/:projectId',
    component: ProjectLayoutComponent,
    canActivate: [AuthGuard],
    resolve: {
      member: ProjectMemberResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@modules/project/project.module').then(m => m.ProjectModule),
        data: {
          breadcrumb: 'Project',
          status: true
        }
      },
    ]
  },
  {
    path: '',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('@modules/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
    ]
  },
  {
    path: 'no-access',
    component: NoAccessPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
