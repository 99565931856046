import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {FilterService} from '@core/service/filter.service';
import {
  loadMemberList,
  loadModerators,
  loadSavedPaperFilters,
  setFilterPapers,
  loadAiVariables
} from '@modules/project/state/project.actions';
import {getScreeningReason} from '@modules/project/state/statistics.actions';
import {NbSidebarService} from '@nebular/theme';
import {Store} from '@ngrx/store';
import {guides} from '@shared/data/guides';
import {AppState} from '@shared/schema/app.state';
import {Subscription} from 'rxjs';
import {FilterSectionDialogComponent} from './components';
import {FilterSectionPermission, Sections} from './models';
import {HubmetaFilterHelper} from './utils/filter-util';


@Component({
  selector: 'app-paper-filter',
  templateUrl: './paper-filter.component.html',
  styleUrls: ['./paper-filter.component.scss']
})
export class PaperFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  projectId: number;
  filterForm: FormGroup;
  defultFilterFormValue = {};
  guide = {
    overall: guides.titleScreening.filter
  };
  sectionPermission = FilterSectionPermission;
  sections = Sections;
  aiVariablePermission: boolean;

  constructor(
    private sidebarService: NbSidebarService,
    private store: Store<AppState>,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private filterService: FilterService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.projectId = +this.route.snapshot.params.projectId;
    this.dispachData();
    this.addSubscriptions();
    this.buildForm();
    this.defultFilterFormValue = {...this.filterForm.value}; // use for reset form to defult value
  }

  dispachData() {
    this.store.dispatch(getScreeningReason({projectId: this.projectId}));
    this.store.dispatch(loadModerators({projectId: this.projectId}));
    this.store.dispatch(loadMemberList({projectId: this.projectId, page: 1, page_size: 20}));
    this.store.dispatch(loadSavedPaperFilters({projectId: this.projectId}));
    this.loadAiVariables();
  }

  loadAiVariables() {
    this.store.select(state => state.project.projectDetails).subscribe(
      (state) => {
        if (
          state &&
          state.feature_flag &&
          state.feature_flag.ai_variable
        ) {
          this.aiVariablePermission = true;
          this.store.dispatch(loadAiVariables({projectId: this.projectId}));
        }
      }
    );
  }

  addSubscriptions() {
  }

  ngOnDestroy() {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  /**
   * Inintal filter Form here
   * Becareful about defullt value
   * All defult value should assign here
   */
  buildForm() {
    this.filterForm = this.builder.group({

      // ------------ PROJECT ID ---------------------------
      projectId                           : [this.projectId],
      // ------------ INFO ---------------------------
      title                               : [null, []],
      authors                             : [null, []],
      journal                             : [null, []],
      abstract                            : [null, []],
      paperId                             : [null, []],
      source                              : [null, []],
      languageFilter                      : [null, []],
      subjects                            : [[], []],
      tags                                : [[], []],
      pdfExist                            : [null, []],
      libmetaExist                        : [null, []],
      year                                : [[1900, new Date().getFullYear()], []],
      sampleSizeRngMin                    : [null, []],
      sampleSizeRngMax                    : [null, []],
      // -------------- VARIABLES -----------------------
      variablesNumber                     : [[0, 30], []],
      contains                            : [[], []],
      notContains                         : [[], []],
      exactlyContains                     : [[], []],
      // -------------- MODRATORS ------------------------
      moderators                          : this.builder.array([]),
      // -------------- AI VARIABLES ------------------------
      aiVariables                          : this.builder.array([]),
      // -------------- USER ASSIGNMENTS ------------------
      userAssignment                     : this.builder.array([]),
      // -------------- COMMENT IN TITLE ------------------
      titleYesRatio                       : [[0, 100], []],
      titleNumberOfComments               : [[0, 0], []],
      titleUserComments                   : this.builder.array([]),
      // -------------- COMMENT IN FULL TEXT -------------
      fulltextYesRatio                    : [[0, 100], []],
      fulltextNumberOfComments            : [[0, 0], []],
      fulltextUserComments                : this.builder.array([]),
      // ------------- FULL TEXT RESEARCHERRIVEW ---------
      fulltextResearcherReviewFilter      : [null, []],
      fulltextResearcherCommentTypeFilter : [null, []],
      fulltextResearcherDecision          : [null, []],
      fulltextResearcherDiscrepancy       : [null, []],
      fulltextResearcherComment           : [null, []],
      fulltextResearcherNote              : [null, []],
      // ------------- FULL TEXT REVIEWER RIVEW ------------
      fulltextReviewerReviewFilter        : [null, []],
      fulltextReviewerCommentTypeFilter   : [null, []],
      fulltextReviewerDecision            : [null, []],
      fulltextReviewerComment             : [null, []],
      fulltextReviewerNote                : [null, []],
      // ------------- TITLE RESEARCHER RIVEW -------------
      titleResearcherReviewFilter         : [null, []],
      titleResearcherCommentTypeFilter    : [null, []],
      titleResearcherDecision             : [null, []],
      titleResearcherDiscrepancy          : [null, []],
      titleResearcherComment              : [null, []],
      // ------------- TITLE REVIEWER RIVEW ----------------
      titleReviewerReviewFilter           : [null, []],
      titleReviewerCommentTypeFilter      : [null, []],
      titleReviewerDecision               : [null, []],
      titleReviewerComment                : [null, []],
    });
  }

  closeSideBar() {
    this.sidebarService.collapse('right');
    this.filterService.announceFilterToggle(false);
  }

  handleFilter() {
    const filter = HubmetaFilterHelper.createQueryParam(this.filterForm.value);
    this.filterService.resetAllPaginationToOne();
    this.store.dispatch(setFilterPapers({
      filter,
      filterType: 'general'
    }));
  }

  handleClearFilters() {
    this.resetFrom();
    this.handleFilter();
  }

  persmision(sectionPersmision: any): boolean {
    // const projectAccess = this.route.snapshot.data.member.project_access;
    let projectAccess = null;
    if (this.route.snapshot.data.member) {
      // console.log('mrnu member data run');

      projectAccess = this.route.snapshot.data.member.project_access;
    } else {
      // Set default values for this.projectAccess

      projectAccess = {
        details: true,
        activity: false,
        analytics: false,
        arm: false,
        import_article: false,
        files: true,
        papers: true,
        deduplication: false,
        title_screening: 2,
        fulltext_screening: 2,
        data_entry: true,
        analysis: true,
        general: false,
        members: false,
        workspace: false,
        screening_category: false,
        member: false,
        taxonomy_citation: true,
        taxonomy_ai_variable: false,
        taxonomy_construct: true,
        taxonomy_files: true,
        taxonomy_group_construct: true,
        taxonomy_measurement: true,
        taxonomy_moderators: true,
        taxonomy_tags: true,
        taxonomy_variable_set: true,
        taxonomy_visualization: true,

      };
    }
    const tmp = [];
    Object.keys(sectionPersmision).forEach(key => {
      tmp.push(sectionPersmision[key] === projectAccess[key]);
    });
    return tmp.every(element => element === true);
  }

  resetFrom() {
    const manualClearcontrol = ['fulltextUserComments', 'titleUserComments', 'moderators'];
    // reset simple controll
    manualClearcontrol.forEach(item => {
      delete this.defultFilterFormValue[item];
    });
    this.filterForm.patchValue(this.defultFilterFormValue);

    // rest modrators
    (this.filterForm.get('moderators') as FormArray).controls.forEach(item => {
      item.get('moderatorValue').patchValue('');
    });

    // reset Ai variable
    (this.filterForm.get('aiVariables') as FormArray).controls.forEach(item => {
      item.get('aiVariableValue').patchValue('');
    });

    // // rest titleUserComments
    // (this.filterForm.get('titleUserComments') as FormArray).controls.forEach(item => {
    //   item.get('value').patchValue(null);
    // });

    // rest titleUserComments
    (this.filterForm.get('titleUserComments') as FormArray).clear();

    // // rest fulltextUserComments
    // (this.filterForm.get('fulltextUserComments') as FormArray).controls.forEach(item => {
    //   item.get('value').patchValue(null);
    // });

    // rest fulltextUserComments
    (this.filterForm.get('fulltextUserComments') as FormArray).clear();

    // rest userAssignment
    (this.filterForm.get('userAssignment') as FormArray).clear();
  }

  openSectionDialog(selectedSection: Sections) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '50%',
      maxHeight: '80vh',
      data: {
        projectId: this.projectId,
        form: this.filterForm,
        section: selectedSection
      },
    };
    const dialogRef = this.dialog.open(FilterSectionDialogComponent, config);
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.filterForm.patchValue(data);
      }
    });
  }


}
