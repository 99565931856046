export enum Sections  {
  INFO = 1,
  VARIABLES,
  TITLE_REVIEWS_RESERCHER,
  TITLE_REVIEWS_REVIEWER,
  FULL_TEXT_REVIEWS_RESERCHER,
  FULL_TEXT_REVIEWS_REVIEWER,
  MODERATOR,
  TITLE_COMMENTS,
  FULLTEXT_COMMENTS
}
