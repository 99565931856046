import { UserInfo } from '@shared/schema/profile';
import { PaperItemInfo } from './project';

export interface TitleScreeningReviewerStatistic {
  reviewed_papers: number;
  yes_comments: number;
  no_comments: number;
  maybe_comments: number;
  total_papers: number;
}
export interface PaperComment {
  id?: number;
  pk?: number;
  comment?: string;
  status: PaperCommentStatus; // [ 1 => title screening, 2 => full text screening]
  var_type?: number; // [ 1 => yes, 2 => no, 3 => maybe ]
  paper: number;
  paper_name?: string;
  user?: UserInfo;
  history_date?: string;
  history_id?: number;
  history_type?: string;
  history_user?: number;
  history_change_reason?: string;
  screening_reason?: number;
  created_at?: string;
}
export enum PaperCommentStatus {
  'title screening' = 1,
  'full text screening' = 2,
}
export interface Sample {
  id: number;
  name: string;
  paper: number;
  size: number;
  note: string;
  image: string;
  sample_type: number;
  calculation_type: number;
}
export interface Paper {
  id: number;
  modified_at: string;
  name: string;
  name_without_punc: string;
  author: string;
  author_without_punc: string;
  journal: string;
  year: number;
  link: string;
  abstract: string;
  status: number;
  language: string;
  project: number;
  batch: number;
  related_paper: number;
  history_user?: number;
  history_change_reason?: string;
  history_date?: string;
  history_id?: number;
  history_type?: string;
  user?: number;
}
export interface ModeratorVariable {
  name: string;
  var_type: number;
  default: any;
  pk: number;
}
export interface CorrelationOutcome {
  first_variable?: CorrelationOutcomeVariable;
  first_variable_placeholder?: string;
  order?: number;
  calculated?: boolean;
  sample?: number;
  second_variable?: CorrelationOutcomeVariable;
  second_variable_placeholder?: string;
  value?: number;
  var_type?: number;
  correlation_value?: number;
  created_at?: string;
  id: number;
  modified_at?: string;
  paper?: PaperItemInfo;
  samd: number;
  samd_z: number;
  dffits: number;
  is_outlier: boolean;
  sample_size: number;
}
export interface CorrelationOutcomeToPost {
  first_variable?: CorrelationOutcomeVariable;
  first_variable_placeholder?: string;
  order?: number;
  calculated?: boolean;
  sample?: number;
  second_variable?: CorrelationOutcomeVariable;
  second_variable_placeholder?: string;
  base_value?: number | string;
  var_type?: number;
  correlation_value?: number;
}
export interface CorrelationOutcomeVariable {
  id?: number;
  time: number;
  variable?: number;
  variable_id?: number;
}
export interface ProjectStatistics {
  members_activities: MemberActivity[];
  researcher_data: ResearcherData;
}
export interface MemberActivity {
  fulltext_comment_progress: CommentProgress;
  fulltext_screening_papers: number;
  title_comment_progress: CommentProgress;
  title_screening_papers: number;
  title_no_comment_in_a_row?: number;
}
export interface CommentProgress {
  maybe: number;
  no: number;
  yes: number;
}
export interface ResearcherData {
  fulltext_screening_accepted: number;
  fulltext_screening_decided: number;
  fulltext_screening_discrepancy: number;
  fulltext_screening_papers: number;
  title_screening_discrepancy_checked: number;
  title_screening_decided: number;
  title_screening_discrepancy: number;
  title_screening_papers: number;
  title_no_comment_in_a_row: number;
  fulltext_screening_discrepancy_checked: number;
}
export interface AICommentStatistics {
  will_be_reject: number;
  will_be_accept: number;
  accepted_by_user_and_rejected_by_asrevview: number;
  rejected_by_user_and_rejected_by_asrevview: number;
  accepted_by_user_and_accepted_by_asreview: number;
  rejected_by_user_and_accepted_by_asreview: number;
}
export interface PaperSubject {
  id: number;
  name?: string;
}
export interface LibmetaPaperDetail {
  abstract?: string;
  author: string;
  author_without_punc?: string;
  hubmeta_id?: number;
  id: number;
  journal?: string;
  language?: string;
  link?: string;
  modified_at?: string;
  name: string;
  name_without_punc?: string;
  project?: number;
  related_project?: number;
  year: number;
  sample: SampleDetail[];
}
export interface SampleDetail {
  correlations: CorrelationOutcome[];
  id: number;
  meta_data?: string;
  name: string;
  note?: string;
  size: number;
}
export interface ScreeningReason {
  id: number;
  priority: number;
  reason: string;
  project: number;
  screening_type: ScreeningType;
  connotation: Connotation;
}
export enum Connotation {
  'negative' = 1,
  'positive' = 2
}
export enum ScreeningType {
  'Title screening' = 1,
  'Full text screening'
}
export enum CommentStatus {
  'title screening' = 1,
  'full text screening',
  'review deduplication',
  'data entry'
}
export declare type CommentStatusOptions = 1 | 2 | 3 | 4;

export interface CorrelationOutcomeStatistic {
  correlations: CorrelationOutcome[];
  excluded_correlations: number[];
  samd_avg: number;
  samd_sd: number;
}
export interface CorrelationOutcomeFilterSet {
  first_variable?: string;
  first_variable_instance__in?: string;
  second_variable?: string;
  second_variable_instance__in?: string;
  value?: string;
  var_type?: string;
  sample__paper?: string;
  excluded_correlations?: number[];
  outlier_distance?: number;
  response_format?: string;
  filter?: number;
  sample?: string;
  calculation_type?: string;
  confidence?: number;
  credibility?: number;
}
export interface KappaDiscrepancy {
  kappa_table: {
    first_member: number;
    second_member: number;
    kapa_value: number;
    num_of_papers: number
  }[];
  kappa: number;
  se: number;
  z_stat: number;
  p_value: number;
  lower: number;
  upper: number;
  num_of_paper: number;
}
export interface ASReviewConfig {
  feature_extraction_model: FeatureExtractionModels;
  classifier_model: ClassifierModel;
}
export enum FeatureExtractionModels {
  tfidf,
  doc2vec,
}
export enum ClassifierModel {
  nb, // NaiveBayes
  svm,
  logistic,
  rf, // RandomForest
}



export interface CorelationOutcomeTableVariable {
  name: string;
  id?: number;
  variableId: number;
  type: string;
  tag: number;
  time?: any;
  placeholder?: string;
  citation_info?: string;
}

export interface CorelationOutcomeTableData {
  table_view: any[][];
  variables_view: CorelationOutcomeTableVariable[];
}
