import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommentType, PaperItem, UserComment } from '@shared/schema/project';
import { PaperComment, PaperCommentStatus } from '@shared/schema/statistic';

@Component({
  selector: 'app-paper-comment',
  templateUrl: './paper-comment.component.html',
  styleUrls: ['./paper-comment.component.scss'],
})
export class PaperCommentComponent implements OnChanges {
  @Input() paper: PaperItem;
  @Input() isFullTextPage = false;
  @Input() isAICell = false;
  @Input() member;
  commentType = CommentType;
  fullTextComment: PaperComment;
  titleComment: PaperComment;
  aiComment: PaperComment;
  constructor() {}

  ngOnChanges() {
    if (this.isAICell) {
      this.aiComment = this.paper.comments.find((val) => val.user === null);
    } else {
      this.fullTextComment = this.paper.comments.find(
        (val) =>
          val.status === PaperCommentStatus['full text screening'] &&
          val.user &&
          this.member &&
          this.member.user &&
          val.user.user_id === this.member.user.user_id
      );
      this.titleComment = this.paper.comments.find(
        (val) =>
          val.status === PaperCommentStatus['title screening'] &&
          val.user &&
          this.member &&
          this.member.user &&
          val.user.user_id === this.member.user.user_id
      );
    }
  }
}
