// tslint:disable:max-line-length
export const guides = {
  dataEntry: {
    exportSample: 'Export correlation table information as a CSV file. It can be R-Score or any other score you have entered here',
    addSample: 'You can add more than one sample for each article by clicking plus sign',
    copySample: 'copy current samples',
    addRow: 'You can add more variables by clicking “Add Row”',
    importCSV: 'You can import the correlation table data from a csv file.\n Download a sample file here and change the values and upload it again.',
    import: 'Import correlation table information from “CSV”, HubMeta’s “Library” or the article’s “PDF”. If Import from PDF is selected, HubMeta will try to automatically load correlation table values',
    importFromLib: 'HubMeta keeps a library of articles along with their correlation table, and other information which can be imported into your project. This library keeps getting updated.',
    importPdf: 'You can upload a PDF or image file for the article here. HubMeta will first try to locate the correlation table in the paper. It will then use state-of-the-art AI technology to automatically read and import correlation table data to your project. You can manually edit this information later.',
    sampleSize: 'Each article in your project can have one or more samples. Each sample has multiple aspects, importantly the sample size which is required. In the “Type” aspect you can specify the type of effect size you want to enter from the article. The options are R-Score, D-Score, F-Score, T-Score and Chi-Square. HubMeta will automatically do conversions before analysis.',
    compositeScoreCalcType:
      `Composite score calculations are required when you enter several constructs and measurements with the same parent. For example, if you have “Satisfaction” as a group construct of “Job satisfaction” and “Relational satisfaction”, and you have scores for both constructs entered, HubMeta will need to calculate a composite score for “Satisfaction”.\n
Composite score calculation type has three options: “Normal Average”, “HubMeta composite score calculations”, and “Auto”.\n
Normal Average: In this option, HubMeta will simply calculate the average of the components. For example, let’s say we want to calculate the correlation between Satisfaction and Var1 in our example above. If we have the correlation between Job satisfaction and Var1 as 0.4 and between Relational satisfaction and Var1 as 0.5, then using this method, the correlation between the group construct “Satisfaction” and Var1 becomes 0.45.\n
HubMeta Composite: This option uses …\n
Auto: In this option (which is the default option), HubMeta decides which method to use. In Most cases the HubMeta Composite method would be used. However, if …`,
    moderators: 'You can use the drop-down menu for each moderator variable to enter values. These preset values can be configured under “Workspace” in “Settings”',
    newSample: 'Here you can specify a new sample for your project. Each sample should have a sample size.',

  },
  workspace: {
    overall: 'this is opverall for taxonomy part',

    citations: 'Here you can add a citation as a source for your constructs and measurements',
    tags: 'Tags can help you search for and find variables. If you have a large number of variables, adding tags is recommended',
    reliability: 'In meta-analysis calculations, reliability information are required. If you leave the reliability information blank, you can specify how HubMeta deals with them. If you leave this box unchecked, you need to enter a default value to be assumed as the reliability of this variable across all papers in the project. If you don’t specify this number, HubMeta will replace it with 1. If you check the “Fill empty reliability …” box, HubMeta will replace the reliability when it is left empty in the paper with the average reliability of that variable across the project or 1 if there are no other reliability values entered.',
    sdValue: 'The SD value for variables is required in meta-analysis calculations. If the SD value is left empty for a variable HubMeta replaces it with 0 or the default value you enter here if you leave this box unchecked. If you check the “Correct using …” box, HubMeta will calculate the average of other SDs across the project and use that as the default value to replace empty SDs.',
    parent: 'Variables have three hierarchical levels in HubMeta which are from top to bottom Group Constructs, Constructs, and Measurements. Therefore each Measurement can have a Construct as a parent, and each construct can have one or more group constructs as a parent. You can leave the parent value empty, but correctly specifying these relationships are important in proper calculation of composite correlations.',
    moderators: 'In this page you can manage moderator variables. Each project has some default moderators assigned to it when the project is created. These can be edited, added or removed in this page. The moderators set here, will be shown in Data Entry for each paper.',
    addModeratorVar: 'In this page you can add moderator variables, and specify the type of those variables and specify default values for the variables.',
    addCitation: 'These are citations for the measure being used in your project',
    filterCitations: 'Use the filter module to filter citations (for your measurements and constructs) based on various criteria. ',
    variableSet: 'Variable sets are handy tools when you want to analyze your data. You can define as many variable sets as you need. In analysis, when you select a variable set, all variables in that set are entered into analysis',
    addVariablesGroup: 'Select variables to be added to a variable set. They could be Group constructs, constructs or measurements',
    visualization: 'This page provides a visualization of the variables that are defined in your project and shows the relationship between Measurements, Constructs and Group Constructs',
    groupConstruct: 'You can select two or more constructs and group them into a “Group Construct”. Group constructs can be entered into your analysis. The correlations for the group constructs will be calculated using HubMeta’s composite score measurement or using simple average if you choose to.',

  },
  taxonomy: {
    overall: 'this is opverall for taxonomy part',
    citations: 'Here you can add a citation as a source for your constructs and measurements',
    tags: 'Tags can help you search for and find variables. If you have a large number of variables, adding tags is recommended',
    reliability: 'In meta-analysis calculations, reliability information are required. If you leave the reliability information blank, you can specify how HubMeta deals with them. If you leave this box unchecked, you need to enter a default value to be assumed as the reliability of this variable across all papers in the project. If you don’t specify this number, HubMeta will replace it with 1. If you check the “Fill empty reliability …” box, HubMeta will replace the reliability when it is left empty in the paper with the average reliability of that variable across the project or 1 if there are no other reliability values entered.',
    sdValue: 'The SD value for variables is required in meta-analysis calculations. If the SD value is left empty for a variable HubMeta replaces it with 0 or the default value you enter here if you leave this box unchecked. If you check the “Correct using …” box, HubMeta will calculate the average of other SDs across the project and use that as the default value to replace empty SDs.',
    parent: 'Variables have three hierarchical levels in HubMeta which are from top to bottom Group Constructs, Constructs, and Measurements. Therefore each Measurement can have a Construct as a parent, and each construct can have one or more group constructs as a parent. You can leave the parent value empty, but correctly specifying these relationships are important in proper calculation of composite correlations.',
    moderators: 'In this page you can manage moderator variables. Each project has some default moderators assigned to it when the project is created. These can be edited, added or removed in this page. The moderators set here, will be shown in Data Entry for each paper.',
    addModeratorVar: 'In this page you can add moderator variables, and specify the type of those variables and specify default values for the variables.',
    addCitation: 'These are citations for the measure being used in your project',
    filterCitations: 'Use the filter module to filter citations (for your measurements and constructs) based on various criteria. ',
    variableSet: 'Variable sets are handy tools when you want to analyze your data. You can define as many variable sets as you need. In analysis, when you select a variable set, all variables in that set are entered into analysis',
    addVariablesGroup: 'Select variables to be added to a variable set. They could be Group constructs, constructs or measurements',
    visualization: 'This page provides a visualization of the variables that are defined in your project and shows the relationship between Measurements, Constructs and Group Constructs',
    groupConstruct: 'You can select two or more constructs and group them into a “Group Construct”. Group constructs can be entered into your analysis. The correlations for the group constructs will be calculated using HubMeta’s composite score measurement or using simple average if you choose to.',

  },
  reasoning: {
    screeningCategory: 'In this page you can add/edit/delete the reasons for rejecting articles during title screening or full-text screening. Some categories are added at the start of each project as a default, but you can add/edit them here',
    addReason: 'You can enter the reason and specify its priority in the list of reasons here.',

  },
  dashboard: {
    slug: 'Slug is the unique link to your project. If your chosen slug was not available, try again with a new one. You can go directly to your project by using this link',
    uploadHubmeta: 'You can create a new project from an existing HubMeta project. To do this, you need to first export all project data from the existing project as a HubMeta file, and import it in this page. This is useful when you want to create duplicates of your projects.',

  },
  generalSetting: {
    editSetting: 'In this page, you can edit the general properties of your project such as project name, project avatar, the slug (direct link), and other settings.',
    advance:
      `You can export all project information to be used in the future or to make a duplicate by exporting as a Hubmeta importable file.
As another option, you can export all project data into a csv file which can be used if you prefer to do your calculations manually or using an alternative software.`
  },
  projectDetail: {
    prisma: 'This page provides a visualization of the PRISMA chart for your project which you can also download. For more information about PRISMA, please check ',
    prismaRefLink: 'http://prisma-statement.org/prismastatement/flowdiagram.aspx',

  },
  activity: {
    log: 'This page provides a log of all activities in your projects.',
    overall: 'This is the overall part of activity',
  },
  analytics: {
    manage: 'In this section, HubMeta can provide a management view to see how much reviewers have spent in each section.',
    detail: 'These data and charts provide an overview of the the time spent in different parts of the project.'

  },
  titleScreening: {
    filter: 'Filters are a handy tool in the screening section. Rather than going through thousands of articles one by one, you can use filters to quickly filter, accept or reject a certain category. These can be based on specific words in the title, a certain author, or the decisions recorded by reviewers in your project',
  },
  fullTextScreening: {
    overall: 'The full-text screening section works very similar to title screening, with the only difference being that here the full-text of the papers are analyzed to meet the inclusion criteria rather than just the title and abstract. ',
    reason: 'The reasons for rejecting an article can be selected here. The values are set in project settings under “screening categories”'
  },
  ARM: {
    overall: 'HubMeta has an Author Relationship Management. System where you can specify papers and authors which need to be contacted. Such cases usually happens when a paper has gathered data but have not reported it in their paper or some further details are needed but are not available',

  },
  importArticle: {
    overall: 'In this page, you can see a list of your project files that have been imported. You can also so what percentage of papers from each file were kept (as they were not considered a duplicate) after deduplication. By clicking the green button, you can download these files.',
    import: 'You can upload files you have downloaded from a database here. First, select the source they were downloaded from (e.g. Web of Science), then enter the search parameters that were used, along with the date of search and any tags that you want to attach to that file. These information are not required, but entering them is a good way to keep track of your search so that you can report them in the paper later.',
    importFile: 'You can import articles into HubMeta if they have a .ris or .bib extension.  To upload PDFs of the same .ris file, first export them from EndNote library into a .zip file and then upload it here. ',
    manualImport: 'You can choose to enter an article record manually by entering its information here.'
  },
  members: {
    overall: `In this page you can manage your project members. You can invite members (who should already have a HubMeta account) and add them as either a Research or Reviewer.
Researchers have access to all sections of the project and can add/edit and accept or reject papers in title screening and full-text screening. Reviewers have limited access and can only add comments and yes/no/maybe votes in screening sections, and enter data in the data entry section`
  },
  analysis: {
    type: 'Using correlational data entered in data entry, HubMeta can provide various types of analyses ',
    bivariateMetaAnalysis: 'Bivariate Meta-analysis runs a meta-analysis between two variables of interest. This is useful when you want to look at the meta-analysis of only two variables',
    bivariateMetaAnalysisTable: 'Bivariate meta-analysis table, creates a table including all variables you select. Each row includes meta-analytic results for two variables selected from the set you have chosen. The results can be exported to a .csv file to be reported.',
    metaAnalyticCorrelationTable: 'This creates a meta-analytic correlation table. Each cell in this matrix includes a meta-analysis between the two variables in that row and column. This table is used to run Meta-analytic Structural Equation Modelling (MASEM) and Path Analysis.',
    method: 'You can choose the R library which is used to analyze your data and produce meta-analysis results. At the moment, you can choose either the Psychmeta (Dahlke & Wiernik, 2019) or HubMeta library which uses Schmidt & Hunter (2015)’s meta-analytic methods for calculations (https://github.com/hubmeta/R).',
    filter: 'Filters in this section act like filters in the “Data Entry” section. When you specify a filter here, HubMeta will run the analysis only on the filtered set of papers. For example, you can run your analysis once using papers Published after 2000, and once with papers before 2000.',
    significancy: 'You can also specify the the significance level for calculations of Confidence Intervals and Credibility Intervals.',
    selectVariables: 'In the analysis section, you need to select that variables or variable sets you want to include in the analysis. Also, you need to specify the Distance for outlier detection, which means how many standard deviations a value should be different from the mean to be detected as an outlier when analysis results are shown. Note that, this is used just for detecting outliers and HubMeta will not remove them automatically. You will have a chance to handle outliers when the results are shown.',
    groupSelectVars: 'You can add variables one by one or by choosing from the variable sets you have defined in your workspace to enter them into analysis',
    result: 'In this page the meta-analytic correlation table is shown. You can click on each cell to see details of how that number is calculated and what pair of variables from which articles where used in the calculations.',
    correction: 'You can choose to view values calculated with or without correction for reliability.',
    RCodeAction: 'You can use this option to recreate the code HubMeta uses to calculate meta-analysis results in R. This allows you to test the results using other packages or your own calculations.',
    RCode: 'You can view the R code which is used to calculate the meta-analysis of the selected pairs here',
    downloadRCode: 'You can download the R code used to calculate the meta-analysis results shown in this line',
    exportCSV: 'You can export the results into a CSV file to be reported in the paper.',
    variablePairs:
      `Each cell in the correlational meta-analytic table is a meta-analysis on its own. When you click on it, this pop up window opens up which shows the variable pairs from different papers that were used in this calculation. You can download the code in R to recalculate these values or use another library or formula for these calculations.
To handle outliers, HubMeta calculates the SAMD and SAMDz values using … method. You can deselect pairs which have a high (or low) SAMD value to remove outliers and recalculate your table with outliers removed.`,
    mainResult: 'These are the main results of the meta-analysis.',
  },
  insights : {
    overall: 'This is the overall part of insights',
  }
};
