import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { AiVariable, Moderator } from '@shared/schema/project';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-ai-variabels',
  templateUrl: './filter-ai-variabels.component.html',
  styleUrls: ['./filter-ai-variabels.component.scss']
})
export class FilterAiVariabelsComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() projectId: number;

  aiVariables: AiVariable[];
  subscription: Subscription[] = [];
  constructor(
    private builder: FormBuilder,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {

    this.setSubscription();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  setSubscription() {
    this.subscription.push(this.subscribeAiVariables());
  }
  subscribeAiVariables() {
    return this.store
      .select((state) => state.project.aiVariables)
      .subscribe((state) => {
        if (state) {
          if (state && state.data.length > 0) {
            const aiVariables = state.data.filter(x => x);
            this.aiVariables = aiVariables
              .sort((a, b) => b.id - a.id)
              .sort((a, b) => b.var_type - a.var_type);
            this.addModeratorFormArray();
          }
        }
      });
  }
  addModeratorFormArray(): void {
    const aiVariableFormArray = this.form.get('aiVariables') as FormArray;
    if (this.aiVariables) {
      aiVariableFormArray.clear(); // i dont undrestand why we should clean it
      this.aiVariables.forEach((item) => {
        aiVariableFormArray.push(this.createAiVariables(item));
      });
    }

  }
  createAiVariables(item: AiVariable): FormGroup {
    return this.builder.group({
      pk: [item.id],
      name: [item.name],
      type: [item.var_type],
      default: [item.default],
      aiVariableValue: [''],
    });
  }

  get aiVariablesArray(): FormArray {
    return this.form.get('aiVariables') as FormArray;
  }

}
