import { createAction, props } from '@ngrx/store';
import { FilterPapers } from '@shared/schema/filter-project';
import {
  DeduplicationReviewList,
  ImportArticleStatusList,
  ProjectActivityItem,
  ProjectDetails,
  ProjectMemberList,
  PrismaGraphData,
  ProjectVariable,
  ProjectMember,
  ProjectRanges,
  Moderator,
  FilterSet,
  PaperList,
  CorrelationVariable,
  CorrelationVariableList,
  ProjectVariablesQueryParams,
  Citation,
  CitationList,
  Tag,
  TagList,
  ModeratorList,
  VariablesGroup,
  VariablesGroupToCreate,
  ProjectPermissionList,
  CorrelationVariableToPost,
  VariablesGroupQueryParams,
  SearchPapersAndVariablesResult,
  AnalysisFlow,
  AnalysisResult,
  HistoryQueryParams,
  BatchFileList,
  PaperCommentList,
  PaperHistory,
  PaperHistoryList,
  ImportArticleFilterParams,
  ImportArticle,
  NewProjectMember,
  Toast,
  PaperFilter,
  AiVariable,
  AiVariableList,
} from '@shared/schema/project';
import { AnalysisResultItem } from '@shared/schema/project.statistic';
import { TitleScreeningReviewerStatistic, ProjectStatistics, CorrelationOutcomeStatistic } from '@shared/schema/statistic';

export const loadProject = createAction(
  '[Project] Load Project',
  props<{ projectId: number }>()
);

export const loadProjectSuccess = createAction(
  '[Project] Load Project Success',
  props<{ selectedProject: ProjectDetails }>()
);

export const loadProjectFailure = createAction(
  '[Project] Load Project Failure',
  props<{ error: any }>()
);

// Article List
export const loadArticleList = createAction(
  '[Project] Load Import Status List',
  props<{ params: ImportArticleFilterParams }>()
);

export const loadArticleListSuccess = createAction(
  '[Project] Load Import Status List Success',
  props<{ statusList: ImportArticleStatusList }>()
);

export const loadArticleListFailure = createAction(
  '[Project] Load Import Status List Failure',
  props<{ error: any }>()
);
export const getProjectPermissions = createAction(
  '[Project] Get Project Permissions',
  props<{ projectId: number, page?: number, page_size?: number }>()
);
export const getProjectPermissionsSuccess = createAction(
  '[Project] Get Project Permissions Success',
  props<{ projectPermissionList: ProjectPermissionList }>()
);
export const addMemberToProject = createAction(
  '[Project] Add Member',
  props<{ member: NewProjectMember }>()
);
export const loadPrismaData = createAction(
  '[Project] Load Prisma Data',
  props<{ projectId: number }>()
);
export const loadPrismaDataSuccess = createAction(
  '[Project] Load Prisma Data Success',
  props<{ prismaGraphData: PrismaGraphData }>()
);
export const loadPrismaDataError = createAction(
  '[Project] Load Prisma Data Error',
  props<{ error: any }>()
);
export const loadProjectVariables = createAction(
  '[Project] Load Project Variables',
  props<{ projectId: number }>()
);
export const loadProjectVariablesSuccess = createAction(
  '[Project] Load Project Variables Success',
  props<{ variables: ProjectVariable[] }>()
);
export const loadVisualData = createAction(
  '[Project] Load Visual Data',
  props<{ projectId: number }>()
);
export const loadVisualDataSuccess = createAction(
  '[Project] Load Visual Data Success',
  props<{ visualGraphData: ProjectVariable[] }>()
);
export const loadVisualDataError = createAction(
  '[Project] Load Visual Data Error',
  props<{ error: any }>()
);
// Article List
export const loadActivityList = createAction(
  '[Project] Load Activity List',
  props<{ projectId: number }>()
);

export const loadActivityListSuccess = createAction(
  '[Project] Load Activity List Success',
  props<{ activityList: ProjectActivityItem[] }>()
);

export const loadActivityListFailure = createAction(
  '[Project] Load Activity List Failure',
  props<{ error: any }>()
);
// Deduplication Review List
export const loadDeduplicationReviewList = createAction(
  '[Project] Load Deduplication Review List',
  props<{ projectId: number, page: number, page_size: number }>()
);

export const loadDeduplicationReviewListSuccess = createAction(
  '[Project] Load Deduplication Review List Success',
  props<{ reviewList: DeduplicationReviewList }>()
);

export const loadDeduplicationReviewFailure = createAction(
  '[Project] Load Deduplication Review List Failure',
  props<{ error: any }>()
);

// Project Member List
export const loadMemberList = createAction(
  '[Project] Load Member List',
  props<{ projectId: number, page?: number, page_size?: number }>()
);

export const loadMemberListSuccess = createAction(
  '[Project] Load Member List Success',
  props<{ memberList: ProjectMemberList }>()
);

export const loadMemberListFailure = createAction(
  '[Project] Load Member List Failure',
  props<{ error: any }>()
);

// Project Title Screening List
export const loadTitleScreeningList = createAction(
  '[Project] Load Title Screening List ',
  props<{filterSet: FilterSet}>()
);

export const loadTitleScreeningListSuccess = createAction(
  '[Project] Load Title Screening List  Success',
  props<{ reviewerList: PaperList }>()
);

export const loadTitleScreeningListFailure = createAction(
  '[Project] Load Title Screening List  Failure',
  props<{ error: any }>()
);

// Project Title Screening Reviewer Statistic
export const loadTitleScreeningReviewerStatistic = createAction(
  '[Project] Load Title Screening Reviewer Statistic',
  props<{ projectId: number }>()
);

export const loadTitleScreeningReviewerStatisticSuccess = createAction(
  '[Project] Load Title Screening Reviewer Statistic Success',
  props<{ statistics: TitleScreeningReviewerStatistic }>()
);

export const loadTitleScreeningReviewerStatisticFailure = createAction(
  '[Project] Load Title Screening Reviewer Statistic Failure',
  props<{ error: any }>()
);

// All Project Members
// export const loadAllMembers = createAction(
//   '[Project] Load All Project Members',
//   props<{ projectId: number }>()
// );

// export const loadAllMembersSuccess = createAction(
//   '[Project] Load TAll Project Members Success',
//   props<{ members: ProjectMember[] }>()
// );

// export const loadAllMembersFailure = createAction(
//   '[Project] Load All Project Members Failure',
//   props<{ error: any }>()
// );
// Project Full Text Screening List
export const loadFullText = createAction(
  '[Project] Load Full Text Screening',
  props<{filterSet: FilterSet}>()
);

export const loadFullTextSuccess = createAction(
  '[Project] Load Full Text Screening Success',
  props<{ fullText: PaperList }>()
);

export const loadFullTextFailure = createAction(
  '[Project] Load Full Text Screening Failure',
  props<{ error: any }>()
);
// Project Ranges
export const loadProjectRanges = createAction(
  '[Project] Load Project Ranges',
  props<{ projectId: number }>()
);

export const loadProjectRangesSuccess = createAction(
  '[Project] Load Project Ranges Success',
  props<{ ranges: ProjectRanges }>()
);

export const loadProjectRangesFailure = createAction(
  '[Project] Load Project Ranges Failure',
  props<{ error: any }>()
);
// Project Moderators
export const loadModerators = createAction(
  '[Project] Load Project Moderators',
  props<{ projectId: number }>()
);

export const loadModeratorsSuccess = createAction(
  '[Project] Load Project Moderators Success',
  props<{ moderators: Moderator[] }>()
);

export const loadModeratorsFailure = createAction(
  '[Project] Load Project Moderators Failure',
  props<{ error: any }>()
);

// Project AiVariable
export const loadAiVariables = createAction(
  '[Project] Load Project AiVariable',
  props<{ projectId: number }>()
);

export const loadAiVariablesSuccess = createAction(
  '[Project] Load Project AiVariable Success',
  props<{ aiVariables: AiVariableList }>()
);

export const loadAiVariablesFailure = createAction(
  '[Project] Load Project AiVariable Failure',
  props<{ error: any }>()
);
// Project Paper List
export const loadPaperList = createAction(
  '[Project] Load Paper List',
  props<{filterSet: FilterSet}>()
);

export const loadPaperListSuccess = createAction(
  '[Project] Load Paper List Success',
  props<{ paperList: PaperList }>()
);

export const loadPaperListFailure = createAction(
  '[Project] Load Paper List Failure',
  props<{ error: any }>()
);
// Project Paper DataEntry List
export const loadDataEntryPaper = createAction(
  '[Project] Load Paper DataEntry List',
  props<{filterSet: FilterSet}>()
);

export const loadDataEntryPaperSuccess = createAction(
  '[Project] Load Paper DataEntry List Success',
  props<{ paperList: PaperList }>()
);

export const loadDataEntryPaperFailure = createAction(
  '[Project] Load Paper DataEntry List Failure',
  props<{ error: any }>()
);
// Add Paper List Filter
// export const loadPaperListFilter = createAction(
//   '[Project] Load Paper List Filter',
//   props<{ filter: FilterSet }>()
// );
export const loadAnalysisFlow = createAction(
  '[Project] Load Analysis flow data',
  props<{ flow: AnalysisFlow }>()
);
export const loadAnalysisResult = createAction(
  '[Project] Load Analysis result data',
  props<{ result: AnalysisResultItem[] }>()
);
export const loadAnalysisTaskId = createAction(
  '[Project] Load Analysis Task Id',
  props<{ result: string }>()
);
export const loadProjectVariableList = createAction(
  '[Project] Load Project Variable List',
  props<{ variableQueryParams: ProjectVariablesQueryParams, reportProgress?: boolean }>()
);
export const loadProjectVariableListSuccess = createAction(
  '[Project] Load Project Variable List Success',
  props<{ variables: CorrelationVariableList }>()
);
export const createProjectVariable = createAction(
  '[Project] Create Project Variable',
  props<{ variable: CorrelationVariableToPost }>()
);
export const createProjectVariableSuccess = createAction(
  '[Project] Create Project Variable Success',
  props<{ variable: CorrelationVariable | VariablesGroup | Moderator }>()
);
export const getProjectVariableDetail = createAction(
  '[Project] Get Project Variable Detail',
  props<{ variableId: number }>()
);
export const getProjectVariableDetailSuccess = createAction(
  '[Project] Get Project Variable Detail Success',
  props<{ variableDetail: CorrelationVariable }>()

);
export const updateProjectVariable = createAction(
  '[Project] Update Project Variable',
  props<{ variable: CorrelationVariableToPost }>()
);
export const updateProjectVariableSuccess = createAction(
  '[Project] Update Project Variable Success',
  props<{ variable: CorrelationVariable | Moderator | VariablesGroup | Citation }>()
);
export const deleteProjectVariable = createAction(
  '[Project] Delete Project Variable',
  props<{ variableId: number }>()
);
export const deleteListOfProjectVariable = createAction(
  '[Project] Delete List Of Project Variable',
  props<{ variableId: number[] }>()
);
export const getCitations = createAction(
  '[Project] Get Citations',
  props<{ queryParams: ProjectVariablesQueryParams, reportProgress: boolean }>()
);
export const getCitationsSuccess = createAction(
  '[Project] Get Citations Success',
  props<{ citationList: CitationList }>()
);
export const createCitation = createAction(
  '[Project] Create Citations',
  props<{ citation: Citation }>()
);
export const createCitationSuccess = createAction(
  '[Project] Create Citations Success',
  props<{ citation: Citation }>()
);
export const getCitationDetail = createAction(
  '[Project] Get Citation Detail',
  props<{ id: number }>()
);
export const getCitationDetailSuccess = createAction(
  '[Project] Get Citation Detail Success',
  props<{ citation: Citation }>()
);
export const deleteCitation = createAction(
  '[Project] Delete Citation',
  props<{ id: number }>()
);
export const deleteListOfCitations = createAction(
  '[Project] Delete  List Of Citations',
  props<{ id: number[] }>()
);
export const updateCitation = createAction(
  '[Project] Update Citation',
  props<{ citation: Citation }>()
);
export const updateCitationSuccess = createAction(
  '[Project] Update Citation Success',
  props<{ citation: Citation }>()
);
export const getTags = createAction(
  '[Project] Get Tags',
  props<{ queryParams: ProjectVariablesQueryParams, reportProgress: boolean }>()
);
export const getTagsSuccess = createAction(
  '[Project] Get Tags Success',
  props<{ tagList: TagList }>()
);
export const createTags = createAction(
  '[Project] Create Tag',
  props<{ tags: string[], projectId: number }>()
);
export const createTagSuccess = createAction(
  '[Project] Create Tag Success',
  props<{ tagsId: number[] }>()
);
export const getModeratorList = createAction(
  '[Project] Get Moderator List',
  props<{ queryParams: ProjectVariablesQueryParams }>()
);
export const getModeratorListSuccess = createAction(
  '[Project] Get Moderator List Success',
  props<{ moderatorList: ModeratorList }>()
);
export const createModerator = createAction(
  '[Project] Create Moderator',
  props<{ moderator: Moderator }>()
);
export const getModeratorDetail = createAction(
  '[Project] Get Moderator Detail',
  props<{ moderatorId: number }>()
);
export const getModeratorDetailSuccess = createAction(
  '[Project] Get Moderator Detail Success',
  props<{ moderator: Moderator }>()
);
export const updateModerator = createAction(
  '[Project] Update Moderator ',
  props<{ moderator: Moderator }>()
);
export const deleteModerator = createAction(
  '[Project] Delete Moderator ',
  props<{ moderatorId: number }>()
);
export const deleteListOfModerators = createAction(
  '[Project] Delete List Of Moderator ',
  props<{ moderatorId: number[] }>()
);


export const getAiVariableList = createAction(
  '[Project] Get AiVariable List',
  props<{ queryParams: ProjectVariablesQueryParams }>()
);
export const getAiVariableListSuccess = createAction(
  '[Project] Get AiVariable List Success',
  props<{ aiVariableList: AiVariableList }>()
);
export const createAiVariable = createAction(
  '[Project] Create AiVariable',
  props<{ aiVariable: AiVariable }>()
);
export const getAiVariableDetail = createAction(
  '[Project] Get AiVariable Detail',
  props<{ aiVariableId: number }>()
);
export const getAiVariableDetailSuccess = createAction(
  '[Project] Get AiVariable Detail Success',
  props<{ aiVariable: AiVariable }>()
);
export const updateAiVariable = createAction(
  '[Project] Update AiVariable ',
  props<{ aiVariable: AiVariable }>()
);
export const deleteAiVariable = createAction(
  '[Project] Delete AiVariable ',
  props<{ aiVariableId: number }>()
);
export const deleteListOfAiVariables = createAction(
  '[Project] Delete List Of AiVariable ',
  props<{ aiVariableId: number[] }>()
);



export const loadVariablesGroups = createAction(
  '[Project] Load VariablesGroups',
  props<{ queryParams: VariablesGroupQueryParams }>()
);
export const loadVariablesGroupsSuccess = createAction(
  '[Project] Load VariablesGroups Success',
  props<{ variablesGroups: VariablesGroup[] }>()
);
export const createVariablesGroup = createAction(
  '[Project] Create VariablesGroup',
  props<{ variablesGroup: VariablesGroupToCreate }>()
);
export const getVariablesGroupDetail = createAction(
  '[Project] Get VariablesGroup Detail',
  props<{ id: number }>()
);
export const getVariablesGroupDetailSuccess = createAction(
  '[Project] Get VariablesGroup Detail Success',
  props<{ variablesGroup: VariablesGroup }>()
);
export const updateVariablesGroup = createAction(
  '[Project] Update VariablesGroup',
  props<{ variablesGroup: VariablesGroupToCreate }>()
);
export const deleteVariablesGroup = createAction(
  '[Project] Delete VariablesGroup',
  props<{ id: number }>()
);
export const deleteListOfVariablesGroup = createAction(
  '[Project] Delete List Of VariablesGroup ',
  props<{ id: number[] }>()
);
export const resetAlert = createAction(
  '[Project] Alert Reset'
);
export const searchPapersAndVariables = createAction(
  '[Project] Search Papers And Variables',
  props<{ searchQuery: string, projectId: number }>()
);
export const searchPapersAndVariableSuccess = createAction(
  '[Project] Search Papers And Variables Success',
  props<{ result: SearchPapersAndVariablesResult[] }>()
);
export const showActionSuccessMessage = createAction(
  '[Project] Action Success',
  props<{ successMessage: string }>()
);
export const showToastMessage = createAction(
  '[Project] Action Toast',
  props<{ toast: Toast }>()
);
export const announceActionFailure = createAction(
  '[Project] Action Failure',
  props<{ error: any }>()
);
export const loadProjectHistoryComments = createAction(
  '[Project] Load Project History Comments',
  props<{ query: HistoryQueryParams, reportProgress: boolean }>()
);
export const loadProjectHistoryPapers = createAction(
  '[Project] Load Project History Papers',
  props<{ query: HistoryQueryParams, reportProgress: boolean }>()
);
export const loadProjectHistoryBatchFiles = createAction(
  '[Project] Load Project History BatchFiles',
  props<{ query: HistoryQueryParams, reportProgress: boolean }>()
);
export const loadProjectHistoryCommentsSuccess = createAction(
  '[Project] Load Project History Comments Success',
  props<{ data: PaperCommentList }>()
);
export const loadProjectHistoryPapersSuccess = createAction(
  '[Project] Load Project History Papers Succcess',
  props<{ data: PaperList }>()
);
export const loadProjectHistoryBatchFilesSuccess = createAction(
  '[Project] Load Project History Batch Files Success',
  props<{ data: BatchFileList }>()
);
export const loadProjectPaperHistory = createAction(
  '[Project] Load Project Paper History',
  props<{ paperId: number, reportProgress: boolean }>()
);
export const loadProjectPaperHistorySuccess = createAction(
  '[Project] Load Project Paper History Success',
  props<{ data: PaperHistoryList }>()
);
export const changeAllPaperStatus = createAction(
  '[Project] Change All Papers Status',
  props<{ filter: FilterSet, paper: FilterSet, filterType: string }>()
);
export const changeAllPaperFullTextStatusSuccess = createAction(
  '[Project] Change All Full Text Papers Status Success',
  props<{filter: FilterSet}>()
);
export const changeAllPaperTitleScreeningStatusSuccess = createAction(
  '[Project] Change All Tile Screening Papers Status Success',
  props<{filter: FilterSet}>()
);
export const loadProjectNotification = createAction(
  '[Project] Load Project Notifications',
  props<{ query: HistoryQueryParams, reportProgress: boolean }>()
);
export const loadProjectNotificationSuccess = createAction(
  '[Project] Load Project Notifications Success',
  props<{notifications: [PaperCommentList, PaperList, BatchFileList ]}>()
);
export const resetProjectState = createAction(
  '[Project] reset All Project states Success'
);
export const getLastImportArticle = createAction(
  '[Project] Get Last Import Article',
  props<{projectId: number}>()
);
export const getLastImportArticleSuccess = createAction(
  '[Project] Get Last Import Article Success',
  props<{article: ImportArticle}>()
);
export const loadProjectStatistics = createAction(
  '[Project] Load Project Statistics',
  props<{projectId: number, section: string}>(),
);
export const loadProjectStatisticSucccess = createAction(
  '[Project] Load Project Statistics Success',
  props<{projectStatistics: ProjectStatistics}>(),
);
export const loadSavedPaperFilters = createAction(
  '[Project] Load Project Papers Filter',
  props<{projectId: number}>()
);
export const loadSavedPaperFiltersSuccess = createAction(
  '[Project] Load Project Papers Filter Success',
  props<{papersFilter: PaperFilter[]}>()
);
export const filterVariables = createAction(
  '[Project] Filter Variables',
  props<{queryParams: ProjectVariablesQueryParams}>()
);
export const filterVariablesSuccess = createAction(
  '[Project] Filter Variables Success',
  props<{variables: CorrelationVariableList}>()
);
export const setFilterPapers = createAction(
  '[Project] Set Filter Papers',
  props<{filter: FilterSet, filterType: string}>()
);
export const resetNewCorrelationValue = createAction(
  '[Project] Reset Correlation New Variable',
  props()
);

