import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-breadcrumb-tag',
  templateUrl: './breadcrumb-tag.component.html',
  styleUrls: ['./breadcrumb-tag.component.scss']
})
export class BreadcrumbTagComponent implements OnInit {
  @Input() tag: any;
  @Output() remove?: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
  }

  removeTag(id: number) {
    this.remove.emit(id);
  }

}
