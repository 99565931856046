import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Variable } from '@shared/schema/variable';
import { VariableNodes } from '@shared/schema/variable-nodes';
import {
  CorrelationVariable
} from '@shared/schema/project';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { loadProjectVariables } from '@modules/project/state/project.actions';
import { MatDialog } from '@angular/material';
import { ProjectService } from '@core/service/project.service';
import * as projectActions from '@modules/project/state/project.actions';
import { ActivatedRoute } from '@angular/router';
import { CreateNewVariableGroupComponent } from '@modules/project/components/create-new-variable-group/create-new-variable-group.component';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { guides } from '@shared/data/guides';
@Component({
  selector: 'app-variables-tree',
  templateUrl: './variables-tree.component.html',
  styleUrls: ['./variables-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VariablesTreeComponent implements OnInit, OnChanges , OnDestroy {
  @Input() projectId: number;
  @Input() disableItems: number[] = [];
  @Input() defaultSelectedVariablesGroup: CorrelationVariable[];
  @Input() editVariablesGroup = false;
  @Output() variablesChange = new EventEmitter<number[]>();
  @ViewChild('searchInput', null) searchInput: ElementRef;
  variables: Variable[] = [];
  openList = {};
  selectedVariables = {};
  breadcrumbItems = [];
  projectVariables = [];
  subscriptions: Subscription[];
  groupVariables;
  selectedLength = 0;
  private newVariableList;
  searchFormControl: FormControl = new FormControl();
  guide = {
    groupSelect: guides.analysis.groupSelectVars
  };
  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private dialog: MatDialog
  ) {
    this.searchFormControl.valueChanges.subscribe(
      val => {
        this.search(val);
      }
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }


  ngOnInit() {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }

    this.subscriptions = [
      this.store
        .select(state => state.project.variablesGroups)
        .subscribe(variablesGroups => {
          if (variablesGroups) {
            this.groupVariables = variablesGroups;
          } else {
            this.store.dispatch(projectActions.loadVariablesGroups({
              queryParams: { project: String(this.projectId) }
            }));
          }
        }),
      this.store
        .select(state => state.project.projectVariables)
        .subscribe(state => {
          if (state) {
            if (state.length > 0) {
              this.projectVariables = state;
              this.parseVisualizationData(state);
              if (this.defaultSelectedVariablesGroup !== undefined && this.defaultSelectedVariablesGroup !== null) {
                for (const variable of this.defaultSelectedVariablesGroup) {
                  this.selectedVariables[variable.id] = variable;
                  this.openClassForDefaultSelection(variable);
                  this.updateBreadcrumbList(variable, true);
                }
              }
              this.selectedLength = Object.keys(this.selectedVariables).length;
            }
          } else {
            this.store.dispatch(loadProjectVariables({ projectId: this.projectId }));
          }
        })
    ];
  }

  ngOnChanges(changes) {
    if (changes.disableItems && this.variables.length) {
      this.variables.map(item => {
        if (this.disableItems.includes(item.id)) {
          item.isDisable = true;
        } else {
          item.isDisable = false;
        }
      });
    } else if (changes.defaultSelectedVariablesGroup && changes.editVariablesGroup) {
      this.parseVisualizationData(this.projectVariables);
    }
  }

  search(value) {
    const getParents = (list: any[], id: number): any[] => {
      let result = list;
      const wanted = this.projectVariables.find(item => item.id === id);
      if (!list.map(item => item.id).includes(id) && wanted !== undefined && wanted !== null) {
        const family = this.projectVariables.filter(item => (
          (item.parent !== undefined && item.parent !== null && item.parent === wanted.id) ||
          (wanted.parent !== undefined && wanted.parent !== null && wanted.parent === item.id)
        ));
        result = [...result, wanted, ...family];
        for (const item of family) {
          result = getParents(result, item.id);
        }
      }
      return result;
    };
    const matched = this.projectVariables.filter(item => String(item.name).toLowerCase().includes(String(value).toLowerCase()));
    let data: any[] = [];
    for (const item of matched) {
      data = getParents(data, item.id);
    }
    const final = [];
    for (const item of data) {
      if (!final.map(elem => elem.id).includes(item.id)) {
        final.push(item);
      }
    }
    this.parseVisualizationData(final);
  }

  createGroup() {
    const dialogRef = this.dialog.open(CreateNewVariableGroupComponent, {
      width: '50%',
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined && result.length > 0) {
        this.projectService.createGroupVariables(
          {
            name: result,
            project: this.projectId,
            variables_id: Object.keys(this.selectedVariables).map(item => Number(item))
          }
        ).then(() => {
          this.store.dispatch(projectActions.loadVariablesGroups({
            queryParams: { project: String(this.projectId) }
          }));
        });
      }
    });
  }

  async parseVisualizationData(data) {
    const tempVars = [];
    for (const visualVar of data) {
      if (visualVar.parent === null) {
        const item = {
          name: visualVar.name,
          id: visualVar.id,
          type: { 1: 'M', 2: 'C', 3: 'GC' }[visualVar.var_type],
          children: null,
          isDisable: this.disableItems.includes(visualVar.id)
        };
        const children = this.getNodeChild(data, visualVar.id);
        if (children.length > 0) {
          item.children = children;
        }
        tempVars.push(item);
      }
    }
    this.variables = tempVars;
    this.newVariableList = new VariableNodes(tempVars);
  }


  getNodeChild(data, id) {
    const tempVars = [];
    for (const visualVar of data) {
      if (visualVar.parent === id) {
        const item = {
          name: visualVar.name,
          id: visualVar.id,
          type: { 1: 'M', 2: 'C', 3: 'GC' }[visualVar.var_type],
          children: null
        };
        const children = this.getNodeChild(data, visualVar.id);
        if (children.length > 0) {
          item.children = children;
        }
        tempVars.push(item);
      }
    }
    return tempVars;
  }


  toggleOpenClass(itemName: string) {
    this.openList[itemName]
      ? (this.openList[itemName] = false)
      : (this.openList[itemName] = true);
  }
  openClassForDefaultSelection(variable) {
    const parent = this.projectVariables.find(visual => visual.id === variable.parent);
    if (parent) {
      this.openList[parent.name] = true;
      if (parent.parent) {
        this.openClassForDefaultSelection(parent);
      }
    }
  }

  addTag(item: any) {
    if (item && item.name && this.searchFormControl.value &&
      item.name.toUpperCase().indexOf(this.searchFormControl.value.toUpperCase() > -1)
      ) {
      this.searchFormControl.patchValue('');
      this.searchInput.nativeElement.focus();
    }
    this.selectedVariables[item.id] ? delete this.selectedVariables[item.id] : this.selectedVariables[item.id] = item;
    this.selectedLength = Object.keys(this.selectedVariables).length;
    this.updateBreadcrumbList(item, false);
    this.variablesChange.emit(Object.keys(this.selectedVariables).map(Number));
  }

  async updateBreadcrumbList(variable, forceAdd: boolean) {
    const index = this.breadcrumbItems.findIndex((i) => i.id === variable.id);
    if (index > -1) {
      if (!forceAdd) {
        this.breadcrumbItems.splice(index, 1);
      }
      return;
    }
    this.breadcrumbItems.push({
      id: variable.id,
      ...this.newVariableList.getParents(variable.id),
    });
  }

  removeTag(id) {
    const index = this.breadcrumbItems.findIndex((i) => i.id === id);
    if (index === -1) {
      return;
    }
    delete this.selectedVariables[id];
    this.selectedLength = Object.keys(this.selectedVariables).length;
    this.breadcrumbItems.splice(index, 1);
    this.variablesChange.emit(Object.keys(this.selectedVariables).map(item => Number(item)));
  }

  applyGroup(index) {
    this.selectedVariables = {};
    this.breadcrumbItems = [];
    this.groupVariables[index].variables.map((item) => {
      if (!this.disableItems || !this.disableItems.includes(item.id)) {
        this.selectedVariables[item.id] = item;
        this.updateBreadcrumbList(item, false);
      }
    });
    this.selectedLength = Object.keys(this.selectedVariables).length;
    this.variablesChange.emit(Object.keys(this.selectedVariables).map(item => Number(item)));

  }
  handleKeyUp(event) {
    if (event && event.code === 'Backspace') {
      if (this.breadcrumbItems && this.breadcrumbItems.length) {
        const lastVar = this.breadcrumbItems[this.breadcrumbItems.length - 1];
        if (lastVar) {
          this.removeTag(lastVar.id);
        }
      }
    } else if (event && event.code === 'Enter') {
      if (this.variables && this.variables.length) {
        this.addTag(this.variables[0]);
      }
    }
  }
}
