import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doi2libkey'
})
export class Doi2libkeyPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value.replace('https://doi.org/', 'http://libkey.io/');
  }

}
