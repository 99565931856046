import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UserInfo } from '@shared/schema/profile';
import { Subscription } from 'rxjs';
import { FilterUserAssignmentDialogComponent } from '../filter-user-assignment-dialog/filter-user-assignment-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';

@Component({
  selector: 'app-filter-user-assignment',
  templateUrl: './filter-user-assignment.component.html',
  styleUrls: ['./filter-user-assignment.component.scss']
})
export class FilterUserAssignmentComponent implements OnInit, OnDestroy {

  @Input() projectId: number;
  @Input() form: FormGroup;

  subscription: Subscription[] = [];
  userAssignmentList = [];
  users: UserInfo[] = [];


  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.setSubscription();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  setSubscription() {
    this.subscription.push(this.subscribeMembers());
    this.subscription.push(this.subscribeUserAssignment());
  }


  subscribeMembers() {
    return this.store.select(state => state.project.memberList).subscribe((allMembers) => {
      if (allMembers && allMembers.data) {
        this.users = allMembers.data
          .filter(item => item.role === null)
          .map(item => item.user);
      }
    });
  }

  subscribeUserAssignment() {
    return this.form.get('userAssignment').valueChanges.subscribe(value => {
      this.userAssignmentList = this.preaperDatatoShow(value);
    });
  }

  openFilterUserAssignment() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '50%',
      maxHeight: '80vh',
      data: {
        projectId: this.projectId,
        userAssignment: this.form.get('userAssignment').value,
        users: this.users
      },
    };
    const dialogRef = this.dialog.open(FilterUserAssignmentDialogComponent, config);
    dialogRef.afterClosed().subscribe((data: { userId: number, roleId: number, stageId: number }[]) => {
      if (data) {
        (this.form.get('userAssignment') as FormArray).clear();
        data.forEach(item => {
          (this.form.get('userAssignment') as FormArray).push(new FormControl(item));
        });
      }
    });
  }

  preaperDatatoShow(data: { userId: number, role: number, stage: number }[]) {
    return data.map((item) => {
      return {
        full_name: this.users.find(v => v.user_id === item.userId).full_name,
        role: getRole(item.role[0]),
        stage: getStage(item.stage[0]),
      };
    });
  }
}
function getRole(roleId: number): any {
  switch (roleId) {
    case 1:
      return 'Reviwer';
      break;
    case 2:
      return 'Researcher';
      break;
    case 3:
      return 'Importer';
      break;
    default:
      return null;
      break;
  }
}

function getStage(stageId: number): any {
  switch (stageId) {
    case 4:
      return 'Title Screening';
      break;
    case 6:
      return 'Full Text Screening';
      break;
    case 7:
      return 'Data Entry';
      break;
    default:
      return null;
      break;
  }
}

