import { NbMenuItem } from '@nebular/theme';
import { MenuItem } from '@shared/schema/menu-item';
export const ProjectMenu: MenuItem[] = [
  {
    title: 'Overview',
    icon: 'icon-icons-th-large',
    elementId: 'overview',
    expanded: true,
    hasChildren: true,
    children: [
      {
        title: 'Details',
        link: 'details',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'details',
      },
      // {
      //   title: 'Activity',
      //   link: 'activity',
      //   pathMatch: 'prefix',
      //   hasChildren: false,
      //   elementId: 'activity',
      // },
      // {
      //   title: 'Analytics',
      //   link: 'analytic',
      //   pathMatch: 'prefix',
      //   hasChildren: false,
      //   elementId: 'analytics',
      // },
      {
        title: 'ARM',
        link: 'contacts',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'arm',
      },
    ],
  },
  {
    title: 'Import Articles',
    icon: 'icon-icons-book-alt',
    elementId: 'import_articles',
    expanded: true,
    hasChildren: true,
    children: [
      {
        title: 'Files',
        link: 'import-articles/files',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'files',
      },
      {
        title: 'Papers',
        link: 'import-articles/add-paper',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'papers',
      },
      {
        title: ' Review Deduplication',
        link: 'import-articles/deduplication',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'deduplication',
      }
    ],
  },

  {
    title: 'Title Screening',
    icon: 'icon-icons-document-layout-left',
    link: 'title-screening',
    hasChildren: false,
    elementId: 'title_screening',
  },
  {
    title: 'Full Text Screening',
    icon: 'icon-icons-align-left-justify',
    link: 'full-text-screening',
    hasChildren: false,
    elementId: 'fulltext_screening',
  },

  {
    title: 'Taxonomy',
    icon: 'icon-icons-palette',
    elementId: 'workspace',
    expanded: false,
    hasChildren: true,
    children: [
      {
        title: 'Files',
        link: 'taxonomy/files',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_files',
      },
      {
        title: 'Group Construct',
        link: 'taxonomy/group-construct',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_group_construct',
      },
      {
        title: 'Construct',
        link: 'taxonomy/construct',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_construct',
      },
      {
        title: 'Measurement',
        link: 'taxonomy/measurement',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_measurement',
      },
      {
        title: 'Moderators Variable',
        link: 'taxonomy/moderators',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_moderators',
      },
      {
        title: 'AI Variable',
        link: 'taxonomy/ai-variable',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_ai_variable',
      },
      {
        title: 'Citation',
        link: 'taxonomy/citation',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_citation',
      },
      {
        title: 'Variable sets',
        link: 'taxonomy/variable-set',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_variable_set',
      },
      {
        title: 'Tags',
        link: 'taxonomy/tags',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_tags',
      },
      {
        title: 'Visualization',
        link: 'taxonomy/visualization',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'taxonomy_visualization',
      }
    ],
  },


  {
    title: 'Data Entry',
    icon: 'icon-icons-file-download-alt',
    link: 'data-entry',
    hasChildren: false,
    elementId: 'data_entry',
  },
  {
    title: 'Analysis',
    icon: 'icon-icons-chart',
    link: 'analysis',
    hasChildren: false,
    elementId: 'analysis',
  },
  {
    title: 'Settings',
    icon: 'icon-icons-cog',
    elementId: 'setting',
    expanded: true,
    hasChildren: true,
    children: [
      {
        title: 'General',
        link: 'general',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'general',
      },
      {
        title: 'Members',
        link: 'members',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'members',
      },
      {
        title: 'Screening Categories',
        link: 'reasoning',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'screening_category',
      },
    ],
  },
  {
    title: 'Insights',
    icon: 'icon-icons-chart',
    elementId: 'insights',
    expanded: false,
    hasChildren: true,
    children: [
      {
        title: 'Contributions',
        link: 'insights/contributions',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'contributions',
      },
      {
        title: 'Analytics',
        link: 'analytic',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'analytics',
      },
    ],
  },
  {
    title: 'Activity',
    icon: 'icon-icons-chart',
    elementId: 'activity',
    expanded: false,
    hasChildren: true,
    children: [
      {
        title: 'Logs',
        link: 'activity/logs',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'logs',
      },
      {
        title: 'Tasks',
        link: 'activity/tasks',
        pathMatch: 'prefix',
        hasChildren: false,
        elementId: 'tasks',
      },
    ],
  }
];

export const NebularMenu: NbMenuItem[] = [
  {
    title: 'Overview',
    icon: { icon: 'icon-icons_th-large', pack: 'icomoon' },
    expanded: true,
    children: [
      {
        title: 'Details',
        link: 'details',
        pathMatch: 'prefix',
      },
      {
        title: 'Activity',
        link: 'activity',
        pathMatch: 'prefix',
      },
    ],
  },
  {
    title: 'Import Articles',
    icon: { icon: 'icon-icons_book-alt', pack: 'icomoon' },
    link: 'import-articles',
    pathMatch: 'prefix',
  },
  {
    title: 'Title Screening',
    icon: { icon: 'icon-icons_document-layout-left', pack: 'icomoon' },
    link: 'title-screening',
    pathMatch: 'prefix',
  },
  {
    title: 'Full Text Screening',
    icon: { icon: 'icon-icons_align-left-justify', pack: 'icomoon' },
    link: 'full-text-screening',
    pathMatch: 'prefix',
  },
  {
    title: 'Data Entry',
    icon: { icon: 'icon-icons_file-download-alt', pack: 'icomoon' },
    link: 'data-entry',
    pathMatch: 'prefix',
  },
  {
    title: 'Analysis',
    icon: { icon: 'icon-icons_chart', pack: 'icomoon' },
    link: 'analysis',
    pathMatch: 'prefix',
  },
];

// export const SettingMenu: MenuItem[] = [
//   {
//     title: 'Setting',
//     icon: 'icon-icons_th-large',
//     elementId: 'setting',
//     expanded: true,
//     hasChildren: true,
//     children: [
//       {
//         title: 'General',
//         link: 'general',
//         pathMatch: 'prefix',
//         hasChildren: false,
//         elementId: 'general',
//       },
//       {
//         title: 'Members',
//         link: 'members',
//         pathMatch: 'prefix',
//         hasChildren: false,
//         elementId: 'members',
//       },
//       {
//         title: 'Workspace',
//         link: 'workspace',
//         pathMatch: 'prefix',
//         hasChildren: false,
//         elementId: 'workspace',
//       },
//     ],
//   }
// ];
