import { NgModule } from '@angular/core';
import {
  MatBadgeModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule,
  MatButtonModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatTableModule,
  MatSortModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule
} from '@angular/material';
import {ScrollingModule} from '@angular/cdk/scrolling';


@NgModule({
  imports: [
    MatChipsModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatBadgeModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule
  ],
  exports: [
    MatChipsModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatBadgeModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatAutocompleteModule
  ],
  declarations: [],
  providers: [],
})
export class MaterialModule {
}
