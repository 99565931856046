import {Component, Input, OnInit} from '@angular/core';
import {environment} from '@env';

@Component({
  selector: 'app-contribution-ratio',
  templateUrl: './contribution-ratio.component.html',
  styleUrls: ['./contribution-ratio.component.scss']
})
export class ContributionRatioComponent {

  @Input() image: string;
  @Input() percent: number;
  @Input() tooltipText: string;
  constructor() { }

}
