import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() buttonName: string;
  @Input() buttonIcon: string;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  buttonClicked() {
    this.clicked.emit(true);
  }

}
