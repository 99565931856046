import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserStorageService } from '@core/service/browser-storage.service';
import { environment } from '@env';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ForgetPassword,
  LoginInfo,
  LoginResponse,
  NewUser,
  RegisterResponse,
  ResetPassword, VerifyAccount
} from '@shared/schema/authentication';
import { UserList } from '@shared/schema/profile';
import { AppRuntimeConfig, CONFIG } from './config.service';

@Injectable()
export class AuthenticationService {
  private HMToken;
  private HMRToken;
  private HMExpire;

  constructor(
    @Inject(CONFIG) private appRuntimeConfig: AppRuntimeConfig,
    private http: HttpClient,
    private storage: BrowserStorageService,
  ) { }

  get hubmetaToken() {
    if (!this.HMToken) {
      this.HMToken = this.storage.get('HMToken');
    }
    return this.HMToken;
  }

  set hubmetaToken(token) {
    if (!token) {
      this.HMToken = null;
      this.storage.remove('HMToken');
      return;
    }
    this.HMToken = token;
    this.storage.set('HMToken', token);
  }

  get hubmetaRefreshToken() {
    if (!this.HMRToken) {
      this.HMRToken = this.storage.get('HMRToken');
    }
    return this.HMRToken;
  }

  set hubmetaRefreshToken(token) {
    if (!token) {
      this.HMRToken = null;
      this.storage.remove('HMRToken');
      return;
    }
    this.HMRToken = token;
    this.storage.set('HMRToken', token);
  }

  get hubmetaExpire() {
    if (!this.HMExpire) {
      this.HMExpire = this.storage.get('HMExpire');
    }
    return this.HMExpire;
  }

  set hubmetaExpire(date) {
    this.HMExpire = date;
    this.storage.set('HMExpire', date);
  }

  async oauth(token: string, provider: string): Promise<LoginResponse> {
    const response = (await this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/oauth/${provider}/`, {
      access_token: token
    }).toPromise()) as LoginResponse;
    this.hubmetaToken = response.access;
    this.hubmetaRefreshToken = response.refresh;
    return response;
  }
  // Login Service with Email
  async login(info: LoginInfo): Promise<LoginResponse> {
    const response =
    (await this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/login/`, info).toPromise()) as LoginResponse;
    this.setCredential(response);
    return response;
  }
  setCredential(credential: any) {
    this.hubmetaToken = credential.access;
    this.hubmetaRefreshToken = credential.refresh;
    const date = new Date(credential.expire_date);
    this.hubmetaExpire = date.toString();
  }
  // Register with Email
  // async register(user: NewUser): Promise<RegisterResponse> {
  //   const response = (
  //     await this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/register/`, user).toPromise()
  //   ) as RegisterResponse;
  //   return response;
  // }
  register(user: NewUser): Observable<RegisterResponse> {
      return this.http.post<RegisterResponse>
      (`${this.appRuntimeConfig.api_url}/auth/authentication/register/`, user);
  }
  // Forget Password with Email
  async forgetPassword(info: ForgetPassword): Promise<any> {
    return (await this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/forget_password/`, info).toPromise()) as any;
  }
  // Reset Password
  async resetPassword(token: string, info: ResetPassword): Promise<LoginResponse> {
    return (
      await this.http.patch(`${this.appRuntimeConfig.api_url}/auth/authentication/reset_password/${token}/`, info).toPromise()
    ) as LoginResponse;
  }
  ResendEmail({ email }: { email: string }): Observable<any> {
    return this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/resend_verification/`, { email });
  }
  // Reset Password
  // async refreshToken(): Promise<{ access: string }> {
  //   const response = await this.http.post(
  //     `${this.appRuntimeConfig.api_url}/auth/authentication/refresh/`, { refresh: this.hubmetaRefreshToken }
  //   ).toPromise() as { access: string, expire_date: string };
  //   this.hubmetaToken = response.access;
  //   const date = new Date(response.expire_date);
  //   this.hubmetaExpire = date.toString();
  //   return response;
  // }
  refreshToken(): Observable<any> {
    return this.http.post(
      `${this.appRuntimeConfig.api_url}/auth/authentication/refresh/`, {refresh: this.hubmetaRefreshToken}
    );
  }
  // Verify Account
  async verifyEmail(info: VerifyAccount): Promise<any> {
    return (await this.http.post(`${this.appRuntimeConfig.api_url}/auth/authentication/verify/`, info).toPromise()) as any;
  }
  //  Search in system user
  getUsers(search: string, page?: number, pageSize?: number): Observable<UserList> {
    return this.http.get(
`${this.appRuntimeConfig.api_url}/auth/users/?search=${search}${page ? '&page=' + page : ''}${pageSize ? '&page_size=' + pageSize : ''}`,
      { reportProgress: true }
    ).pipe(
      map((result: UserList) => {
        return result;
      })
    );
  }

}
