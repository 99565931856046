import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/service/authentication.service';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { UserInfo } from '@shared/schema/profile';

@Component({
  selector: 'app-email-not-verified-warning',
  templateUrl: './email-not-verified-warning.component.html',
  styleUrls: ['./email-not-verified-warning.component.scss']
})
export class EmailNotVerifiedWarningComponent implements OnInit {
  @Input() user: UserInfo;
  constructor(
    private service: AuthenticationService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
  }
  resendEmail() {
    this.service.ResendEmail({email: this.user.email}).subscribe((res: any) => {
      this.showSuccessToast('Resend Email Success');
    });
  }
  showSuccessToast(message: string, title?: string) {
    this.toastrService.show(message, title ? title : '', {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      status: 'success',
      duration: 3000,
    });
  }

}
