import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { FilterPaperType } from '@shared/schema/filter-project';
import { ScreeningReason } from '@shared/schema/statistic';
import { ProjectMember, ProjectRanges } from '@shared/schema/project';
import { paperDecisionOptions } from '@shared/utils/papers-status';

import { map } from 'rxjs/operators';
import { concat, Observable, of, Subscription } from 'rxjs';
import { StatisticService } from '@core/service/statistic.service';
import { Ng5SliderService } from '../../../services/ng5-slider.service';
@Component({
  selector: 'app-filter-fulltext-reviwer-reviwe',
  templateUrl: './filter-fulltext-reviwer-reviwe.component.html',
  styleUrls: ['./filter-fulltext-reviwer-reviwe.component.scss']
})
export class FilterFulltextReviwerReviweComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() projectId: number;
  ranges: ProjectRanges;

  state = FilterPaperType.FULL_TEXT_SCREENING;
  adminLevel = false;

  /*------------------------------------
   * Observable list.
   * Uncubscribe automaticly
   * with Async Pipe in ui
  -------------------------------------*/
  screeningCategory$: Observable<any[]>;


  // ranges: ProjectRanges;
  reviewers: ProjectMember[] = [];


  reviewerDecisionFilter = paperDecisionOptions.filter(val => val.id !== 'accepted' && val.id !== 'rejected');

  /*--------------------------
   * DataSourses
   --------------------------*/
  reviewFilterOptions = [
    { id: 1, label: 'Reviewed' },
    { id: 2, label: 'Haven\'t reviewed yet' },
    { id: 3, label: 'All' },
  ];

  commentType: { name: string; value: number }[] = [
    {
      name: 'Yes',
      value: 1,
    },
    {
      name: 'No',
      value: 2,
    },
    {
      name: 'Maybe',
      value: 3,
    },
  ];

  discrepancyFilterOptions = [
    { id: null, label: 'All' },
    { id: 2, label: 'Reviewer Discrepancy' },
    { id: 1, label: 'Reviewer same opinion' },
  ];

  screeningCategories: ScreeningReason[];
  showCommentControl = false;
  subscription: Subscription[] = [];

    /*------------------------------------
   * [NG5SLIDER BUG]
   * slider should be reset afterOpened
   * otherwise this not whow range correctly
  -------------------------------------*/
  ng5SliderManualRefresh ;

  constructor(
    private store: Store<AppState>,
    private service: StatisticService,
    private slidersSevice: Ng5SliderService,
  ) {
    this.ng5SliderManualRefresh = this.slidersSevice.refresh;
   }



  ngOnInit() {
    this.setSubscription();
    this.setObservables();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  setObservables() {
  }

  setSubscription() {
    this.subscription.push(this.getProjectRanges());

  }

  getProjectRanges() {
    return this.store
      .select((state) => state.project.projectRanges)
      .subscribe((state) => {
        this.ranges = state;
      });
  }

  getScreeningCallbackFunction = (term: string): Observable<{
    num_of_pages: number;
    count: number;
    data: any[];
  }> => {
    return this.service.getScreeningReason(this.projectId, term)
      // TODO: Ask Backend to set pagination for this API
      .pipe(
        map((allScreeningReason: any[]) => {
          if (allScreeningReason && allScreeningReason.length > 0) {
            return allScreeningReason.map(item => {
              return {
                label: item.reason,
                value: item.id,
                screeningType: item.screening_type,
                priority: item.priority
              };
            })
              .sort((a, b) => b.priority - a.priority)
              .filter(item => item.screeningType === this.state);
          }
        }),
        map((screeningCategories: any) => {
          return {
            data: screeningCategories,
            count: screeningCategories.length,
            num_of_pages: 1
          };
        })
      );
  }
  // getScreeningCategory() {
  //   return concat(
  //     of([]),
  //     this.store.select(state => state.project.screeningReasons).pipe(
  //       map((allScreeningReason: any[]) => {
  //         if (allScreeningReason && allScreeningReason.length > 0) {
  //           return allScreeningReason.map(item => {
  //             return {
  //               label: item.reason,
  //               value: item.id,
  //               screeningType: item.screening_type,
  //               priority: item.priority
  //             };
  //           })
  //             .sort((a, b) => b.priority - a.priority)
  //             .filter(item => item.screeningType === this.state);
  //         }
  //       }),
  //     )
  //   );
  // }

  // subscribeReviwer() {
  //   return this.store
  //     .select(state => state.project.memberList)
  //     .subscribe(
  //       (allMembers) => {
  //         if (allMembers && allMembers.data) {
  //           this.reviewers = allMembers.data.filter(item => item.role === null);
  //         }
  //       });
  // }
}
