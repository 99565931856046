import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() maxPage = 0;
  @Input() defaultPageSize = 10;
  @Input() pageSizeFilter = false;
  @Input() paginationNumber = true;
  currentPage = 1;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() changePageSize: EventEmitter<number> = new EventEmitter<number>();
  pageList: number[] = [];
  shownPageList: number[] = [];
  filterList: number[] = [10, 25, 50, 100];
  pageNumberControl: FormControl;
  pageSizeControl: FormControl;
  constructor() {}

  ngOnInit() {
    this.pageNumberControl = new FormControl();
    const pageSize = this.filterList.find(val => val === +this.defaultPageSize);
    if (pageSize) {
      this.pageSizeControl = new FormControl(pageSize);
    } else {
      this.pageSizeControl = new FormControl(this.filterList[0]);
    }
    this.pageSizeControl.valueChanges.subscribe((val) =>
      this.pageSizeChange(val)
    );
  }

  currentPageChange(page: number) {
    this.currentPage = page;
    this.changePage.emit(page);
  }

  pageSizeChange(event) {
    this.changePageSize.emit(event);
    this.pageNumberControl.patchValue('');
  }

  goToPage() {
    const page = this.pageNumberControl.value;
    if (this.currentPage && this.currentPage.toString() === page) {
      return;
    }
    if (page && this.maxPage) {
      if (+page <= +this.maxPage && +page >= 0 ) {
        this.currentPage = page;
        this.changePage.emit(page);
      }
    }
  }
}
