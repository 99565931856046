import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: string, terms: string[], palette: string[]): any {
    let result = value;
    if (!terms) {
      return result;
    }
    const length = terms.length;
    for (let i = 0; i < length; i++) {
      const regex = new RegExp(terms[i] + '(?![^<>]*>)', 'ig');
      const found = result.match(regex);
      if (found) {
        result = result.replace(
          new RegExp(terms[i] + '(?![^<>]*>)', 'ig'),
          `<span class='highlight-${i % palette.length
          }' style='background-color: ${palette[i % palette.length]};'>${terms[i]}</span>`
        );

      }
      // result = result.replace(
      //   new RegExp(terms[i] + '(?![^<>]*>)', 'ig'),
      //   `<span class='highlight-${
      //     i % pollet.length
      //   }' style='background-color: ${pollet[i % pollet.length]};'>${terms[
      //     i
      //   ]}</span>`
      // );
      // result = result.replace(
      //   new RegExp(terms[i].toUpperCase() + '(?![^<>]*>)', 'g'),
      //   `<span class='highlight-${
      //     i % pollet.length
      //   }' style='background-color: ${pollet[i % pollet.length]};'>${terms[
      //     i
      //   ].toUpperCase()}</span>`
      // );
    }
    return result;
  }
}
