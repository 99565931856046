import { EventEmitter, Injectable } from '@angular/core';


@Injectable()
export class Ng5SliderService {
  ng5SliderRefresh: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  refreshSlider() {
    this.ng5SliderRefresh.emit();
  }
  get refresh() {
    return this.ng5SliderRefresh;
  }
}
