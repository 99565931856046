import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { UserInfo } from '@shared/schema/profile';
import { ProjectMember } from '@shared/schema/project';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-reviwer-comments',
  templateUrl: './filter-reviwer-comments.component.html',
  styleUrls: ['./filter-reviwer-comments.component.scss']
})
export class FilterReviwerCommentsComponent implements OnInit, OnDestroy {

  @Input() projectId: number;
  @Input() form: FormGroup;
  @Input() formControll: string;
  subscription: Subscription[] = [];
  userCommentTypeOptions:
    { name: string; value: number }[] = [
      {
        name: 'Yes',
        value: 1,
      },
      {
        name: 'No',
        value: 2,
      },
      {
        name: 'Maybe',
        value: 3,
      },
    ];

  reviewers: ProjectMember[] = [];

  constructor(
    private builder: FormBuilder,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.setSubscription();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }

  setSubscription() {
    this.subscription.push(this.subscribeMembers());
  }

  subscribeMembers() {
    return this.store.select(state => state.project.memberList).subscribe((allMembers) => {
      if (allMembers && allMembers.data) {
        this.reviewers = allMembers.data.filter(item => item.role === null);
        this.createReviewersCommentFormArray();
      }
    });
  }
  createReviewersCommentFormArray() {
    if (!this.reviewers || !this.reviewers.length) {
      return;
    }
    const userComments = this.form.get(this.formControll) as FormArray;
    userComments.clear(); // i dont undrestan why we have clear form controll
    this.reviewers.forEach(val => {
      userComments.push(this.createReviewerComment(val.user));
    });
  }

  createReviewerComment(user: UserInfo): FormGroup {
    return this.builder.group({
      userId: [user.user_id],
      value: []
    });
  }

  get reviewersCommentsFormArray() {
    return this.form.get(this.formControll) as FormArray;
  }

}
