import {  ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Sections } from '../../../models';
import { Ng5SliderService } from '../../../services/ng5-slider.service';

@Component({
  selector: 'app-filter-section-dialog',
  templateUrl: './filter-section-dialog.component.html',
  styleUrls: ['./filter-section-dialog.component.scss']
})
export class FilterSectionDialogComponent implements OnInit {
  projectId: number;
  form: FormGroup;
  sections = Sections;
  selectedSections: number;
  allowShow: boolean ;
  constructor(
    public dialogRef: MatDialogRef<FilterSectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup,
      projectId: number ,
      section: Sections},
    private slidersSevice: Ng5SliderService,
    private cd: ChangeDetectorRef
  ) { }


  ngOnInit() {
    this.form = this.data.form;
    this.projectId = this.data.projectId;
    this.selectedSections = this.data.section;
    this.refreshAllSliders();
  }
  close() {
    this.dialogRef.close(this.form.value);
  }

  refreshAllSliders() {
    this.dialogRef.afterOpened().subscribe(() => {
      this.slidersSevice.refreshSlider();
      this.allowShow = true;
      this.cd.detectChanges();

    });
  }


}
