import { Action, createReducer, on } from '@ngrx/store';
import { ProjectState } from '@shared/schema/project.state';
import * as projectActions from '@modules/project/state/project.actions';
import * as statisticsActions from '@modules/project/state/statistics.actions';

export const projectFeatureKey = 'project';
export const initialState: ProjectState = {
  projectDetails: null,
  importArticles: null,
  prismaGraphData: null,
  projectVariables: null,
  visualGraphData: null,
  activities: null,
  deduplicationReviewList: null,
  memberList: null,
  titleScreening: null,
  titleScreeningReviewerStatistic: null,
  allMembers: null,
  fullTextScreening: null,
  projectRanges: null,
  moderators: null,
  paperList: null,
  analysisFlow: {
    project: null,
    analys_type: null,
    analysis_method: null,
    filter_id: null,
    api_format: 'api',
    return_fields: [],
    structure: []
  },
  analysisResult: null,
  analysisTaskId: null,
  correlationVariableList: null,
  tagList: null,
  citationList: null,
  newVariable: null,
  moderatorList: null,
  aiVariableList: null,
  aiVariables: null,
  variablesGroups: null,
  successAlertMessage: null,
  projectPermissions: null,
  searchPapersAndVariablesResult: null,
  variableDetail: null,
  commentHistory: null,
  paperHistoryList: null,
  projectPapersHistory: null,
  batchFileHistory: null,
  notifications: null,
  tagsId: null,
  lastArticle: null,
  projectStatistics: null,
  toast: {
    message: null,
    title: null,
    status: null
  },
  screeningReasons: null,
  savedPaperFilter: null,
  filteredVariables: null,
  filterPapers: {
    general: null,
    titleScreening: null,
    fullTextScreening: null,
    dataEntry: null,
    manualPaper: null
  },
  projectTasks: null,
  role_status: null
};

const projectReducer = createReducer(
  initialState,
  on(projectActions.loadProjectSuccess, (state, action) => ({
    ...state,
    projectDetails: action.selectedProject
  })),
  on(projectActions.loadArticleListSuccess, (state, action) => ({
    ...state,
    importArticles: action.statusList
  })),
  on(projectActions.loadPrismaDataSuccess, (state, action) => ({
    ...state,
    prismaGraphData: action.prismaGraphData
  })),
  on(projectActions.loadProjectVariablesSuccess, (state, action) => ({
    ...state,
    projectVariables: action.variables
  })),
  on(projectActions.loadVisualDataSuccess, (state, action) => ({
    ...state,
    visualGraphData: action.visualGraphData
  })),
  on(projectActions.loadActivityListSuccess, (state, action) => ({
    ...state,
    activities: action.activityList
  })),
  on(projectActions.loadDeduplicationReviewListSuccess, (state, action) => ({
    ...state,
    deduplicationReviewList: action.reviewList
  })),
  on(projectActions.loadMemberListSuccess, (state, action) => ({
    ...state,
    memberList: action.memberList
  })),
  on(projectActions.loadTitleScreeningListSuccess, (state, action) => ({
    ...state,
    titleScreening: action.reviewerList
  })),
  on(
    projectActions.loadTitleScreeningReviewerStatisticSuccess,
    (state, action) => ({ ...state, titleScreeningReviewerStatistic: action.statistics })
  ),
  // on(projectActions.loadAllMembersSuccess, (state, action) => ({ ...state, allMembers: action.members })),
  on(projectActions.loadFullTextSuccess, (state, action) => ({ ...state, fullTextScreening: action.fullText })),
  on(projectActions.loadProjectRangesSuccess, (state, action) => ({ ...state, projectRanges: action.ranges })),
  on(projectActions.loadModeratorsSuccess, (state, action) => ({ ...state, moderators: action.moderators })),
  on(projectActions.loadAiVariablesSuccess, (state, action) => ({ ...state, aiVariables: action.aiVariables })),
  on(projectActions.loadPaperListSuccess, (state, action) => ({ ...state, paperList: action.paperList })),
  on(projectActions.loadDataEntryPaperSuccess, (state, action) => ({ ...state, paperList: action.paperList })),
  on(projectActions.loadAnalysisFlow, (state, action) => ({ ...state, analysisFlow: action.flow })),
  on(projectActions.loadAnalysisTaskId, (state, action) => ({ ...state, analysisTaskId: action.result })),
  on(projectActions.loadAnalysisResult, (state, action) => ({ ...state, analysisResult: action.result })),
  on(projectActions.loadProjectVariableListSuccess, (state, action) => ({
    ...state, correlationVariableList: action.variables
  })),
  on(projectActions.getCitationsSuccess, (state, action) => ({
    ...state, citationList: action.citationList
  })),
  on(projectActions.getTagsSuccess, (state, action) => ({
    ...state, tagList: action.tagList
  })),
  on(projectActions.createTagSuccess, (state, action) => ({
    ...state, tagsId: action.tagsId
  })),
  on(projectActions.createProjectVariableSuccess, (state, action) => ({
    ...state, newVariable: action.variable, successAlertMessage: 'Variable was successfully Added'
  })),
  on(projectActions.updateProjectVariableSuccess, (state, action) => ({
    ...state, newVariable: action.variable, successAlertMessage: 'Variable was successfully Edited'
  })),
  on(projectActions.loadVariablesGroupsSuccess, (state, action) => ({
    ...state, variablesGroups: action.variablesGroups
  })),
  on(projectActions.getModeratorListSuccess, (state, action) => ({
    ...state, moderatorList: action.moderatorList
  })),
  on(projectActions.getAiVariableListSuccess, (state, action) => ({
    ...state, aiVariableList: action.aiVariableList
  })),
  on(projectActions.createCitationSuccess, (state, action) => ({
    ...state, newVariable: action.citation, successAlertMessage: 'Citation was successfully Added'
  })),
  on(projectActions.updateCitationSuccess, (state, action) => ({
    ...state, newVariable: action.citation, successAlertMessage: 'Citation was successfully Edited'
  })),
  on(projectActions.resetNewCorrelationValue, (state, action) => ({
    ...state, newVariable: null
  })),
  on(projectActions.showActionSuccessMessage, (state, action) => ({
    ...state, successAlertMessage: action.successMessage
  })),
  on(projectActions.resetAlert, (state, action) => ({
    ...state, successAlertMessage: null
  })),
  on(projectActions.getProjectPermissionsSuccess, (state, action) => ({
    ...state, projectPermissions: action.projectPermissionList
  })),
  on(projectActions.searchPapersAndVariableSuccess, (state, action) => ({
    ...state, searchPapersAndVariablesResult: action.result
  })),
  on(projectActions.getProjectVariableDetailSuccess, (state, action) => ({
    ...state, variableDetail: action.variableDetail
  })),
  on(projectActions.getModeratorDetailSuccess, (state, action) => ({
    ...state, variableDetail: action.moderator
  })),
  on(projectActions.loadProjectHistoryCommentsSuccess, (state, action) => ({
    ...state, commentHistory: action.data
  })),
  on(projectActions.loadProjectHistoryBatchFilesSuccess, (state, action) => ({
    ...state, batchFileHistory: action.data
  })),
  on(projectActions.loadProjectHistoryPapersSuccess, (state, action) => ({
    ...state, projectPapersHistory: action.data
  })),
  on(projectActions.loadProjectPaperHistorySuccess, (state, action) => ({
    ...state, paperHistoryList: action.data
  })),
  on(projectActions.loadProjectNotificationSuccess, (state, action) => ({
    ...state, notifications: action.notifications
  })),
  on(projectActions.resetProjectState, (state, action) => initialState ),
  on(projectActions.getLastImportArticleSuccess, (state, action) => ({
    ...state, lastArticle: action.article,
  })),
  on(projectActions.loadProjectStatisticSucccess, (state, action) => ({
    ...state, projectStatistics: action.projectStatistics
  })),
  on(projectActions.showToastMessage, (state, action) => ({
    ...state, toast: action.toast
  })),
  on(statisticsActions.getScreeningReasonSuccess, (state, action) => ({
    ...state, screeningReasons: action.reasons
  })),
  on(projectActions.loadSavedPaperFiltersSuccess, (state, action) => ({
    ...state, savedPaperFilter: action.papersFilter
  })),
  on(projectActions.filterVariablesSuccess, (state, action) => ({
    ...state, filteredVariables: action.variables
  })),
  on(projectActions.setFilterPapers, (state, action) => ({
    ...state, filterPapers: {...state.filterPapers, [action.filterType]: action.filter}
  })),
  on(statisticsActions.loadProjectTasksSuccess, (state, action) => ({
    ...state, projectTasks: action.tasks
  })),
);

export function ProjectReducer(
  state: ProjectState | undefined,
  action: Action
) {
  return projectReducer(state, action);
}
