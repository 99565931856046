import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-new-variable-group',
  templateUrl: './create-new-variable-group.component.html',
  styleUrls: ['./create-new-variable-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateNewVariableGroupComponent implements OnInit {
  createSample: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateNewVariableGroupComponent>,
    private builder: FormBuilder,
  ) {
  }
  ngOnInit() {
    this.buildForm();
  }
  buildForm() {
    this.createSample = this.builder.group({
      name: ['', Validators.required],
    });
  }

  create() {
    const { name } = this.createSample.value;
    this.dialogRef.close(name);
  }


  cancel() {
    this.dialogRef.close(null);
  }
}
