import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { Observable, Subject, timer } from 'rxjs';
import { map, retry, share, switchMap, takeUntil } from 'rxjs/operators';
import { ProjectList } from '@shared/schema/project-list';
import {
  DeduplicationReviewList,
  DeduplicationStatus,
  ImportArticle,
  ImportArticleStatusList,
  PrismaGraphData,
  ProjectActivityItem,
  ProjectDetails,
  ProjectMemberList,
  ProjectPermissionList,
  GroupVariables,
  ProjectVariable,
  ProjectRanges,
  Moderator,
  PaperFilter,
  FilterSet,
  PaperList,
  ProjectVariablesQueryParams,
  CorrelationVariableList,
  CorrelationVariable,
  CitationList,
  TagList,
  Tag,
  Citation,
  ModeratorList,
  VariablesGroup,
  VariablesGroupToCreate,
  CorrelationVariableToPost,
  VariablesGroupQueryParams,
  SearchPapersAndVariablesResult,
  ImportArticleFilterParams,
  TaskProgress,
  PaperItem,
  ContactList,
  ProjectMember,
  PaperItemInfo,
  AiVariable,
} from '@shared/schema/project';
import { HelperService } from './service-helper';
import { ProjectStatistics } from '@shared/schema/statistic';
import { AppRuntimeConfig, CONFIG } from './config.service';
import { ITaxonomyFilesResult, TaxonomyFile } from '@shared/schema/taxonomy-files';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends HelperService {
  /*====================*
   *  THIS ARE JUST FOR TEST
   *  THIS MUST HANDEL WTIH
   *  CORECT STRUCTURE AND
   *  EMIT
   *  MOVE TIHS LINES LATER :)
   */
  defualtPageSize: number;
  projectListAppend: boolean;
  projectListParam: {
    page: number,
    page_size: number,
    deleted: boolean,
    reportProgress: boolean,
    name__icontains?: string,
  };
  private stopPolling = new Subject(); // when trigger next , polling will be stop
  constructor(
    @Inject(CONFIG) private appRuntimeConfig: AppRuntimeConfig,
    private http: HttpClient
  ) {
    super();
    this.defualtPageSize = 10;
    this.projectListAppend = false;
    this.projectListParam = {
      page: 1,
      page_size: this.defualtPageSize,
      deleted: false,
      reportProgress: false,
      name__icontains: null,
    };
  }
  // Create new project
  async createProject(projectInfo: any): Promise<any> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/project/project/`, projectInfo)
      .toPromise()) as any;
  }
  // Update Project
  async patchProject(projectInfo: any, id: number): Promise<any> {
    return (await this.http
      .patch(`${this.appRuntimeConfig.api_url}/project/project/${id}/`, projectInfo)
      .toPromise()) as any;
  }
  // Delete Project
  async deleteProject(projectId: number): Promise<any> {
    return (await this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/`)
      .toPromise()) as any;
  }
  // Get Project List
  getProjectList({
    page = 1,
    page_size = 10,
    reportProgress = false,
    deleted = false,
    name__icontains = null
  }): Observable<ProjectList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/`, {
        params: this.getHttpParams({
          page,
          page_size,
          deleted,
          name__icontains
        }),
        reportProgress,
      })
      .pipe(
        map((result: ProjectList) => {
          return result;
        })
      );
  }
  // Get Public Project List
  getPublicProjectList({
    page = 1,
    page_size = 10,
    reportProgress = false,
    deleted = false,
    name__icontains = null
  }): Observable<ProjectList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/public_projects/`, {
        params: this.getHttpParams({
          page,
          page_size,
          deleted,
          name__icontains
        }),
        reportProgress,
      })
      .pipe(
        map((result: ProjectList) => {
          return result;
        })
      );
  }
  //  Get Project
  getProject(projectId: number, progress = false): Observable<ProjectDetails> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/`, {
        reportProgress: progress
      })
      .pipe(
        map((result: ProjectDetails) => {
          return result;
        })
      );
  }
  // Get All Activity For specific project
  getProjectActivity(projectId: number): Observable<ProjectActivityItem[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/activity/`)
      .pipe(
        map((result: ProjectActivityItem[]) => {
          return result;
        })
      );
  }

  // Get All Activity For specific project
  getProjectLog(queryParam: any): Observable<any> {
    return this.http.get(`${this.appRuntimeConfig.api_url}/project/logs/`, {
      params: this.getHttpParams(queryParam),
    });
  }
  //  Get ArticleList
  getArticleList(
    params: ImportArticleFilterParams
  ): Observable<ImportArticleStatusList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/batch_files/`, {
        params: this.getHttpParams(params),
      })
      .pipe(
        map((result: ImportArticleStatusList) => {
          return result;
        })
      );
  }

  getArticleWarning(batchFileId: number): Observable<any> {
    return this.http
      .get<any>(`${this.appRuntimeConfig.api_url}/project/batch_files/${batchFileId}/warnings/`);
  }

  // get last imported article use the same endpoint as >>> getArticleList and can be replaced in future
  getLastArticle(projectId: number): Observable<ImportArticle> {
    return this.getArticleList({
      page: '1',
      page_size: '1',
      ordering: '-pk',
      project: projectId.toString(),
    }).pipe(map((res: ImportArticleStatusList) => res.data[0]));
  }
  // Import new article to project
  importArticle(article: FormData): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/batch_files/`, article, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  // Delete Imported Article
  async deleteImportedArticle(
    projectId: number,
    articleId: number
  ): Promise<any> {
    return (await this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/batch_files/${articleId}/`, {
        params: this.getHttpParams({ project: projectId }),
      })
      .toPromise()) as any;
  }

  getProjectPrisma(projectId: number): Observable<PrismaGraphData> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/prisma/`)
      .pipe(map((res: PrismaGraphData) => res));
  }

  getProjectVisualize(projectId: number): Observable<ProjectVariable[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/visualize/`)
      .pipe(map((result: ProjectVariable[]) => result));
  }
  getProjectVariables(projectId: number): Observable<ProjectVariable[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/variables/`)
      .pipe(map((result: ProjectVariable[]) => result));
  }

  // Get Deduplication Status
  deduplicationStatus(projectId: number): Observable<DeduplicationStatus> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/${projectId}/deduplication/`, {
        reportProgress: true,
      })
      .pipe(
        map((result: DeduplicationStatus) => {
          return result;
        })
      );
  }
  // Run Deduplication
  runDeduplication(projectId: number, deduplicationType: string): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/papers/deduplicate/`, {
        project: projectId,
        deduplicationType
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  // Get Deduplication Review List
  getDeduplicationReviewList({
    projectId,
    page = 1,
    page_size = 5,
  }): Observable<DeduplicationReviewList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/deduplication_review/`, {
        params: this.getHttpParams({
          project: projectId,
          page,
          page_size,
        }),
      })
      .pipe(
        map((result: DeduplicationReviewList) => {
          return result;
        })
      );
  }
  // Change Paper Status
  async changePaperStatus(
    projectId: number,
    paperId: number,
    info: { status: number }
  ): Promise<PaperItem> {
    return (await this.http
      .patch(
        `${this.appRuntimeConfig.api_url}/project/papers/${paperId}/`,
        { ...info, project: projectId },
        { reportProgress: true }
      )
      .toPromise()) as PaperItem;
  }
  // Change All Paper status at once [depricated]
  changeAllPaperStatue(paper: FilterSet): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/papers/paper_status/`, paper)
      .pipe(map((result) => result));
  }
  // Change All Paper status in tilte screening
  changeAllPaperStatueInTitleScreeing(paper: FilterSet): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/papers/change_title_papers_status/`, paper)
      .pipe(map((result) => result));
  }
  // Change All Paper status in fulltext screening
  changeAllPaperStatueInFulltextScreeing(paper: FilterSet): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/papers/change_fulltext_papers_status/`, paper)
      .pipe(map((result) => result));
  }
  // Available Permissions
  getPermissionList(
    projectId: number,
    page?: number,
    pageSize?: number
  ): Observable<ProjectPermissionList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/permissions/`, {
        params: this.getHttpParams({
          page,
          page_size: pageSize,
        }),
      })
      .pipe(
        map((result: ProjectPermissionList) => {
          return result;
        })
      );
  }
  // create paper
  createPaper(data: FormData): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/papers/`, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(map((res) => res));
  }
  editPaper(id: number, data: any): Observable<any> {
    return this.http
      .patch(`${this.appRuntimeConfig.api_url}/project/papers/${id}/`, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(map((res) => res));
  }
  updatePaper(id: number, data: any): Observable<any> {
    return this.http
      .put(`${this.appRuntimeConfig.api_url}/project/papers/${id}/`, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(map((res) => res));
  }
  downloadPdfWithPaperId(id): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/${id}/download_pdf/`)
      .pipe(map((res) => res));
  }
  downloadPdfWithPdfId(id): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/pdf/${id}/`)
      .pipe(map((res) => res));
  }
  DeletePdfWithPdfId(id): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/pdf/${id}/`)
      .pipe(map((res) => res));
  }
  // why the output of this endpoint is type of deduplication status?!!!!
  //  Invite Member To Project
  inviteMember(info: {
    user_id: any;
    project: number;
  }): Observable<DeduplicationStatus> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/members/`, info)
      .pipe(map((result: DeduplicationStatus) => result));
  }
  inviteMemberLink(userPermition: any): Observable<any> {
    return this.http.post<any>
      (`${this.appRuntimeConfig.api_url}/project/members/invite_link/`, userPermition);
  }
  addMemberWithToken(token: any): Observable<any> {
    return this.http.post<any>
      (`${this.appRuntimeConfig.api_url}/project/members/add_member_with_token/`, token);
  }

  getUser(projectId: number): Observable<ProjectMember> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/members/get_member/`, {
        params: this.getHttpParams({ project: projectId }),
      })
      .pipe(map((res: ProjectMember) => res));
  }
  getMember(memberId: number): Observable<ProjectMember> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/members/${memberId}/`)
      .pipe(map((res: ProjectMember) => res));
  }
  // Members of Project
  getProjectMemberList(
    projectId: number,
    page: number,
    pageSize: number
  ): Observable<ProjectMemberList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/members/`, {
        params: this.getHttpParams({
          project: projectId,
          page,
          page_size: pageSize,
        }),
      })
      .pipe(
        map((result: ProjectMemberList) => {
          return result;
        })
      );
  }
  // Remove Project Member
  async removeProjectMember(userId: number): Promise<any> {
    return (await this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/members/${userId}/`)
      .toPromise()) as any;
  }
  leaveProject(memberId: number): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/members/${memberId}/`);
  }
  async editProjectMember(memberId: number, data: any): Promise<any> {
    return (
      await this.http.patch(`${this.appRuntimeConfig.api_url}/project/members/${memberId}/`, data).toPromise()
    ) as any;
  }
  // get title screening list with comments
  getAllTitleScreening(filter: FilterSet): Observable<PaperList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/title_screening/`, {
        params: this.getHttpParams(filter),
      })
      .pipe(map((res: PaperList) => res));
  }
  // Get Title Screening Item
  // async getTitleScreeningItem(projectId: number, paperId: number): Promise<PaperItemInfo> {
  //   return (await this.http.get(
  // `${this.appRuntimeConfig.api_url}/project/${projectId}/title_screening/${paperId}/`).toPromise()) as PaperItemInfo;
  // }

  // get title screeing list with comments
  getAllFullTextScreening(filter: FilterSet): Observable<PaperList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/full_text_screening/`, {
        params: this.getHttpParams(filter),
      })
      .pipe(map((res: PaperList) => res));
  }
  // get title screeing list with comments
  getAllDaraEntry(filter: FilterSet): Observable<PaperList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/data_entry/`, {
        params: this.getHttpParams(filter),
      })
      .pipe(map((res: PaperList) => res));
  }
  // Get Full Text Screening Item
  // async getFullTextItem(projectId: number, paperId: number): Promise<PaperItemInfo> {
  //   return (await this.http.get(
  // `${this.appRuntimeConfig.api_url}/project/${projectId}/full_screening/${paperId}/`).toPromise()) as PaperItemInfo;
  // }
  //  Get All Project Language
  getProjectLanguages(projectId: number): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/languages/`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  // Get Project Ranges
  getProjectRanges(projectId: number): Observable<ProjectRanges> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/ranges/`)
      .pipe(
        map((result: ProjectRanges) => {
          return result;
        })
      );
  }
  // Get Import Article Status
  getModerators(projectId: number): Observable<Moderator[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/moderators/`)
      .pipe(
        map((result: Moderator[]) => {
          return result;
        })
      );
  }
  // Get Project Filters
  getFilters(projectId: number): Observable<PaperFilter[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/filters/`, {
        params: this.getHttpParams({ project: projectId }),
      })
      .pipe(
        map((result: PaperFilter[]) => {
          return result;
        })
      );
  }
  // Get Project Filter By ID
  getFilter(filterId: number): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/filters/${filterId}/`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  //  Create Project Filter
  async createFilter(info: PaperFilter): Promise<any> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/project/filters/`, info)
      .toPromise()) as any;
  }
  async editFilter(id: number, info: PaperFilter): Promise<any> {
    return (await this.http
      .put(`${this.appRuntimeConfig.api_url}/project/filters/${id}/`, info)
      .toPromise()) as any;
  }
  async deleteFilter(Filterid: number): Promise<any> {
    return (await this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/filters/${Filterid}/`)
      .toPromise()) as any;
  }
  async getGroupVariables(projectId): Promise<GroupVariables> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/variables_group/`, {
        params: this.getHttpParams({ project: projectId }),
      })
      .toPromise()) as GroupVariables;
  }
  async createGroupVariables(info: {
    name: string;
    project: number;
    variables_id: number[];
  }): Promise<GroupVariables> {
    return (await this.http
      .post(`${this.appRuntimeConfig.api_url}/project/variables_group/`, info)
      .toPromise()) as GroupVariables;
  }
  // Get Project Papers
  getPapers(filter: FilterSet): Observable<PaperList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/`, {
        params: this.getHttpParams(filter),
      })
      .pipe(
        map((result: PaperList) => {
          return result;
        })
      );
  }
  getPaperDetail(paperId: number): Observable<PaperItem> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/${paperId}/`)
      .pipe(
        map((result: PaperItem) => {
          return result;
        })
      );
  }

  async exportPapers(filter: FilterSet): Promise<any> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/export/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      })
      .toPromise()) as any;
  }
  async exportTitleScreeningPapers(filter: FilterSet): Promise<any> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/export_title/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      })
      .toPromise()) as any;
  }
  async exportFulltextsScreeningPapers(filter: FilterSet): Promise<any> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/export_fulltext/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      })
      .toPromise()) as any;
  }
  async exportDataEntryPapers(filter: FilterSet): Promise<any> {
    return (await this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/export_data_entry/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      })
      .toPromise()) as any;
  }
  async deletePaper(id: number): Promise<any> {
    return (await this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/papers/${id}/`)
      .toPromise()) as any;
  }
  getProjectStatistic(id: number, AreaSection: string): Observable<ProjectStatistics> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${id}/statistics/`, {
        reportProgress: true,
        params: { section: AreaSection }
      })
      .pipe(map((projectStatistics: ProjectStatistics) => projectStatistics));
  }
  // Get Project Variable List
  getProjectVariableList(
    params: ProjectVariablesQueryParams,
    reportProgress?: boolean
  ): Observable<CorrelationVariableList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/variables/`, {
        params: this.getHttpParams(params),
        reportProgress,
      })
      .pipe(map((result: CorrelationVariableList) => result));
  }
  // Create Project Correlation Variable
  createProjectVariable(variable: CorrelationVariableToPost): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/variables/`, variable, {
        reportProgress: true,
      })
      .pipe(map((result) => result));
  }
  // Edit Project Correlation Variable
  updateProjectVariable(variable: CorrelationVariableToPost): Observable<any> {
    return this.http
      .put(
        `${this.appRuntimeConfig.api_url}/project/variables/${variable.id}/`,
        variable,
        { reportProgress: true }
      )
      .pipe(map((result) => result));
  }
  // Get Project Correlation Variable Detail
  getProjectVariableDetail(id: number): Observable<CorrelationVariable> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/variables/${id}/`)
      .pipe(map((result: CorrelationVariable) => result));
  }
  //  Delete a Project Correlation Variable
  deleteProjectVariable(id: number): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/variables/${id}/`)
      .pipe(map((result) => result));
  }
  deleteListOfProjectVariables(id: number[]): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/variables/bulk_delete/`, {
        params: this.getHttpParams({ id }),
      })
      .pipe(map((result) => result));
  }
  updateParentListOfProjectVariables(id: number[], parentId: number): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/variables/bulk_update/`, {
        parent: parentId,
        variables_id: id
      })
      .pipe(map((result) => result));
  }
  MergeVariableListOfProjectVariables(id: number[], remainVariableId: number): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/variables/merge/`, {
        base_variable: remainVariableId,
        variables_id: id
      })
      .pipe(map((result) => result));
  }
  // Get Citation List
  getCitations(
    queryParams: ProjectVariablesQueryParams,
    reportProgress: boolean
  ): Observable<CitationList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/citations/`, {
        params: this.getHttpParams(queryParams),
        reportProgress,
      })
      .pipe(map((result: CitationList) => result));
  }
  createCitation(citation: Citation): Observable<Citation> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/citations/`, citation, {
        reportProgress: true,
      })
      .pipe(map((result: Citation) => result));
  }
  getCitationDetail(id: number): Observable<Citation> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/citations/${id}/`)
      .pipe(map((result: Citation) => result));
  }
  updateCitation(citation: Citation): Observable<Citation> {
    return this.http
      .put(
        `${this.appRuntimeConfig.api_url}/project/citations/${citation.id}/`,
        citation,
        { reportProgress: true }
      )
      .pipe(map((result: Citation) => result));
  }
  deleteCitation(id: number): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/citations/${id}/`)
      .pipe(map((result) => result));
  }
  deleteListOfCitations(id: number[]): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/citations/bulk_delete/`, {
        params: this.getHttpParams({ id }),
      })
      .pipe(map((result) => result));
  }
  // Get Tag List
  getTags(
    queryParams: ProjectVariablesQueryParams,
    reportProgress: boolean
  ): Observable<TagList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/tags/`, {
        params: this.getHttpParams(queryParams),
        reportProgress,
      })
      .pipe(map((result: TagList) => result));
  }
  getTagDetail(id: number): Observable<Tag> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/tags/${id}/`)
      .pipe(map((result: Tag) => result));
  }
  // Create Tag
  createTag(tag: Tag): Observable<Tag> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/tags/`, tag, {
        reportProgress: true,
      })
      .pipe(map((result: Tag) => result));
  }
  deleteBulkTag(tagList?: number[], autoGenerated?: boolean): Observable<TagList> {
    return this.http.delete(`${this.appRuntimeConfig.api_url}/project/tags/bulk_delete/`, {
      params: this.getHttpParams({
        id__in: tagList,
        auto_generated: autoGenerated
      }),
    })
      .pipe(map((result: TagList) => result));
  }
  // Create Magic Tag
  createMagicTag(data: any): Observable<Tag> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/clustering/auto_tag/`, data, {
        reportProgress: true,
      })
      .pipe(map((result: Tag) => result));
  }
  deleteAutoTag(): Observable<TagList> {
    return this.deleteBulkTag(null, true);
  }
  createTags(projectId: number, tags: string[]): Observable<number[]> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/tags/bulk_create/`, {
        project: projectId,
        tags,
      })
      .pipe(map((result: { tag_ids: number[] }) => result.tag_ids));
  }
  updateTag(tag: Tag): Observable<Tag> {
    return this.http
      .put(`${this.appRuntimeConfig.api_url}/project/tags/${tag.id}/`, tag, {
        reportProgress: true,
      })
      .pipe(map((result: Tag) => result));
  }
  deleteTag(id: number): Observable<TagList> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/tags/${id}/`)
      .pipe(map((result: TagList) => result));
  }
  deleteTags(tagList: number[]): Observable<TagList> {
    return this.deleteBulkTag(tagList, null);
  }

  getModeratorList(
    queryParams: ProjectVariablesQueryParams
  ): Observable<ModeratorList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/moderators/`, {
        params: this.getHttpParams(queryParams),
      })
      .pipe(map((result: ModeratorList) => result));
  }
  getModeratorDetail(id: number): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/moderators/${id}/`)
      .pipe(map((result) => result));
  }
  createModerator(moderator: Moderator): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/moderators/`, moderator, {
        reportProgress: true,
      })
      .pipe(map((result) => result));
  }
  updateModerator(moderator: Moderator): Observable<any> {
    return this.http
      .put(
        `${this.appRuntimeConfig.api_url}/project/moderators/${moderator.id}/`,
        moderator,
        { reportProgress: true }
      )
      .pipe(map((result: Moderator) => result));
  }
  deleteModerator(id: number): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/moderators/${id}/`)
      .pipe(map((result) => result));
  }
  deleteListOfModerators(id: number[]): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/moderators/bulk_delete/`, {
        params: this.getHttpParams({ id }),
      })
      .pipe(map((result) => result));
  }
  getVariablesGroupList(
    queryParams: VariablesGroupQueryParams
  ): Observable<VariablesGroup[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/variables_group/`, {
        params: this.getHttpParams(queryParams),
      })
      .pipe(map((result: VariablesGroup[]) => result));
  }
  createVariablesGroup(variablesGroup): Observable<VariablesGroup> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/variables_group/`, variablesGroup, {
        reportProgress: true,
      })
      .pipe(map((result: VariablesGroup) => result));
  }
  getVariablesGroupDetail(id: number): Observable<VariablesGroup> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/variables_group/${id}/`)
      .pipe(map((result: VariablesGroup) => result));
  }
  updateVariablesGroup(
    variablesGroup: VariablesGroupToCreate
  ): Observable<VariablesGroup> {
    return this.http
      .put(
        `${this.appRuntimeConfig.api_url}/project/variables_group/${variablesGroup.id}/`,
        variablesGroup,
        { reportProgress: true }
      )
      .pipe(map((result: VariablesGroup) => result));
  }
  deleteVariablesGroup(id: number): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/variables_group/${id}/`)
      .pipe(map((result) => result));
  }
  deleteListOfVariablesGroup(id: number[]): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/variables_group/bulk_delete/`, {
        params: this.getHttpParams({ id }),
      })
      .pipe(map((result) => result));
  }
  searchPapersAndVariables(
    query: string,
    projectId: number
  ): Observable<SearchPapersAndVariablesResult[]> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/search/`, {
        reportProgress: true,
        params: { query },
      })
      .pipe(map((result: SearchPapersAndVariablesResult[]) => result));
  }


  getTaskStatus(taskId: string): Observable<TaskProgress> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/tasks/${taskId}/status/`, {
        reportProgress: true,
      })
      .pipe(map((result: { task: TaskProgress }) => result.task));
  }
  pollingTaskStatus(taskId: string): Observable<TaskProgress> {
    return timer(1, 2000).pipe(
      switchMap(() => this.getTaskStatus(taskId)),
      retry(),
      share(),
      takeUntil(this.stopPolling)
    );
  }
  stopPollingTaskStatus() {
    this.stopPolling.next();
  }

  getlatastTaskId(): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/clustering/auto_tag/task_id/`, {
        reportProgress: true,
      });
  }
  exportProject(projectId: number): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/export_csv/`, {
        reportProgress: true,
      })
      .pipe(map((res) => res));
  }
  exportJSON(projectId: number): Observable<any> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/export/`, {
        reportProgress: true,
      })
      .pipe(map((res) => res));
  }
  syncWithLibmeta(filter: FilterSet): Observable<{ task_id: string }> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/update_libmeta_exist/`, {
        params: this.getHttpParams(filter),
        reportProgress: true,
      })
      .pipe(map((res: { task_id: string }) => res));
  }
  recalculateCompositScore(projectId: number): Observable<{ task_id: string }> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/recalculate_composite_score/`, {
        reportProgress: true,
      })
      .pipe(map((res: { task_id: string }) => res));
  }
  downloadFile(path) {
    return this.http
      .get(`${this.appRuntimeConfig.host_name}${path}`, {
        responseType: 'blob',
        reportProgress: true,
      })
      .pipe(map((res) => res));
  }
  pinProject(params): Observable<any> {
    return this.http
      .post(`${this.appRuntimeConfig.api_url}/project/pin/`, params)
      .pipe(map((res) => res));
  }
  deletePinOfProject(id): Observable<any> {
    return this.http
      .delete(`${this.appRuntimeConfig.api_url}/project/pin/${id}/`)
      .pipe(map((res) => res));
  }
  // getPinnedProject(user): Observable<any> {
  //   return this.http.get(`${this.appRuntimeConfig.api_url}/project/pin/?user=${user}`).pipe(
  //     map(res => res)
  //   );
  // }
  importCSVCorrelationOutcome(file: FormData): Observable<any> {
    return this.http
      .post(
        `${this.appRuntimeConfig.api_url}/statistic/correlation_outcome/import_csv/`,
        file
      )
      .pipe(map((res) => res));
  }
  getContactList(filter: FilterSet): Observable<ContactList> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/papers/contact_list/`, {
        params: this.getHttpParams(filter),
      })
      .pipe(map((res: ContactList) => res));
  }
  getKappaTaskId(
    projectId: number,
    commentStatus: number
  ): Observable<{ task_id: string }> {
    return this.http
      .get(`${this.appRuntimeConfig.api_url}/project/project/${projectId}/kappa/`, {
        params: this.getHttpParams({ comment_status: commentStatus }),
        reportProgress: true,
      })
      .pipe(map((res: { task_id: string }) => res));
  }
  searchDOI(params: FilterSet): Observable<Pick<PaperItemInfo, 'abstract' | 'name' | 'author' | 'year' | 'link'>> {
    return this.http.get(`${this.appRuntimeConfig.api_url}/project/papers/doi_search/`, {
      params: this.getHttpParams(params),
      reportProgress: true
    }).pipe(
      map((res: Pick<PaperItemInfo, 'abstract' | 'name' | 'author' | 'year'>) => res)
    );
  }
  convertImgToCSV(params): Observable<any> {
    return this.http
      .post(
        `${this.appRuntimeConfig.libmeta_url}/image_to_csv/image_to_csv/`,
        { ...params },
        { reportProgress: true, responseType: 'text' }
      )
      .pipe(map((res) => res));
  }

  /*===============
  * Taxonomy File
  =================
  */
  getTaxonomyFileList(paginationPram: any): Observable<ITaxonomyFilesResult> {
    return this.http.get<ITaxonomyFilesResult>(
      `${this.appRuntimeConfig.api_url}/project/variable_files/`, {
      params: this.getHttpParams(paginationPram),
    }
    );
  }

  createTaxonomyVariable(model: any): Observable<ITaxonomyFilesResult> {
    return this.http.post<ITaxonomyFilesResult>(
      `${this.appRuntimeConfig.api_url}/project/variable_files/`,
      model
    );
  }

  deleteTaxonomyImportedFile(
    projectId: number,
    itemId: number): Observable<any> {
    return this.http.delete<any>(
      `${this.appRuntimeConfig.api_url}/project/variable_files/${itemId}/`, {
      params: this.getHttpParams({ project: projectId }),
    });
  }

  getTaxonomyFileWarnings(variablefileId: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/project/variable_files/${variablefileId}/warnings/`,
    );
  }
  getTaxonomyFileErrors(variablefileId: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/project/variable_files/${variablefileId}/errors/`,
    );
  }
  getTaxonomyFileExport(filter: any): Observable<{ task_id: string }> {
    return this.http.get<{ task_id: string }>(
      `${this.appRuntimeConfig.api_url}/project/variables/export/`, {
      params: this.getHttpParams(filter),
    }
    );
  }

  taxonomyImportCitation(model: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/project/citations/import_file/`,
      model
    );
  }
  taxonomyExportCitation(): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/project/citations/export/`
    );
  }
  taxonomyImportModrators(model: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/project/moderators/import_file/`,
      model
    );
  }
  taxonomyExportModrators(): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/project/moderators/export/`
    );
  }
  insight(section: any, projectId: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/project/project/${projectId}/analytics/`, {
      params: this.getHttpParams(section),
    }
    );
  }
  /*===============
  * Assignment
  =================*/
  getPaperAssignment(paperId: number, pagination?: any): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/project/paper_assignment/`, {
      params: this.getHttpParams({
        paper: paperId,
        ...pagination
      }),
    }
    );
  }

  addPaperAssignment(model: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/project/paper_assignment/`,
      model
    );
  }
  deletePaperAssignment(assignmentId: number): Observable<any> {
    return this.http.delete<any>(
      `${this.appRuntimeConfig.api_url}/project/paper_assignment/${assignmentId}/`);
  }

  bulkAssignPaper(model: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/project/paper_assignment/bulk_create/`,
      model
    );
  }
  deleteBulkAssignPaper(model: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/project/paper_assignment/bulk_delete/`,
      model
    );
  }

  /*===============
  * AI Variable
  =================*/
  getAiVariableList(pagination?: any, report: boolean= false): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/`, {
      params: this.getHttpParams({
        ...pagination
      }), reportProgress: report,
    }
    );
  }
  // getAiVariables(projectId: number): Observable<AiVariable[]> {
  //   return this.http
  //     .get(`${this.appRuntimeConfig.api_url}/aivariable/aivariable/`)
  //     .pipe(
  //       map((result: AiVariable[]) => {
  //         return result;
  //       })
  //     );
  // }
  addAiVariable(aiVariableModel: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/`, aiVariableModel);
  }

  editAiVariable(aiVariableModel: any): Observable<any> {
    return this.http.put<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/${aiVariableModel.id}/`, aiVariableModel);
  }

  deleteAiVariable(aiVariableId: any): Observable<any> {
    return this.http.delete<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/${aiVariableId}/`);
  }
  aiVariablePreRun(aiVariableId, preRunModel: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/${aiVariableId}/pre_run/`, preRunModel);
  }
  aiVariableRun(aiVariableId, RunModel: any): Observable<any> {
    return this.http.post<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/${aiVariableId}/run/`, RunModel);
  }

  aiVariableLogList(aiVariableId: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/aivariable/${aiVariableId}/run_results/`);
  }



  getAiVariablePaper(paperId: number): Observable<any> {
    return this.http.get<any>(
      `${this.appRuntimeConfig.api_url}/aivariable/paper/${paperId}/`);
  }

  /*===============
  * JUST FOR TEST
  =================
  GO TO CONSTRUCTOR
  */

  setProjectListParam(Param: any) {
    this.projectListParam = {
      ...this.projectListParam,
      ...Param
    };
  }
  getProjectListParam() {
    return this.projectListParam;
  }
  setProjectlistAppend(status: boolean) {
    this.projectListAppend = status;
  }
  getProjectlistAppend(): boolean {
    return this.projectListAppend;
  }

}
