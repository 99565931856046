import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProjectService} from '@core/service/project.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { PaperItem } from '@shared/schema/project';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-paper-decision',
  templateUrl: './paper-decision.component.html',
  styleUrls: ['./paper-decision.component.scss']
})
export class PaperDecisionComponent implements OnChanges {
  @Input() status = 1;
  @Input() projectId: number;
  @Input() paper: PaperItem;
  @Input() showButtonName = false;
  @Output() updateStatus: EventEmitter<PaperItem> = new EventEmitter<PaperItem>();
  decisionForm: FormGroup;
  valueChange: PaperItem;
  pending = false;
  constructor(
    private service: ProjectService,
    private builder: FormBuilder,
    private spinnerService: NgxSpinnerService

  ) {}

  ngOnChanges() {
    this.buildForm();
  }
  buildForm() {
    this.decisionForm = this.builder.group({
      title: [null],
      full: [null],
    });
    if (this.paper) {
      this.decisionForm.patchValue({
        title: this.paper.status,
        full: this.paper.status,
      });
    }

    this.decisionForm.get('title').valueChanges.subscribe(
      (change) => change === 6 ? this.handleAcceptPaper() : this.handleRejectPaper()
    );
    this.decisionForm.get('full').valueChanges.subscribe(
      (change) => change === 7 ? this.handleAcceptPaper() : this.handleRejectPaper()
    );
  }
  handleAcceptPaper(bypassFullText?: boolean) {
    this.pending = true;
    this.spinnerService.show('paper-decision' + this.paper.id);

    // if paper is accepted in title screening
    if (this.status === 1) {
      if (bypassFullText) {
        if (this.paper.status === 7) {
          this.changePaperStatus(4);
        } else {
          this.changePaperStatus(7);
        }
      } else {
        if (this.paper.status === 6) {
          this.changePaperStatus(4);
        } else {
          this.changePaperStatus(6);
        }
      }
    } else {
      // if paper is accepted in full text screening
      if (this.paper.status === 7) {
        this.changePaperStatus(6);
      } else {
        this.changePaperStatus(7);
      }

    }
  }
  handleRejectPaper() {
    this.pending = true;
    this.spinnerService.show('paper-decision' + this.paper.id);

    // if paper is rejected in title screening
    if (this.status === 1) {
      if (this.paper.status === 3) {
        this.changePaperStatus(4);
      } else {
        this.changePaperStatus(3);
      }
    } else {
      // if paper is rejected in full text screening
      if (this.paper.status === 5) {
        this.changePaperStatus(6);
      } else {
        this.changePaperStatus(5);
      }
    }
  }

  handlePostEndpointCallActions(response) {
    this.updateStatus.emit(response);
    this.spinnerService.hide('paper-decision' + this.paper.id);
    this.pending = false;
  }

  changePaperStatus(status: number) {
    this.service.changePaperStatus(this.projectId, this.paper.pk, { status }).then((response) => {
      this.handlePostEndpointCallActions(response);
    });
  }
}
