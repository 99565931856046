const exportR = (resultTable, variablesName, observations) => {
    return(
`library("lavaan") \t #This is the main library that does the SEM analysis
library("semPlot") \t #This is the library that can visualize the SEM models
lower1 <- '
${resultTable}'


# The names between "" are variable names from the correlation matrix

inputMatrix1 <-
getCov(lower1, names = c(${variablesName}))


Observations <- c(${observations})


install.packages("psych")\t# Install psych package
library("psych")\t# Load psych package

Observations = harmonic.mean(Observations)\t # Apply harmonic.mean function

model1 <-'

# ${variablesName}
# regressions

# correlated residuals
'


fit1 <- sem(model1,
sample.cov = inputMatrix1,
sample.nobs = Observations , orthogonal = TRUE)
summary(fit1, fit.measures=TRUE )
fitMeasures(fit1, c("chisq" , "df" ,  "pvalue" , "srmr","agfi","rmsea","nfi" , "cfi"))
semPaths(fit1, whatLabels ="par" , curvePivot = TRUE, layout= "tree3" , rotation = 2 , residuals = FALSE , levels = c(4,5,6,7) )`
    );
};
export { exportR };
