import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserInfo } from '@shared/schema/profile';

@Component({
  selector: 'app-filter-user-assignment-dialog',
  templateUrl: './filter-user-assignment-dialog.component.html',
  styleUrls: ['./filter-user-assignment-dialog.component.scss']
})
export class FilterUserAssignmentDialogComponent implements OnInit {

  form: FormGroup;
  userRoleOptions:
    { name: string; value: number }[] = [
      {
        name: 'Reviwer',
        value: 1,
      },
      {
        name: 'Researcher',
        value: 2,
      },
      {
        name: 'Importer',
        value: 3,
      },
    ];
  userStageOptions:
    { name: string; value: number }[] = [
      {
        name: 'Title Screening',
        value: 4,
      },
      {
        name: 'Full Text Screening',
        value: 6,
      },
      {
        name: 'Data Entry',
        value: 7,
      },
    ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      userAssignment: {userId: number, role: number[], stage: number[]}[],
      users: UserInfo[]
    },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FilterUserAssignmentDialogComponent>,
  ) { }

  ngOnInit() {
    this.createForm();
    if (this.data.userAssignment.length > 0) {
      this.fillData(this.data.userAssignment);
    } else {
      this.creteNewUserAssignment();
    }
  }


  createForm() {
    this.form = this.fb.group({
      userAssignments: this.fb.array([])
    });
  }

  fillData(userAssignment: Array<any>) {
    userAssignment.forEach((item, index) => {
      this.creteNewUserAssignment(item);
    });
  }

  get userAssignments(): FormArray {
    return this.form.get('userAssignments') as FormArray;
  }

  creteNewUserAssignment(item?: any) {
    const newUserAssignment = this.fb.group({
      userId: [item ? item.userId : null, []],
      role: [item ? item.role : [], []],
      stage: [item ? item.stage : [], []]
    });
    this.userAssignments.push(newUserAssignment);
  }

  deleteRow(index: number) {
    this.userAssignments.removeAt(index);
  }

  handleUserAssignment() {
    const userAssignments = this.removeEmptyRow();
    this.dialogRef.close(userAssignments);
  }

  removeEmptyRow(): Array<any> {
    return this.userAssignments.value.map(item => {
      if (item.userId && item.role.length > 0 && item.stage.length > 0) {
        return item;
      }
      return null;
    })
    .filter(item => item !== null);
  }

}
