import {Component, OnInit} from '@angular/core';
import { MatTabChangeEvent} from '@angular/material';

@Component({
  selector: 'app-cite-us',
  templateUrl: './cite-us.component.html',
  styleUrls: ['./cite-us.component.scss']
})
export class CiteUsComponent implements OnInit {
  bibTexValue =
  '\n' +
  '@inproceedings{\n' +
    '  Steel2023AnAO,\n' +
    '  title={An Application of Modern Literature Review Methodology: Finding Needles in Ever-Growing Haystacks},\n' +
    '  author={Piers Steel and Hadi Fariborzi and Rosa Hendijani},\n' +
    '  year={2023},\n' +
    '  url={https://api.semanticscholar.org/CorpusID:257745056}\n' +
    '}';
  MLAValue = '\n' +
  'Steel, Piers et al.\n ' +
    '“An Application of Modern Literature Review Methodology: Finding Needles in Ever-Growing Haystacks.” (2023).' +
    '\n\n\n\n\n'; // just for prety

    APAValue = '\n' +
    'Steel, P., Fariborzi, H., & Hendijani, R.\n' +
    ' (2023). An Application of Modern Literature Review Methodology: Finding Needles in Ever-Growing Haystacks.' +
    '\n\n\n\n\n'; // just for prety

    chicagoValue = '\n' +
    'Steel, Piers, Hadi Fariborzi and Rosa Hendijani.\n ' +
    '“An Application of Modern Literature Review Methodology: Finding Needles in Ever-Growing Haystacks.” (2023).' +
    '\n\n\n\n\n'; // just for prety

    copyLabel = 'Copy';

  constructor( ) {
  }

  ngOnInit() {
  }

  onTabChanged($event: MatTabChangeEvent) {
    this.copyLabel = 'Copy';
  }
}
