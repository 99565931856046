import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectVariablesQueryParams, VariablesGroupQueryParams } from '@shared/schema/project';

@Injectable()
export class LoadVariableService {
    private loadVariable = new Subject<any>();
    private loadScreeningData = new Subject();

    loadVariable$ = this.loadVariable.asObservable();
    loadScreeningData$ = this.loadScreeningData.asObservable();

    announceLoadVariable(variableType: string, queryParams: VariablesGroupQueryParams | ProjectVariablesQueryParams) {
        this.loadVariable.next({variableType, queryParams});
    }
    announceLoadScreeningData() {
        this.loadScreeningData.next();
    }
}
