import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectService } from '@core/service/project.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/schema/app.state';
import { CorrelationVariableList, CorrelationVariablesOrdering, ProjectRanges } from '@shared/schema/project';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ng5SliderService } from '../../../services/ng5-slider.service';

@Component({
  selector: 'app-filter-variable',
  templateUrl: './filter-variable.component.html',
  styleUrls: ['./filter-variable.component.scss']
})
export class FilterVariableComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() projectId: number;

  ranges: ProjectRanges;
  subscription: Subscription[] = [];

  /*------------------------------------
   * [NG5SLIDER BUG]
   * slider should be reset afterOpened
   * otherwise this not whow range correctly
  -------------------------------------*/
  ng5SliderManualRefresh;

  constructor(
    private store: Store<AppState>,
    private service: ProjectService,
    private slidersSevice: Ng5SliderService
  ) {
    this.ng5SliderManualRefresh = this.slidersSevice.refresh;
  }

  ngOnInit() {
    this.setSubscription();
  }

  ngOnDestroy(): void {
    for (const item of this.subscription) {
      if (item) {
        item.unsubscribe();
      }
    }
  }
  setSubscription() {
    this.subscription.push(this.getProjectRanges());
  }
  getProjectRanges() {
    return this.store
      .select((state) => state.project.projectRanges)
      .subscribe((state) => {
        this.ranges = state;
      });
  }

  getVariableCallbackFunction = (term: string): Observable<{
    num_of_pages: number;
    count: number;
    data: any[];
  }> => {
    return this.service.getProjectVariableList({
      page: 1,
      page_size: 10,
      project: this.projectId.toString(),
      name__icontains: term,
      ordering: CorrelationVariablesOrdering.usage_asc
    }, true).pipe(
      map((state) => {
        return {
          num_of_pages: state.num_of_pages,
          count: state.count,
          data: state.data.map((data) => {
            return {
              id: data.id,
              name: data.name + ' - ' + data.correlation_type + ' (' + data.usage + ')'
            };
          })
        };
      })
    );
  }

}
