import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap, switchMap, catchError, debounceTime, startWith, skipWhile } from 'rxjs/operators';


@Component({
  selector: 'app-filter-search-dropdown',
  templateUrl: './filter-search-dropdown.component.html',
  styleUrls: ['./filter-search-dropdown.component.scss']
})
export class FilterSearchDropdownComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() control: string;
  @Input() placeholder: string;
  @Input() bindLabel = 'name';
  @Input() bindValue ;
  @Input() notFoundText: string;
  @Input() addTag = false;
  @Input() multiple = true;
  @Input() callbackFunction: (args: any) => Observable<{
    num_of_pages: number;
    count: number;
    data: any[];
  }>;

  items$: Observable<any[]>;
  search$ = new Subject<string>();
  loading = false;

  constructor() { }

  ngOnInit() {
    this.items$ = this.getItems();
  }

  getItems() {
    return concat(
      of([]),
      this.search$.pipe(
        debounceTime(300),
        distinctUntilChanged((prev, curr) => prev === curr || curr === null),
        tap(() => { this.loading = true; }),
        switchMap((term: string) => {
          return this.callbackFunction(term).pipe(
            map(res => res.data),
            catchError(() => of([])), // empty list on error
            tap(() => { this.loading = false; })
          );
        }
        )
      )
    );
  }
}
