import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ProjectService } from '@core/service/project.service';
import { WorkspaceAddNewTagComponent } from '@modules/project/components/workspace-add-new-tag/workspace-add-new-tag.component';
import { PaperItem, Tag } from '@shared/schema/project';
import { downloadBlob } from '@shared/utils';
import { HttpUtils } from '@shared/utils/http-utils';
import { AddTagToPaperComponent } from '../add-tag-to-paper/add-tag-to-paper.component';
import { HandleAssignmentComponent } from '../handle-assignment/handle-assignment.component';
@Component({
  selector: 'app-paper-overall-info',
  templateUrl: './paper-overall-info.component.html',
  styleUrls: ['./paper-overall-info.component.scss']
})
export class PaperOverallInfoComponent implements OnInit {
  @Input() paper: PaperItem;
  @Input() projectId: PaperItem;
  @Output() updatePaperTags: EventEmitter<PaperItem> = new EventEmitter();
  @Output() updatePaperAssignment: EventEmitter<PaperItem> = new EventEmitter();
  @Input() terms: string[];
  @Input() libmeta: boolean;
  @Input() isMember: boolean;
  libmetaProgress = null;
  palette = ['#ef9a9a', '#9fa8da', '#a5d6a7', '#ffe082', '#bcaaa4', '#ffab91', '#e6ee9c', '#81d4fa', '#ce93d8'];

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }
  downloadPdf() {
    if (this.paper && this.paper.pdf_files.length > 0 && this.paper.pdf_files[0].file_server === 1) {
      this.paper.pdf_files[0].file.forEach((item: string) => {
        this.projectService.downloadFile(item).subscribe(
          response => {
            downloadBlob(response, 'application/pdf', item.slice(0, (item.length - 3)));
          }
        );
      });

    } else {
      if (this.paper.pdf_files[0].file) {
        this.projectService.downloadPdfWithPdfId(this.paper.pdf_files[0].id).subscribe(
          response => {
            const binaryStrings = window.atob(response.bin_file);
            const len = binaryStrings.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryStrings.charCodeAt(i);
            }
            downloadBlob(bytes.buffer, 'application/pdf', this.paper.pdf_files[0].file[0], true);
          }
        );
      }
    }

  }
  openPdfAsNewWindow(response) {
    const blob = new Blob([response], {
      type: 'application/pdf',
    });
    const a = window.document.createElement('a');
    const href = window.URL.createObjectURL(blob);
    a.target = '_blank';
    a.href = href;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  refreshLibmetaSync() {

  }
  doiLink(link: string): boolean {
    return link.includes('https://doi.org/');
  }
  openTagDialog() {
    const dialogRef = this.dialog.open(AddTagToPaperComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: {
        paper: this.paper,
        projectId: this.projectId
      }
    });
    dialogRef.afterClosed().subscribe(tags => {
      if (tags) {
        this.updateTag(tags);
      }
    });
  }

  deleteTag(tagId: number) {
    const tags = this.paper.tags.filter(x => x.id !== tagId);
    const tagsId = tags.map(x => x.id);
    this.projectService.editPaper(this.paper.pk, { tags: tagsId }).subscribe(res => {
      if (HttpUtils.responceComplate(res)) {
        this.updateTag(tags);
      }
    });

  }
  updateTag(tags: Tag[]) {
    this.paper = {
      ...this.paper,
      tags
    };
    this.updatePaperTags.emit(this.paper);
  }

  openAssignment() {
    const config: MatDialogConfig = {
      width: '50%',
      disableClose: true,
      data: {
        paperId: this.paper.pk,
        importer: this.paper.importer
      }
    };
    const dialogRef = this.dialog.open(HandleAssignmentComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      this.updatePaperAssignment.emit(result);
      // if (result && result.length > 0) {
      // }
    });


  }
}
