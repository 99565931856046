import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';
import { UserInfo } from '@shared/schema/profile';

@Component({
  selector: 'app-dropbox-not-connected-warning',
  templateUrl: './dropbox-not-connected-warning.component.html',
  styleUrls: ['./dropbox-not-connected-warning.component.scss']
})
export class DropboxNotConnectedWarningComponent implements OnInit {
  @Input() user: UserInfo;
  constructor() { }

  ngOnInit() {
  }
  redirectToDropboxAuth() {
    const redirectURI = window.location.href;
    localStorage.setItem('redirectedToDropboxFrom', redirectURI);
    const clientId = environment.dropbox_client_id;
    window.location.href = `https://www.dropbox.com/oauth2/authorize?client_id=${clientId}&
response_type=code&redirect_uri=${redirectURI}&
token_access_type=offline`;

  }

}
