import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-email-design-pagination',
  templateUrl: './email-design-pagination.component.html',
  styleUrls: ['./email-design-pagination.component.scss'],
})
export class EmailDesignPaginationComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() page: number;
  @Input() totalCount = 1;
  @Input() maxPageCount: number;
  @Input() pageSize: number;
  @Input() listLength: number;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  currentPage = 1;
  sizeInfo: string;
  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalCount) {
      this.showPageSize(this.currentPage, changes.totalCount.currentValue);
    }
    if (changes.page || changes.listLength) {
      this.currentPage = this.page !== undefined ? this.page : 1;
      this.showPageSize(this.currentPage, this.totalCount);
    }
  }
  newPage(type: string) {
    type === 'next'
      ? (this.currentPage = this.currentPage + 1)
      : (this.currentPage = this.currentPage - 1);
    this.changePage.emit(this.currentPage);
    this.showPageSize(this.currentPage, this.totalCount);
  }
  showPageSize(page: number, count: number) {
    if (this.pageSize > count) {
      this.sizeInfo = `1-${count}`;
      return;
    }
    const from = (page - 1) * this.pageSize + 1;
    const to = this.listLength < this.pageSize ?
    ((page - 1) * this.pageSize + this.listLength) :
    page  * this.pageSize;
    this.sizeInfo = from + '-' + to;
  }
}
