import { Inject } from '@angular/core';
import { CONFIG } from '@core/service/config.service';
import { environment } from '@env';

const downloadBlob = (
  blobContent: string[] | string | Blob | ArrayBuffer,
  type: string,
  fileName: string,
  openInNewWindow?: boolean,
) => {
  let blobPart;
  if (typeof blobContent === 'string') {
    blobPart = [blobContent];
  } else {
    blobPart = [blobContent];
  }
  if (!fileName) {
    const newDate = new Date();
    fileName = newDate.getFullYear() + '-' + newDate.getMonth() + '-' + newDate.getUTCDay();
  }
  const blob = new Blob(blobPart, { type });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    if (!openInNewWindow) {
      a.download = fileName;
    }
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
const downloadFile = (link: string, hostName: string, isAbsoluteLink?: boolean, fileName?: string) => {
  const a = window.document.createElement('a');
  if (!isAbsoluteLink) {
    a.href = hostName + link;
  } else {
    a.href = link;
  }
  // if (!fileName) {
  //   const newDate = new Date();
  //   fileName = newDate.getFullYear() + '-' + newDate.getMonth() + '-' + newDate.getUTCDay();
  // }
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export { downloadBlob, downloadFile };
