import {Injectable} from '@angular/core';
import {StatisticService} from './statistic.service';
import {timeout} from 'rxjs/operators';
import {ProjectDetails} from '@shared/schema/project';
import {Store} from '@ngrx/store';
import {AppState} from '@shared/schema/app.state';
import {loadProject, resetProjectState} from '@modules/project/state/project.actions';
import * as profileActions from '@modules/profile/state/profile.actions';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserIdle {
  id: number;
  startDate: Date;
  timeout = null;
  idleTimeout = 1 * 60 * 1000;
  projectId;
  project: ProjectDetails;
  subscription: Subscription[];

  constructor(
    private statistic: StatisticService,
    private store: Store<AppState>
  ) {
    this.actionHanlder = this.actionHanlder.bind(this);
  }

  start(id: number, projectId: number) {
    this.subscription = [];
    this.subscription = [
      this.store.select(state => state.project.projectDetails).subscribe(
        (state) => {
          this.project = state;
          if (this.project && this.project.role_status === 'member') {

            this.id = id;
            this.projectId = projectId;
            this.startDate = new Date();
            document.addEventListener('mousemove', this.actionHanlder);
            document.addEventListener('keydown', this.actionHanlder);

          }
        }
      )
    ];


  }


  end() {
    const endTime = new Date();
    this.submitTime(this.startDate, endTime);
  }

  actionHanlder() {
    if (!this.startDate) {
      this.startDate = new Date();
    }
    if (timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    let timeoutFunc = function() {
      this.submitTime(this.startDate, (new Date((new Date()).getTime() - this.idleTimeout).toString()));
      this.startDate = null;
    };
    timeoutFunc = timeoutFunc.bind(this);
    this.timeout = setTimeout(timeoutFunc, this.idleTimeout);
  }

  submitTime(rangeStart, rangeEnd) {
    if (this.projectId) {

      const start = new Date(rangeStart);
      const end = new Date(rangeEnd);
      if (end.getTime() - start.getTime() > 20 * 1000) {
        this.statistic.createActionTime({
          start: start.toISOString(),
          end: end.toISOString(),
          page: this.id,
          project: this.projectId
        })
          .subscribe();
      }
    }
  }
}
