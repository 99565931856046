import { keysToCamel } from '@shared/utils/string-utils';

export class ObjectHelper {
  private obj: object;
  constructor(filterValue: object) {
    this.obj = { ...filterValue } || {};
  }

  private kebabCase(str: string) {
    return str.replace(/[A-Z]/g, m => '_' + m.toLowerCase());
  }

  private checkKey(key: string, source: object) {
    if (key in source) {
      return key;
    }
    if (this.kebabCase(key) in this.obj) {
      return this.kebabCase(key);
    }
    return null;
  }

  /**
   * Convert All Object Key
   * From CamelCase To kebab_case
   */

  kebabCaceKey() {
    const tmpObject = {};
    Object.keys(this.obj).forEach(key => {
      tmpObject[this.kebabCase(key)] = this.obj[key];
    });
    this.obj = tmpObject;
    return this;
  }
  CamelCaseKey() {
    let tmpObject = {};
    tmpObject = keysToCamel(this.obj);
    this.obj = tmpObject;
    return this;
  }


  /**
   * If Value Of Key are ArrayObject
   * Convert it baseed on given key
   * example:
   * {
   *  objectKey : [
   *      {
   *        valueObjectKey1: something1
   *        valueObjectKey2: something2
   *        valueObjectKey3: something3
   *      },
   *      {
   *        valueObjectKey1: something4
   *        valueObjectKey2: something5
   *        valueObjectKey3: something6
   *      },
   *    ]
   * }
   *  => valueArrayObjectToSimpleArray('objectKey','valueObjectKey1') :
   * {
   *  objectKey : [something1,something6]
   * }
   * @description no description
   * @param  valueObjectKey value that object value filter based on
   * @param  objKey main object key
   */
  valueArrayObjectToSimpleArray(objKey: string, valueObjectKey: string) {
    const key = this.checkKey(objKey, this.obj);
    if (key && this.obj[key] instanceof Array) {
      this.obj[objKey] = (this.obj[objKey] as Array<any>)
        .map(item => item[valueObjectKey])
        .filter(item => item !== null)
        ;
    }
    return this;
  }

  valueArrayObjectToSimpleArrayWithMapper(objKey: string, mapperFunction: any) {
    const key = this.checkKey(objKey, this.obj);
    if (key && this.obj[key] instanceof Array) {
      this.obj[key] = (this.obj[key] as Array<any>)
        .map(mapperFunction)
        .filter(item => item !== null)
        ;
      // If object[key] eqaul to empty array make it null
      if ((this.obj[key] as Array<any>).length === 0) {
        this.obj[key] = null;
      }
    }
    return this;
  }

  /**
   * @description Rename key
   * @param   oldKey old Key
   * @param   newKey new key
   */
  renameKey(oldKey: string, newKey: string) {
    const key = this.checkKey(oldKey, this.obj);
    if (key) {
      delete Object.assign(this.obj, { [newKey]: this.obj[key] })[key];
    }
    return this;
  }

  /**
   * @description Convert to string value
   * @param   objKey main object key
   */
  toString(objKey: string) {
    const key = this.checkKey(objKey, this.obj);
    if (key) {
      this.obj[key] = this.obj[key].toString();
    }
    return this;
  }

  /**
   * @description Stringfiy Value
   * @param  objKey main object key
   */
  stringfiy(objKey: string) {
    const key = this.checkKey(objKey, this.obj);
    if (key) {
      this.obj[key] = JSON.stringify(this.obj[key]);
    }
    return this;
  }
  /**
   * @description remove null key
   * @param  objKey main object key
   */
  removeNullkey(objKey: string) {
    const key = this.checkKey(objKey, this.obj);
    if (key) {
      if (this.obj[key] === null) {
        delete this.obj[key];
      }
    }
    return this;
  }
  NullKeyToEmptyArray(objKey: string) {
    const key = this.checkKey(objKey, this.obj);
    if (key) {
      if (this.obj[key] === null) {
        this.obj[key] = [];
      }
    }
    return this;
  }
  /**
   * @description Remove Null
   */
  removeNull() {
    Object.keys(this.obj).forEach(key => {
      if (this.obj[key] === null) {
        delete this.obj[key];
      }
    });
    return this;
  }

  get value() {
    return this.obj;
  }
}
